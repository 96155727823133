export const INPUT_KEYS = {
  CREATOR_FULL_NAME: 'creatorFullName',
  CREATOR_HBS_EMAIL: 'creatorHbsEmail',
  CREATOR_HBS_CLASSCARD_LINK: 'creatorHbsClasscardLink',
  COMPANY_WEBSITE: 'companyWebsite',
  COMPANY_DESCRIPTION: 'companyDescription',
  COMPANY_NAME: 'companyName',
};

export const SINGLE_SELECT_KEYS = {
  COMPANY_CARD_COLOR: 'companyCardColor',
  COMPANY_STAGE: 'companyStage',
  FOUNDING_YEAR: 'foundingYear',
  FUNDING_STAGE: 'fundingStage',
  FUNDRAISING: 'fundraising',
};

export const MULTI_SELECT_KEYS = {
  CATEGORIES: 'categories',
  GEOGRAPHIC_FOCUS: 'geographicFocus',
  FOUNDER_ATTRIBUTES: 'founderAttributes',
};

export const RADIO_KEYS = {
  IS_HIRING: 'isHiring',
};
