import { BRIGHT_GRAY } from 'constants/colors';
import styled from 'styled-components';

export const CardContainer = styled.div`
  justify-content: space-between;
  background-color: var(--RAISING_BLACK_OR_WHITE);
  border: 1px solid var(--GRAY_80_OR_BRIGHT_GRAY);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 450px;
  overflow: hidden;
  padding: 10px;
  width: 280px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const ContentContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 390px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  background-color: var(--BRIGHT_GRAY_OR_TRANSPARENT);
  border-radius: 4px;
  height: 40px;
  padding: 3px;
  width: 40px;
`;

export const SubImage = styled.div`
  align-items: center;
  background-color: var(--WHITE_OR_CRIMSON);
  border-radius: 4px;
  color: var(--RAISING_BLACK_OR_WHITE);
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

export const CompanyName = styled.div`
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  display: -webkit-box !important;
  font-size: 20px;
  font-weight: 600;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4;
`;

export const ExpirationDateContainer = styled.div`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 12px;
  margin: 5px 0 2px;
`;

export const ExpirationDate = styled.span`
  color: ${(props) => props.Color};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
`;

export const CategoryContainer = styled.div`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 10px;
  margin-bottom: 5px;
`;

export const SingleCategory = styled.p`
  margin: 0;
  margin-top: 3px;
  background-color: ${(props) => props.bgColor};
  color: #212121;
  border-radius: 4px;
  padding: 0 5px;
  height: 19px;
  font-size: 12px;
  line-height: 22px;
`;

export const TargetAudienceContainer = styled.div`
  border-bottom: 1px solid ${BRIGHT_GRAY};
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  padding-bottom: 10px;
  margin-top: 2px;
`;

export const CategoriesAndTargetsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const TargetAudience = styled.span`
  margin-top: 2px;
  background-color: ${(props) => props.bgColor};
  color: #212121;
  border-radius: 4px;
  padding: 0 5px;
  height: 19px;
  font-size: 12px;
  line-height: 22px;
`;

export const DiscountContainer = styled.div`
  border-bottom: 1px solid ${BRIGHT_GRAY};
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  padding-bottom: 30px;
`;

export const Discount = styled.span`
  color: var(--WHITE_OR_CRIMSON);
  display: -webkit-box !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;

export const Description = styled.div`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  display: -webkit-box !important;
  font-size: 12px;
  line-height: 19px;
  overflow: hidden;
  padding-top: 10px;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ClaimResourcesLink = styled.a`
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  text-decoration: none;
`;
