import { useState, useEffect } from 'react';
import {
  CreateUpdateHireContainer,
  BrandsWrapper,
  BrandNameWrapper,
  BrandName,
  AddUpdateCompanyContainer,
  AddCompanyButton,
  UpdateCompanyButton,
  CreateUpdateHireWrapper,
} from 'pages/HomePage/components/CreateUpdateHire/CreateUpdateHire.styled';
import BRANDS from 'constants/brands';
import {
  ADD_A_COMPANY,
  UPDATE_A_COMPANY,
} from 'components/Forms/Company/constants';
import { useAppContext } from 'contexts/AppContext';

const CreateUpdateHire = () => {
  const [brandIndex, setBrandIndex] = useState(0);
  const { setActiveForm } = useAppContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setBrandIndex((prevState) =>
        prevState === BRANDS.length - 1 ? 0 : prevState + 1,
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [firstBrand, secondBrand] = BRANDS[brandIndex];

  return (
    <CreateUpdateHireContainer>
      <CreateUpdateHireWrapper>
        <BrandsWrapper>
          <BrandNameWrapper>
            From <BrandName>{firstBrand}</BrandName>
          </BrandNameWrapper>
          <BrandNameWrapper>
            To <BrandName>{secondBrand}</BrandName>
          </BrandNameWrapper>
        </BrandsWrapper>
        <AddUpdateCompanyContainer>
          <AddCompanyButton onClick={() => setActiveForm(ADD_A_COMPANY)}>
            {ADD_A_COMPANY}
          </AddCompanyButton>
          <UpdateCompanyButton onClick={() => setActiveForm(UPDATE_A_COMPANY)}>
            {UPDATE_A_COMPANY}
          </UpdateCompanyButton>
        </AddUpdateCompanyContainer>
      </CreateUpdateHireWrapper>
    </CreateUpdateHireContainer>
  );
};

export default CreateUpdateHire;
