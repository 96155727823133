import PropTypes from 'prop-types';
import { useState } from 'react';
import { eventFormStateProps } from 'components/Forms/Events/propTypes';
import {
  Wrapper,
  TitleContainer,
  Step,
  Title,
  FormContainer,
  InputsContainer,
  ButtonsContainer,
  FieldContainer,
} from 'components/Forms/Events/StepOne/StepOne.styled';
import HbsDatePicker from 'components/common/HbsDatePicker/HbsDatePicker';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { STEP_ONE_KEYS } from 'components/Forms/Events/StepOne/constants';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import EventStepOneSchema from 'components/Forms/Events/StepOne/schema';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { BUTTONS_CSS } from 'components/Forms/Resources/constants';

const keys = Object.values(STEP_ONE_KEYS);

const StepOne = ({
  currentStep,
  closeForm,
  nextStep,
  setFormState,
  formState,
  update,
}) => {
  const [formInputState, setFormInputState] = useState(EventStepOneSchema);

  const { onBlurHandler, triggerError } = useFormInput();

  const onBlurDateHandler = () => {
    setFormInputState((prevState) => ({
      ...prevState,
      [STEP_ONE_KEYS.EVENT_DATE]: {
        ...prevState[STEP_ONE_KEYS.EVENT_DATE],
        isError: !prevState[STEP_ONE_KEYS.EVENT_DATE].validator(
          formState[STEP_ONE_KEYS.EVENT_DATE],
        ),
      },
    }));
  };

  const currentDate = new Date();

  const selectDateHandler = (date) => {
    setFormState((prevState) => ({
      ...prevState,
      [STEP_ONE_KEYS.EVENT_DATE]: date,
    }));
    setFormInputState((prevState) => ({
      ...prevState,
      [STEP_ONE_KEYS.EVENT_DATE]: {
        ...prevState[STEP_ONE_KEYS.EVENT_DATE],
        isError: !prevState[STEP_ONE_KEYS.EVENT_DATE].validator(date),
      },
    }));
  };

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      setFormInputState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };

  const onButtonClickSubmit = (e) => {
    e.preventDefault();

    const fieldWithErrors = keys.filter(
      (key) => !formInputState[key].validator(formState[key]),
    );

    fieldWithErrors.forEach((key) => triggerError(setFormInputState, key));

    if (fieldWithErrors.length) return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>{`Step ${currentStep}`}</Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer onSubmit={onButtonClickSubmit}>
        <InputsContainer>
          <FormInput
            {...formInputState[STEP_ONE_KEYS.EVENT_NAME]}
            value={formState[STEP_ONE_KEYS.EVENT_NAME]}
            onChangeHandler={onChangeHandler(STEP_ONE_KEYS.EVENT_NAME)}
            onBlurHandler={onBlurHandler(
              setFormInputState,
              STEP_ONE_KEYS.EVENT_NAME,
            )}
            name={STEP_ONE_KEYS.EVENT_NAME}
          />
        </InputsContainer>
        <InputsContainer>
          <FormInput
            {...formInputState[STEP_ONE_KEYS.RSVP_LINK]}
            value={formState[STEP_ONE_KEYS.RSVP_LINK]}
            onChangeHandler={onChangeHandler(STEP_ONE_KEYS.RSVP_LINK)}
            onBlurHandler={onBlurHandler(
              setFormInputState,
              STEP_ONE_KEYS.RSVP_LINK,
            )}
            name={STEP_ONE_KEYS.RSVP_LINK}
          />
        </InputsContainer>
        <FieldContainer>
          <HbsDatePicker
            selectedDate={formState[STEP_ONE_KEYS.EVENT_DATE]}
            minDate={currentDate}
            setSelectedDate={selectDateHandler}
            isError={formInputState[STEP_ONE_KEYS.EVENT_DATE].isError}
            warnMsg={formInputState[STEP_ONE_KEYS.EVENT_DATE].warnMsg}
            showTime
            onBlurDateHandler={onBlurDateHandler}
            useMinDate={!update}
          />
        </FieldContainer>
        <ButtonsContainer>
          <SecondaryButton
            {...BUTTONS_CSS.secondary}
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <DarkButton {...BUTTONS_CSS.primary}>Next</DarkButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepOne.defaultProps = {
  update: false,
};

StepOne.propTypes = {
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: eventFormStateProps().isRequired,
  update: PropTypes.bool,
};

export default StepOne;
