import Modal from 'components/common/Modal/Modal';
import CompanyPopup from 'pages/HomePage/components/CompaniesContainer/CompanyPopup/CompanyPopup';
import {
  LoadMoreContainer,
  CompanyPreview,
  FiltersSection,
  ClearFilters,
  ClearFiltersButton,
} from 'pages/HomePage/components/CompaniesContainer/CompaniesContainer.styled';
import { SecondaryButton } from 'components/common/Button/Button.styled';

import NoResults from 'components/common/NoResults/NoResults';
import BackToTop from 'pages/HomePage/components/BackToTop/BackToTop';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import { useAppContext } from 'contexts/AppContext';
import { useEffect } from 'react';
import {
  ADD_A_COMPANY,
  UPDATE_A_COMPANY,
} from 'components/Forms/Company/constants';
import useCompanies from 'hooks/api/useCompanies';
import Filters from './Filters/Filters';
import FilterControls from './FilterControls/FilterControls';
import CompanyList from './CompanyList';
import FloatingAnchorPartner from './FloatingAnchorPartner/FloatingAnchorPartner';

const CompaniesContainer = () => {
  const {
    setPreviewCompany,
    fetching,
    data,
    previewCompany,
    sortType,
    handleSortTypeChange,
    displayFilters,
    setDisplayFilters,
    clearFilters,
    filtersKey,
    filters,
    handleFiltersChange,
    totalRowCount,
    updateLoadMoreConfig,
    loadMoreConfig,
    getCompanies,
  } = useCompanies();
  const closePopup = () => setPreviewCompany(null);
  const { user, activeForm, setCallback } = useAppContext();
  useEffect(() => {
    if (activeForm === ADD_A_COMPANY || activeForm === UPDATE_A_COMPANY) {
      setCallback(getCompanies);
    }
  }, [activeForm]);
  let ignore = false;
  useEffect(() => {
    if (!ignore) {
      getCompanies();
    }
    return () => {
      ignore = true;
    };
  }, [loadMoreConfig.limit, loadMoreConfig.offset, filters, sortType, user]);

  const displayCompanies = () => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!data.length) return <NoResults />;

    return (
      <CompanyList
        fetching={fetching}
        data={data}
        setPreviewCompany={setPreviewCompany}
      />
    );
  };

  return (
    <>
      {!!previewCompany && (
        <Modal closeOnEscape closeForm={closePopup}>
          <CompanyPopup {...previewCompany} />
        </Modal>
      )}
      <FiltersSection>
        <FilterControls
          sortType={sortType}
          handleSortTypeChange={handleSortTypeChange}
          toggleFilters={() => setDisplayFilters(!displayFilters)}
        />
        {displayFilters && (
          <ClearFilters>
            <ClearFiltersButton onClick={() => clearFilters()}>
              Clear filters
            </ClearFiltersButton>
          </ClearFilters>
        )}
        {displayFilters && (
          <Filters
            isFiltersApplied={filtersKey}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        )}
      </FiltersSection>
      {displayCompanies()}
      {!fetching && (
        <LoadMoreContainer>
          {data.length < totalRowCount && (
            <SecondaryButton
              Border="none"
              TextDecoration="underline"
              FontWeight="600"
              FontSize="12px"
              type="button"
              onClick={() => updateLoadMoreConfig()}
            >
              Load more <br />
            </SecondaryButton>
          )}
          <CompanyPreview>
            {`${data.length}
          of ${totalRowCount} entries`}
            {loadMoreConfig.offset ? <BackToTop /> : null}
          </CompanyPreview>
        </LoadMoreContainer>
      )}
      <FloatingAnchorPartner />
    </>
  );
};

export default CompaniesContainer;
