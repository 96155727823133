import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const FooterContainer = styled.footer`
  background-color: var(--FOOTER_DARK_OR_FOOTER_LIGHT);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 24px 130px;

  @media ${DEVICE.laptop} {
    padding: 24px 50px;
  }

  @media ${DEVICE.formResponsive} {
    padding: 24px 20px;
  }
`;

export const NewsletterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  align-items: center;

  @media ${DEVICE.laptopL} {
    gap: 20px;
  }

  @media ${DEVICE.tablet} {
    flex-direction: column;
    max-width: 475px;
    width: 100%;
    align-self: center;
    align-items: stretch;
  }

  @media ${DEVICE.formResponsive} {
    max-width: none;
  }
`;

export const NewsletterParagraphsSection = styled.div`
  flex: 1;
`;

export const NewsletterTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  @media ${DEVICE.laptop} {
    font-size: 14px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
  }
`;

export const NewsletterParagraph = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 300;
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);

  @media ${DEVICE.laptop} {
    font-size: 12px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 10px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 24px;

  @media ${DEVICE.tablet} {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const FooterContentParagraph = styled.p`
  font-size: 10px;
  line-height: 15px;
  margin: 0;
  text-align: center;
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
`;

export const EmailLink = styled.a`
  color: var(--WHITE_OR_CRIMSON);
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:hover::after {
    text-decoration: none;
  }
`;

export const MiddleFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

export const SymphonyFooterLink = styled.a`
  font-size: 10px;
  color: inherit;
  display: flex;
  align-items: center;

  & > svg {
    height: 24px;
    margin-left: 10px;
    width: 124px;
  }
`;
