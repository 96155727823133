import { ResourcesSection } from 'pages/ExclusiveResources/ExclusiveResourcesPage.styled';
import ExclusiveResourcesContainer from 'pages/ExclusiveResources/components/ExclusiveResourcesContainer/ExclusiveResourcesContainer';
import {
  LandingSectionWrapper,
  ResourcesAnchorPartnerWrapper,
  LandingSection,
} from 'pages/ResourcePage/ResourcePage.styled';
import AnchorPartner from 'components/common/AnchorPartner/AnchorPartner';
import useExclusiveDeals from 'hooks/api/useExclusiveDeals';
import WBSExclusive from './components/WBSExclusive/WBSExclusive';

const ExclusiveResources = () => {
  const exclusiveContext = useExclusiveDeals();
  const { fetchExclusiveResources } = exclusiveContext;
  return (
    <ResourcesSection>
      <LandingSection>
        <LandingSectionWrapper>
          <WBSExclusive successCallback={fetchExclusiveResources} />
          <ResourcesAnchorPartnerWrapper>
            <AnchorPartner />
          </ResourcesAnchorPartnerWrapper>
        </LandingSectionWrapper>
      </LandingSection>
      <ExclusiveResourcesContainer exclusiveContext={exclusiveContext} />
    </ResourcesSection>
  );
};
export default ExclusiveResources;
