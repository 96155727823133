import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const EventPageContainer = styled.div`
  padding: 20px 75px 0;
  min-height: calc(100vh - 96px - 193px);
  overflow: auto;

  @media ${DEVICE.laptop} {
    padding: 20px 20px 0;
  }
  @media ${DEVICE.formResponsive} {
    padding: 20px 10px 0;
    min-height: 100vh;
    overflow-x: hidden;
  }
`;

export const LandingSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const EventAnchorPartnerWrapper = styled.div`
  display: initial;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;
