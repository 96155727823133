import styled from 'styled-components';

export const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessage = styled.span`
  color: var(--WHITE_OR_CRIMSON);
  font-size: 14px;
`;
