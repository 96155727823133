import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { getEventById } from 'services/requests';

const useEventPopupData = (eventId) => {
  const [eventPopupData, setEventPopupData] = useState(null);

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    if (eventId) {
      const fetchEventData = async () => {
        const fetchedEvent = await request(() => getEventById(eventId));

        if (!ignore && fetchedEvent) setEventPopupData(fetchedEvent.data);
      };

      fetchEventData();
    } else {
      setEventPopupData(null);
    }

    return () => {
      ignore = true;
    };
  }, [eventId]);

  return { eventPopupData };
};

export default useEventPopupData;
