import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import useDarkMode from 'hooks/useDarkMode';
import { useAppContext } from 'contexts/AppContext';
import GlobalStyle from 'components/GlobalStyle/GlobalStyle';
import isAuth from 'utils/isAuth';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import FormContainer from 'components/Forms/FormContainer';
import HomePage from 'pages/HomePage/HomePage';
import ResourcePage from 'pages/ResourcePage/ResourcePage';
import ExclusiveResourcesPage from 'pages/ExclusiveResources/ExclusiveResourcesPage';
import EventPage from 'pages/EventPage/EventPage';
import AdminPage from 'pages/AdminPage/AdminPage';
import AboutPage from 'pages/AboutPage/AboutPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import ProtectedRoute from 'routes/ProtectedRoutes/ProtectedRoute';
import DeleteCompany from 'pages/AdminPage/components/Forms/Company/DeleteCompany/DeleteCompany';
import EditCompany from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCompany';
import RestoreCompany from 'pages/AdminPage/components/Forms/Company/RestoreCompany/RestoreCompany';
import USER_ROLES from 'constants/user_roles';
import CompaniesProvider from 'contexts/CompaniesContext';

import './App.css';
import VerificationPage from 'pages/VerificationPage/VerificationPage';

const App = () => {
  const [isDark, themeToggler] = useDarkMode();

  const { removeUser, getUserRole } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    if (!isAuth()) removeUser();
  }, [location]);

  return (
    <HelmetProvider>
      <GlobalStyle
        isDark={location.pathname.startsWith('/administration') || isDark}
      />
      <FormContainer />
      <Header toggleSwitch={themeToggler} isDark={isDark} />
      <main style={{ flex: 1 }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/resources" element={<ResourcePage />} />
          <Route
            path="/exclusive-resources"
            element={<ExclusiveResourcesPage />}
          />
          <Route path="/events" element={<EventPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/administration"
            element={
              <ProtectedRoute isAuthorized={getUserRole() === USER_ROLES.ADMIN}>
                <CompaniesProvider>
                  <AdminPage />
                </CompaniesProvider>
              </ProtectedRoute>
            }
          >
            <Route path="companies/delete" element={<DeleteCompany />} />
            <Route path="companies/edit" element={<EditCompany />} />
            <Route path="companies/restore" element={<RestoreCompany />} />
          </Route>
          <Route path="verification" element={<VerificationPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <Footer />
    </HelmetProvider>
  );
};

export default App;
