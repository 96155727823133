import PropTypes from 'prop-types';
import {
  Wrapper,
  Step,
  TitleContainer,
  Title,
  SelectContainer,
  ButtonsContainer,
} from 'components/Forms/Company/SelectCompany/SelectCompany.styled';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import { useEffect, useState } from 'react';
import { getAllCompaniesByUserId, getCompanyById } from 'services/requests';
import useRequest from 'hooks/useRequest';
import repackReceivedData from 'components/Forms/Company/SelectCompany/utils';

const SelectCompany = ({ nextStep, closeForm, setFormState }) => {
  const [options, setOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isError, setIsError] = useState(false);
  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const getCompaniesByUser = async () => {
      const responseData = await request(() => getAllCompaniesByUserId());

      if (!ignore && responseData.data) {
        const option = responseData.data.map(({ id, companyName: label }) => ({
          id,
          label,
        }));

        setOptions(option);
      }
    };

    getCompaniesByUser();

    return () => {
      ignore = true;
    };
  }, []);

  const onChangeHandler = (option) => {
    setSelectedCompany(option);
    setIsError(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (selectedCompany) nextStep();
    setIsError(true);
  };

  useEffect(() => {
    const getCompany = async () => {
      const responseData = await request(() =>
        getCompanyById(selectedCompany.id),
      );

      if (responseData)
        setFormState((prevState) => ({
          ...prevState,
          ...repackReceivedData(responseData.data),
        }));
    };

    if (selectedCompany) getCompany();
  }, [selectedCompany]);

  return (
    <Wrapper>
      <TitleContainer>
        <Step>Step 1/4</Step>
        <Title>
          What company do you
          <br /> want to update?
        </Title>
      </TitleContainer>
      <SelectContainer>
        <SingleSelect
          key={options.length}
          label="Current company name*"
          isSearchable
          placeHolder="Select a company..."
          options={options}
          selectedValue={selectedCompany}
          onChangeHandler={onChangeHandler}
          MaxHeight="150px"
          disabledDarkMode
          isError={isError}
        />
      </SelectContainer>
      <ButtonsContainer>
        <SecondaryButton
          Border="1px solid #545556"
          Width="83px"
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          type="button"
          noDark
          onClick={closeForm}
        >
          Cancel
        </SecondaryButton>
        <Spacer Width="16px" />
        <DarkButton
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          Width="158px"
          Border="none"
          type="button"
          onClick={submitHandler}
        >
          Next: General Info
        </DarkButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

SelectCompany.propTypes = {
  nextStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
};

export default SelectCompany;
