const SUBMIT_BUTTON_CSS = {
  Border: 'none',
  Height: '40px',
  FontSize: '14px',
  FontWeight: '600px',
  responsiveHeight: '40px',
  responsiveWidth: '295px',
  type: 'button',
  Width: '360px',
};

export default SUBMIT_BUTTON_CSS;
