const prepareExclusiveResourceData = (formState) => ({
  companyName: formState.companyName.trim(),
  promotion: formState.promotion,
  companyWebsite: formState.companyUrl,
  companyLogo: formState.companyLogo && formState.companyLogo.base64,
  categories: formState.categories.map((category) => category.id),
  dealDescription: formState.dealDescription,
  companyDescription: formState.companyDescription,
  contactName: formState.contactName,
  contactEmail: formState.contactEmail,
  expiringDate: formState.expiringDate
    ? formState.expiringDate.toISOString()
    : null,
});

export default prepareExclusiveResourceData;
