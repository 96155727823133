import { CONTACT_EMAIL } from 'constants/other';

const PARAGRAPHS = {
  ABOUT: [
    [
      'Inspired by our friends at ',
      ', we have come together to build the most comprehensive community of entrepreneurship at Wharton across graduation years, industries and geographies. ',
    ],
    'We are independent and community-driven, and love hearing what works best for all of the company-builders out there. WhartonBuilds is for any Wharton student, drop out, or alumni building a company be it 0-1 entrepreneurship or entrepreneurship through acquisition (aka Search). Our goal is to level up the Wharton entrepreneurship community by pooling our resources, networks, and knowledge.',
    'If this is you, please add your company ',
    'We also host regular events with various Wharton alumni, including notable founders, investors, and operators. For more information on these events and other WhartonBuilds initiatives, be sure to sign up for our quarterly newsletter.',
    'If you know of other resources, events, or content you’d like to see here, or you have exclusive deals to offer our community, you can add them directly on this site, or ',
    'reach out',
    'If you’re interested in being a formal rep for your class (responsible for sharing WhartonBuilds with your class), ',
  ],
};

export const LINK_KEYS = {
  GSBUILDS: 'GSBuilds.co',
  HERE: 'here',
  REACH_OUT: 'please reach out',
};

export const LINKS = {
  [LINK_KEYS.GSBUILDS]: 'https://gsbuilds.co/',
  [LINK_KEYS.HERE]: 'https://www.whartonbuilds.com',
  [LINK_KEYS.REACH_OUT]: CONTACT_EMAIL,
};

export default PARAGRAPHS;
