import { isEmptyFieldValidator } from 'utils/inputValidators';
import { EXCLUSIVE_KEYS } from '../constants';

const generalInfoInputSchema = {
  [EXCLUSIVE_KEYS.COMPANY_NAME]: {
    label: 'Company name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your company name',
    validator: isEmptyFieldValidator,
  },
};

export default generalInfoInputSchema;
