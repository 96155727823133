import PropTypes from 'prop-types';
import groupByProperty from 'utils/groupByProperty';
import { dateSortFunction } from 'pages/EventPage/components/Content/utils';
import {
  UpcomingEventsWrapper,
  HeadingContainer,
  DateDayHeader,
  DateMonthParagraph,
  EventCountPerDay,
  SectionContainer,
  UpcomingEventsCardContainer,
  EventCountContainer,
  DateContainer,
} from 'pages/EventPage/components/Content/UpcomingEvents/UpcomingEvents.styled';
import MONTH_NAME_SHORT from 'pages/EventPage/components/Content/UpcomingEvents/constants';
import UpcomingEventCard from 'pages/EventPage/components/Content/UpcomingEvents/UpcomingEventCard/UpcomingEventCard';
import { eventsPropTypes } from 'pages/EventPage/components/eventPropTypes';
import NoResults from 'components/common/NoResults/NoResults';
import { MobileBottomSpacer } from 'pages/EventPage/components/Content/EventPageContent.styled';

const UpcomingEvents = ({ upcomingEvents, handleEventCardClick }) => {
  const eventGroupedByDay = groupByProperty('day')(upcomingEvents);

  return (
    <UpcomingEventsWrapper>
      {upcomingEvents.length ? (
        Object.keys(eventGroupedByDay)
          .sort(dateSortFunction)
          .map((day) => (
            <SectionContainer key={day}>
              <HeadingContainer>
                <DateContainer>
                  <DateMonthParagraph>
                    {MONTH_NAME_SHORT[new Date(day).getMonth()]}
                  </DateMonthParagraph>
                  <DateDayHeader>{new Date(day).getDate()}</DateDayHeader>
                </DateContainer>
                <EventCountContainer>
                  <EventCountPerDay>{`${eventGroupedByDay[day].length} ${
                    eventGroupedByDay[day].length > 1 ? 'events' : 'event'
                  }`}</EventCountPerDay>
                </EventCountContainer>
              </HeadingContainer>
              <UpcomingEventsCardContainer>
                {eventGroupedByDay[day].map((event) => (
                  <UpcomingEventCard
                    key={event.id + event.eventName}
                    upcomingEvent={event}
                    handleClick={() => handleEventCardClick(event.id)}
                  />
                ))}
              </UpcomingEventsCardContainer>
            </SectionContainer>
          ))
      ) : (
        <NoResults />
      )}
      <MobileBottomSpacer />
    </UpcomingEventsWrapper>
  );
};

UpcomingEvents.propTypes = {
  upcomingEvents: eventsPropTypes().isRequired,
  handleEventCardClick: PropTypes.func.isRequired,
};

export default UpcomingEvents;
