import { defaultFormStateProp } from 'components/Forms/Company/propTypes';
import FOUNDER_INITIAL_STATE from 'components/Forms/Company/StepThree/schema';
import {
  mapCategories,
  mapFounderAttributes,
  mapGeographicFocus,
} from 'components/Forms/Company/StepTwo/utils';
import { FUNDING_STAGE, OPERATING_STAGE } from 'pages/HomePage/constants';
import getCategoryColor from 'pages/HomePage/utils';

const repackReceivedData = (company) => ({
  id: company.id,
  creatorFullName: company.creatorFullName,
  creatorHbsEmail: company.creatorHbsEmail,
  creatorHbsClasscardLink:
    company.creatorHbsClasscardLink ||
    defaultFormStateProp().creatorHbsClasscardLink,
  isHiring: company.isHiring ? 'Yes' : 'No',
  companyName: company.companyName,
  companyWebsite:
    company.companyWebsite || defaultFormStateProp().companyWebsite,
  foundingYear: company.foundingYear
    ? { label: `${company.foundingYear}` }
    : defaultFormStateProp().foundingYear,
  companyDescription: company.companyDescription,
  // TODO:  personalEmail:  what to do with personalEmail
  companyStage: company.companyStage && {
    id: company.companyStage.id,
    label: company.companyStage.stageName,
    bgColor: getCategoryColor(company.companyStage.stageName, OPERATING_STAGE),
  },
  fundingStage: company.fundingStage && {
    id: company.fundingStage.id,
    label: company.fundingStage.fundingStageName,
    bgColor: getCategoryColor(
      company.fundingStage.fundingStageName,
      FUNDING_STAGE,
    ),
  },
  companyCardColor: {
    id: company.companyCardColor.id,
    label: company.companyCardColor.colorName,
    color: company.companyCardColor.colorValue,
  },
  fundraising: {
    id: company.fundraising,
    label: company.fundraising ? 'Yes' : 'No',
  },
  founderAttributes: company.founderAttributes
    ? mapFounderAttributes(company.founderAttributes)
    : [],
  geographicFocus: company.geographicFocus
    ? mapGeographicFocus(company.geographicFocus)
    : [],
  categories: company.categories ? mapCategories(company.categories) : [],
  founders: company.founders.map((founder) => ({
    fullName: { ...FOUNDER_INITIAL_STATE.fullName, value: founder.fullName },
    classYear: {
      ...FOUNDER_INITIAL_STATE.classYear,
      selectedValue: { label: `${founder.classYear || 2022}` },
    },
    founderEmail: {
      ...FOUNDER_INITIAL_STATE.founderEmail,
      value: founder.founderEmail || '',
    },
    founderLinkedin: {
      ...FOUNDER_INITIAL_STATE.founderLinkedin,
      value: founder.founderLinkedin ? founder.founderLinkedin : '',
    },
    position: founder.position,
    department: {
      ...FOUNDER_INITIAL_STATE.department,
      value: founder.department || '',
    },
  })),
});

export default repackReceivedData;
