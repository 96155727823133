export const ADD_AN_EXCLUSIVE_DEAL = 'Add an exclusive deal';

export const ADD_RESOURCE_STEPS = {
  ENTRY_MODAL: 'ENTRY_MODAL',
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  STEP_THREE: 'STEP_THREE',
  RESPONSE: 'RESPONSE',
};

export const ADD_RESOURCE_PROGRESS = {
  [ADD_RESOURCE_STEPS.ENTRY_MODAL]: '0%',
  [ADD_RESOURCE_STEPS.STEP_ONE]: '33%',
  [ADD_RESOURCE_STEPS.STEP_TWO]: '66%',
  [ADD_RESOURCE_STEPS.STEP_THREE]: '100%',
  [ADD_RESOURCE_STEPS.RESPONSE]: '0%',
};

export const PUBLIC_KEYS = {
  CATEGORIES: 'categories',
  CONTACT_NAME: 'contactName',
  CONTACT_EMAIL: 'contactEmail',
  DEAL_DESCRIPTION: 'dealDescription',
  DEAL_IMAGE: 'dealImage',
  DEAL_NAME: 'dealName',
  DEAL_URL: 'dealUrl',
  TARGET_AUDIENCES: 'targetAudiences',
  EXPIRING_DATE: 'expiringDate',
};

export const EXCLUSIVE_KEYS = {
  CATEGORIES: 'categories',
  COMPANY_DESCRIPTION: 'companyDescription',
  COMPANY_LOGO: 'companyLogo',
  CONTACT_NAME: 'contactName',
  CONTACT_EMAIL: 'contactEmail',
  COMPANY_WEBSITE: 'companyUrl',
  COMPANY_NAME: 'companyName',
  DEAL_DESCRIPTION: 'dealDescription',
  EXPIRING_DATE: 'expiringDate',
  PROMOTION: 'promotion',
};

export const BUTTONS_CSS = {
  primary: {
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    Width: '173px',
    Border: 'none',
    type: 'submit',
  },
  secondary: {
    Border: '1px solid #545556',
    Width: '83px',
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
};
