import SymphonyLetterLogo from 'assets/icons/SymphonyLetterLogo';
import SymphonyLogo from 'assets/icons/SymphonyLogo';
import { SYMPHONY_WEBSITE } from 'constants/other';
import { useRef, useState } from 'react';
import gaSymphonyVisit from 'utils/gaSymphonyVisit';

import {
  FloatingAnchorPartnerContainer,
  AnchorPartnerContent,
  AnchorPartnerDetails,
  LearnMoreLink,
  SymphonyLetterLogoWrapper,
} from './FloatingAnchorPartner.styled';

const FloatingAnchorPartner = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const openFloat = () => {
    if (!open) {
      ref.current.focus();
      setOpen(true);
    }
  };
  const closeFloat = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  };
  return (
    <FloatingAnchorPartnerContainer
      onClick={openFloat}
      onBlur={closeFloat}
      className={`${open ? 'open' : ''}`}
      ref={ref}
      tabIndex={0}
    >
      <SymphonyLetterLogoWrapper>
        <SymphonyLetterLogo />
      </SymphonyLetterLogoWrapper>
      <AnchorPartnerContent>
        <AnchorPartnerDetails
          href={SYMPHONY_WEBSITE}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            gaSymphonyVisit('floating_anchor_partner');
          }}
        >
          <p>Anchor partner </p>
          <SymphonyLogo />
        </AnchorPartnerDetails>
        <LearnMoreLink to="/about#anchor-partner">Claim offer</LearnMoreLink>
      </AnchorPartnerContent>
    </FloatingAnchorPartnerContainer>
  );
};

export default FloatingAnchorPartner;
