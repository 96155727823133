import { Path } from './NoResults.styled';

const NoResultsIllustration = () => (
  <svg
    width="171"
    height="131"
    viewBox="0 0 171 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="171"
      height="131"
      rx="4"
      fill="var(--DAVY_GRAY_OR_BRIGHT_GRAY)"
    />
    <g clipPath="url(#clip0_107_802)">
      <rect
        x="12"
        y="15"
        width="147"
        height="20"
        rx="7"
        fill="var(--TRANSPARENT_OR_WHITE)"
      />
      <Path
        pathVarColor="--BRIGHT_GRAY_OR_DAVY_GRAY"
        d="M152.158 34.8415H18.842C17.028 34.8395 15.2889 34.1202 14.0062 32.8416C12.7236 31.5629 12.002 29.8293 12 28.021V21.8205C12.002 20.0122 12.7236 18.2786 14.0062 16.9999C15.2889 15.7213 17.028 15.002 18.842 15H152.158C153.972 15.002 155.711 15.7213 156.994 16.9999C158.276 18.2786 158.998 20.0122 159 21.8205V28.021C158.998 29.8293 158.276 31.5629 156.994 32.8416C155.711 34.1202 153.972 34.8395 152.158 34.8415ZM18.842 15.4134C17.138 15.4153 15.5043 16.0909 14.2993 17.2921C13.0944 18.4933 12.4166 20.1218 12.4147 21.8205V28.021C12.4166 29.7197 13.0944 31.3483 14.2993 32.5494C15.5043 33.7506 17.138 34.4262 18.842 34.4282H152.158C153.862 34.4262 155.496 33.7506 156.701 32.5494C157.906 31.3483 158.583 29.7197 158.585 28.021V21.8205C158.583 20.1218 157.906 18.4933 156.701 17.2921C155.496 16.0909 153.862 15.4153 152.158 15.4134H18.842Z"
      />
      <Path
        pathVarColor="--DARK_CHARCOAL_OR_BRIGHT_GRAY"
        d="M117.534 20.9934H29.0018C26.8262 20.9934 25.0625 22.7516 25.0625 24.9204V24.9204C25.0625 27.0892 26.8262 28.8473 29.0018 28.8473H117.534C119.709 28.8473 121.473 27.0892 121.473 24.9204C121.473 22.7516 119.709 20.9934 117.534 20.9934Z"
      />
      <Path
        pathColor="var(--WHITE_OR_CRIMSON)"
        d="M129.144 55.4363H40.6126C38.4369 55.4363 36.6732 57.1944 36.6732 59.3632V59.3633C36.6732 61.5321 38.4369 63.2902 40.6126 63.2902H129.144C131.32 63.2902 133.084 61.5321 133.084 59.3632C133.084 57.1944 131.32 55.4363 129.144 55.4363Z"
      />
      <Path
        pathVarColor="--DARK_CHARCOAL_OR_WHITE"
        d="M129.144 80.4077H40.6126C38.4369 80.4077 36.6732 82.1659 36.6732 84.3347V84.3347C36.6732 86.5035 38.4369 88.2616 40.6126 88.2616H129.144C131.32 88.2616 133.084 86.5035 133.084 84.3347C133.084 82.1659 131.32 80.4077 129.144 80.4077Z"
      />
      <Path
        pathVarColor="--DARK_CHARCOAL_OR_WHITE"
        d="M129.144 105.379H40.6126C38.4369 105.379 36.6732 107.137 36.6732 109.306V109.306C36.6732 111.475 38.4369 113.233 40.6126 113.233H129.144C131.32 113.233 133.084 111.475 133.084 109.306C133.084 107.137 131.32 105.379 129.144 105.379Z"
      />
      <Path
        pathColor="var(--WHITE_OR_CRIMSON)"
        d="M146.04 29.163C145.946 29.2974 145.802 29.3889 145.64 29.4174C145.478 29.4459 145.311 29.409 145.176 29.315L141.791 26.967C141.656 26.8729 141.565 26.7293 141.536 26.5677C141.507 26.4062 141.544 26.2399 141.639 26.1056C141.733 25.9712 141.877 25.8797 142.039 25.8512C142.201 25.8227 142.368 25.8596 142.503 25.9537L145.888 28.3016C146.023 28.3957 146.115 28.5393 146.143 28.7009C146.172 28.8624 146.135 29.0287 146.04 29.163Z"
      />
      <Path
        pathColor="var(--WHITE_OR_CRIMSON)"
        d="M142.197 26.4959C141.698 27.2073 140.998 27.755 140.186 28.0698C139.374 28.3845 138.486 28.4522 137.636 28.2643C136.785 28.0763 136.01 27.6412 135.407 27.0139C134.805 26.3865 134.403 25.5952 134.251 24.74C134.1 23.8848 134.207 23.004 134.558 22.2092C134.908 21.4143 135.488 20.741 136.223 20.2743C136.957 19.8077 137.814 19.5688 138.685 19.5877C139.556 19.6066 140.402 19.8826 141.116 20.3807C142.071 21.0495 142.722 22.0689 142.925 23.2154C143.128 24.3618 142.866 25.5416 142.197 26.4959ZM135.883 22.0887C135.508 22.6222 135.301 23.2545 135.287 23.9057C135.272 24.5569 135.452 25.1976 135.803 25.7469C136.154 26.2962 136.661 26.7294 137.259 26.9917C137.857 27.254 138.519 27.3337 139.163 27.2206C139.806 27.1075 140.402 26.8067 140.874 26.3563C141.346 25.9059 141.673 25.3261 141.814 24.6902C141.956 24.0543 141.905 23.3909 141.668 22.7839C141.431 22.1769 141.019 21.6535 140.484 21.2799C139.766 20.7799 138.878 20.5843 138.016 20.7359C137.153 20.8875 136.386 21.374 135.883 22.0887Z"
      />
      <Path
        pathVarColor="--BRIGHT_GRAY_OR_DAVY_GRAY"
        d="M148.322 67.0362H22.6778C22.6228 67.0362 22.5701 67.0144 22.5312 66.9756C22.4923 66.9369 22.4705 66.8843 22.4705 66.8295C22.4705 66.7747 22.4923 66.7221 22.5312 66.6833C22.5701 66.6446 22.6228 66.6228 22.6778 66.6228H148.322C148.377 66.6228 148.43 66.6446 148.469 66.6833C148.508 66.7221 148.53 66.7747 148.53 66.8295C148.53 66.8843 148.508 66.9369 148.469 66.9756C148.43 67.0144 148.377 67.0362 148.322 67.0362Z"
      />
      <Path
        pathVarColor="--BRIGHT_GRAY_OR_DAVY_GRAY"
        d="M148.322 92.0174H22.6778C22.6228 92.0174 22.5701 91.9956 22.5312 91.9568C22.4923 91.9181 22.4705 91.8655 22.4705 91.8107C22.4705 91.7559 22.4923 91.7033 22.5312 91.6645C22.5701 91.6258 22.6228 91.604 22.6778 91.604H148.322C148.377 91.604 148.43 91.6258 148.469 91.6645C148.508 91.7033 148.53 91.7559 148.53 91.8107C148.53 91.8655 148.508 91.9181 148.469 91.9568C148.43 91.9956 148.377 92.0174 148.322 92.0174Z"
      />
      <Path
        pathVarColor="--BRIGHT_GRAY_OR_DAVY_GRAY"
        d="M148.322 117.001H22.6778C22.6228 117.001 22.5701 116.979 22.5312 116.94C22.4923 116.901 22.4705 116.849 22.4705 116.794C22.4705 116.739 22.4923 116.686 22.5312 116.648C22.5701 116.609 22.6228 116.587 22.6778 116.587H148.322C148.377 116.587 148.43 116.609 148.469 116.648C148.508 116.686 148.53 116.739 148.53 116.794C148.53 116.849 148.508 116.901 148.469 116.94C148.43 116.979 148.377 117.001 148.322 117.001Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_107_802">
        <rect
          width="147"
          height="102"
          fill="white"
          transform="translate(12 15)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NoResultsIllustration;
