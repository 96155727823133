import { ADD_A_RESOURCE } from 'components/Forms/Resources/constants';
import { CONTACT_EMAIL } from 'constants/other';

const RESOURCE_STATIC_TEXT = {
  [ADD_A_RESOURCE]: {
    START_TITLE: 'Add a resource',
    START_DESCRIPTION: `WhartonBuilds is the definitive platform to find resources for your studies or discounts.`,
    CONTACT_INFO: `For questions or assistance, please reach out to`,
    CONTACT_EMAIL,
    END_TITLE: 'Thank you!',
    END_DESCRIPTION: 'Thank you for submiting your resource to our platform.',
  },
};

export default RESOURCE_STATIC_TEXT;
