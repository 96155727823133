import PropTypes from 'prop-types';
import FilterIcon from 'assets/icons/FilterIcon';
import { FilterButton } from './FilterControls.styled';

const ToggleFilters = ({ toggleFilters }) => (
  <FilterButton onClick={toggleFilters}>
    <FilterIcon />
    Filters
  </FilterButton>
);

ToggleFilters.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
};

export default ToggleFilters;
