import isURL from 'validator/lib/isURL';
import validateURL from 'utils/linkValidator';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import { Divider } from 'components/common/Divider/Divider.styled';
import {
  PopupButtonContainer,
  PopupColumn,
  PopupContainer,
  PopupContent,
  PopupImage,
  PopupName,
  PopupSection,
  PopupSectionParagraph,
  PopupSubSectionName,
  PopupTitle,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { BRIGHT_GRAY } from 'constants/colors';
import { EVENT_AUDIENCES, EVENT_ORGANIZERS } from 'pages/EventPage/constants';
import { NOT_AVAILABLE } from 'pages/HomePage/components/CompaniesContainer/constants';
import {
  eventDefaultProps,
  eventPropTypes,
} from 'pages/EventPage/components/eventPropTypes';

const EventPopup = ({
  eventName,
  eventDescription,
  rsvpLink,
  eventLogo,
  eventLocation,
  eventDate,
  organizers,
  audience,
}) => (
  <PopupContainer>
    <PopupTitleContainer>
      <PopupTitle>Event information</PopupTitle>
      <PopupName>{eventName}</PopupName>
    </PopupTitleContainer>
    <PopupContent>
      <PopupColumn>
        {eventLogo && (
          <PopupSection>
            <PopupImage src={`data:image/png;base64,${eventLogo}`} />
            <Divider width="90%" color={BRIGHT_GRAY} />
          </PopupSection>
        )}
        <PopupSection>
          <PopupSubSectionName>Location</PopupSubSectionName>
          <PopupSectionParagraph>
            {eventLocation || NOT_AVAILABLE}
          </PopupSectionParagraph>
        </PopupSection>
        <PopupSection>
          <PopupSubSectionName>Date</PopupSubSectionName>
          <PopupSectionParagraph>
            {new Date(eventDate).toLocaleString('en-us', {
              timeStyle: 'long',
              dateStyle: 'medium',
            })}
          </PopupSectionParagraph>
        </PopupSection>
      </PopupColumn>
      <PopupColumn>
        <PopupSection>
          <PopupSubSectionName>Event organizers</PopupSubSectionName>
          <PopupMultiCategories
            categories={organizers}
            categoriesColors={EVENT_ORGANIZERS}
          />
        </PopupSection>
        <PopupSection>
          <PopupSubSectionName>Audience</PopupSubSectionName>
          <PopupMultiCategories
            categories={audience}
            categoriesColors={EVENT_AUDIENCES}
          />
        </PopupSection>
        <PopupSection>
          <PopupSubSectionName>Description</PopupSubSectionName>
          <PopupSectionParagraph>{eventDescription}</PopupSectionParagraph>
        </PopupSection>
      </PopupColumn>
    </PopupContent>
    {rsvpLink && isURL(rsvpLink) ? (
      <PopupButtonContainer>
        <a href={validateURL(rsvpLink)} target="_blank" rel="noreferrer">
          <PrimaryButton Padding="0 15px" Height="40px" Border="none">
            Register for the event
          </PrimaryButton>
        </a>
      </PopupButtonContainer>
    ) : null}
    <Spacer Height="40px" />
  </PopupContainer>
);

EventPopup.defaultProps = { ...eventDefaultProps() };

EventPopup.propTypes = { ...eventPropTypes() };

export default EventPopup;
