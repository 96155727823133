import CompanyTab from 'pages/AdminPage/TabControl/CompanyTab/CompanyTab';

export const TAB_CONTROL_KEYS = {
  COMPANIES: 'Companies',
  RESOURCES: 'Resources',
  EXCLUSIVE_RESOURCES: 'Exclusive Resources',
  EVENTS: 'Events',
  ABOUT: 'About',
};

export const SUB_HEADER_TABS = {
  [TAB_CONTROL_KEYS.COMPANIES]: {
    content: <CompanyTab />,
    width: '100px',
  },
  [TAB_CONTROL_KEYS.RESOURCES]: {
    width: '90px',
  },
  [TAB_CONTROL_KEYS.EXCLUSIVE_RESOURCES]: {
    width: '170px',
  },
  [TAB_CONTROL_KEYS.EVENTS]: {
    width: '55px',
  },
  [TAB_CONTROL_KEYS.ABOUT]: {
    width: '55px',
  },
};
