import { WHITE } from 'constants/colors';
import PropTypes from 'prop-types';

const Check = ({ fill }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2.00009L6 14.0001L0.5 8.50009L1.91 7.09009L6 11.1701L16.59 0.590088L18 2.00009Z"
      fill={fill}
    />
  </svg>
);
Check.defaultProps = {
  fill: WHITE,
};
Check.propTypes = {
  fill: PropTypes.string,
};
export default Check;
