import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import companiesReducer from '../pages/AdminPage/TabControl/CompanyTab/companiesReducer';

export const CompaniesContext = createContext({
  state: null,
  dispatch: null,
});

const CompaniesProvider = ({ children }) => {
  const initState = {};
  const [state, dispatch] = useReducer(companiesReducer, initState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CompaniesContext.Provider value={{ state, dispatch }}>
      {children}
    </CompaniesContext.Provider>
  );
};

CompaniesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CompaniesProvider;
