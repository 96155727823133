const preparePublicResourceData = (formState) => ({
  dealName: formState.dealName.trim(),
  dealUrl: formState.dealUrl,
  dealImage: formState.dealImage && formState.dealImage.base64,
  targetAudiences: formState.targetAudiences.map((category) => category.id),
  categories: formState.categories.map((category) => category.id),
  dealDescription: formState.dealDescription,
  contactName: formState.contactName,
  contactEmail: formState.contactEmail,
  expiringDate: formState.expiringDate
    ? formState.expiringDate.toISOString()
    : null,
});
export default preparePublicResourceData;
