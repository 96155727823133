import { PUBLIC_KEYS } from 'components/Forms/ExclusiveDeals/constants';
import { emailValidator, isEmptyFieldValidator } from 'utils/inputValidators';

const ContactInputSchema = {
  [PUBLIC_KEYS.CONTACT_NAME]: {
    label: 'Your name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your name',
    validator: isEmptyFieldValidator,
  },
  [PUBLIC_KEYS.CONTACT_EMAIL]: {
    label: 'Email address*',
    inputType: 'email',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid email address',
    validator: emailValidator,
  },
};

export default ContactInputSchema;
