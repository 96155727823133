import { createGlobalStyle } from 'styled-components';
import * as colors from 'constants/colors';
import 'react-datepicker/dist/react-datepicker.css';

const GlobalStyle = createGlobalStyle`
    :root {
        --RED_OR_RAISING_BLACK: ${(props) =>
          props.isDark ? colors.RED : colors.RAISIN_BLACK};

        --WHITE_OR_RAISING_BLACK: ${(props) =>
          props.isDark ? colors.WHITE : colors.RAISIN_BLACK};

        --TRANSPARENT_OR_WHITE: ${(props) =>
          props.isDark ? 'transparent' : colors.WHITE};

        --WHITE_OR_DAVY_GRAY: ${(props) =>
          props.isDark ? colors.WHITE : colors.DAVY_GRAY};  

        --RAISING_BLACK_OR_WHITE: ${(props) =>
          props.isDark ? colors.RAISIN_BLACK : colors.WHITE};

        --EERIE_BLACK_OR_LIGHT_GRAY: ${(props) =>
          props.isDark ? colors.EERIE_BLACK : colors.LIGHT_GRAY};

        --EERIE_BLACK_OR_BRIGHT_GRAY: ${(props) =>
          props.isDark ? colors.EERIE_BLACK : colors.BRIGHT_GRAY};
          
        --DAVY_GRAY_OR_LIGHT_GRAY: ${(props) =>
          props.isDark ? colors.DAVY_GRAY : colors.LIGHT_GRAY};

        --GRAY_80_OR_RAISIN_BLACK_ALPHA_03: ${(props) =>
          props.isDark ? colors.GRAY_80 : colors.RAISIN_BLACK_ALPHA_03};

        --BRIGHT_GRAY_OR_DAVY_GRAY: ${(props) =>
          props.isDark ? colors.BRIGHT_GRAY : colors.DAVY_GRAY};

        --DAVY_GRAY_OR_BRIGHT_GRAY: ${(props) =>
          props.isDark ? colors.DAVY_GRAY : colors.BRIGHT_GRAY};

        --GRAY_80_OR_DAVY_GRAY: ${(props) =>
          props.isDark ? colors.GRAY_80 : colors.DAVY_GRAY};

        --GRAY_80_OR_BRIGHT_GRAY: ${(props) =>
          props.isDark ? colors.GRAY_80 : colors.BRIGHT_GRAY};

        --DARK_CHARCOAL_OR_BRIGHT_GRAY: ${(props) =>
          props.isDark ? colors.DARK_CHARCOAL : colors.BRIGHT_GRAY};

        --BRIGHT_GRAY_OR_DARK_CHARCOAL: ${(props) =>
          props.isDark ? colors.BRIGHT_GRAY : colors.DARK_CHARCOAL};

        --DIM_GRAY_OR_BRIGHT_GRAY: ${(props) =>
          props.isDark ? colors.DIM_GRAY : colors.BRIGHT_GRAY};
          
        --DARK_CHARCOAL_OR_WHITE: ${(props) =>
          props.isDark ? colors.DARK_CHARCOAL : colors.WHITE};

        --CRIMSON_OR_WHITE: ${(props) =>
          props.isDark ? colors.CRIMSON : colors.WHITE};

        --WHITE_OR_CRIMSON: ${(props) =>
          props.isDark ? colors.WHITE : colors.CRIMSON};

        --BRIGHT_GRAY_OR_TRANSPARENT: ${(props) =>
          props.isDark ? colors.BRIGHT_GRAY : 'transparent'};  

        --OPACITY_04_OR_1 : ${(props) => (props.isDark ? 1 : 0.4)};

        
        --BLACK_OLIVE_OR_LIGHT_GRAY: ${(props) =>
          props.isDark ? colors.BLACK_OLIVE : colors.LIGHT_GRAY};  

        --FOOTER_DARK_OR_FOOTER_LIGHT: ${(props) =>
          props.isDark
            ? colors.FOOTER_DARK_BACKGROUND
            : colors.FOOTER_LIGHT_BACKGROUND};  

    }

    html {
      height: 100%;
    }
    body {
        height: 100%;
        border-color: var(--WHITE_OR_RAISING_BLACK);
        background-color: var(--RAISING_BLACK_OR_WHITE);
        color: var(--WHITE_OR_RAISING_BLACK);
        font-family: 'Poppins', sans-serif;
        margin: 0;

        @supports (-webkit-overflow-scrolling: touch) { 
          select,
          textarea,
          input {
            font-size: 16px;
          }
        }
    }

    #root {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    a {
      text-decoration: none;
      
      &:focus,
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }
    }
`;

export default GlobalStyle;
