import PropTypes from 'prop-types';
import DropdownHide from 'assets/icons/DropdownHide';
import DropdownShow from 'assets/icons/DropdownShow';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import RadioButtonGroup from 'components/common/RadioButtonGroup/RadioButtonGroup';
import { TEXT_SIZES } from 'components/common/RadioButtonGroup/constants';
import {
  FilterControlsContainer,
  FilterControlsWrapper,
  SortControlContainer,
  SortDropdown,
  SortDropdownField,
} from './FilterControls.styled';
import { companySortOptions } from '../constants';
import ToggleFiltersButton from './ToggleFilters';

const FilterControls = ({ toggleFilters, handleSortTypeChange, sortType }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const contentRef = useRef(null);

  const closeDropDown = () => {
    setDisplayDropdown(false);
  };

  const applySortType = ({ target: { value } }) => handleSortTypeChange(value);

  useClickOutside(contentRef, closeDropDown);

  return (
    <FilterControlsContainer>
      <FilterControlsWrapper>
        <SortControlContainer ref={contentRef}>
          <SortDropdownField
            id="company-sort"
            onClick={() => setDisplayDropdown((prevState) => !prevState)}
          >
            <p>
              Sort by <span>{sortType}</span>
            </p>
            {displayDropdown ? <DropdownHide /> : <DropdownShow />}
          </SortDropdownField>
          {displayDropdown && (
            <SortDropdown>
              <RadioButtonGroup
                key={sortType}
                textSize={TEXT_SIZES.MEDIUM}
                flexDirection="column"
                groupLabel="Date or Alphabetical"
                options={companySortOptions}
                selectedLabel={sortType}
                onChange={applySortType}
                radioButtonFillColor="var(--BRIGHT_GRAY_OR_DAVY_GRAY)"
                radioButtonLabelColor="var(--BRIGHT_GRAY_OR_DAVY_GRAY)"
                radioButtonOutlineColor="var(--DAVY_GRAY_OR_BRIGHT_GRAY)"
              />
            </SortDropdown>
          )}
        </SortControlContainer>
        <Spacer Width="8px" />
        <ToggleFiltersButton toggleFilters={toggleFilters} />
      </FilterControlsWrapper>
    </FilterControlsContainer>
  );
};

FilterControls.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
  handleSortTypeChange: PropTypes.func.isRequired,
  sortType: PropTypes.string.isRequired,
};

export default FilterControls;
