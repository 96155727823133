import styled, { keyframes } from 'styled-components';
import { WHITE } from 'constants/colors';

export const SubscribeWrapper = styled.div`
  background-color: var(--RAISING_BLACK_OR_WHITE);
  border: 1px solid var(--GRAY_80_OR_BRIGHT_GRAY);
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: 500px;
  padding-left: 8px;
`;

export const SubscribeInput = styled.input`
  border: none;
  background: none;
  height: 40px;
  width: calc(100% - 93px);
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  }
`;

const loadingSpinnerAnimation = keyframes`
  to {transform: rotate(360deg);}
`;

export const LoadingSpinner = styled.div`
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: ${(props) =>
      props.color ? `2px solid ${props.color}` : `2px solid ${WHITE}`};
    border-top-color: var(--WHITE_OR_CRIMSON);
    animation: ${loadingSpinnerAnimation} 0.6s linear infinite;
  }
`;

export const SubscribeButton = styled.button`
  align-self: center;
  color: var(--RAISING_BLACK_OR_WHITE);
  background-color: var(--WHITE_OR_CRIMSON);
  border: 1px solid var(--WHITE_OR_CRIMSON);
  border-radius: 4px;
  font-weight: 600;
  height: 40px;
  margin-right: 1px;
  width: 104px;
  cursor: pointer;
  position: relative;
`;
