const EmailSentIcon = () => (
  <svg
    width="154"
    height="138"
    viewBox="0 0 154 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_789_1043)">
      <path
        d="M152.649 52.774C152.594 52.774 152.54 52.7572 152.495 52.7257L77.7751 0.780002C77.5478 0.622658 77.2776 0.53857 77.0009 0.539041C76.7242 0.539512 76.4543 0.624519 76.2274 0.782636L2.04625 52.7251C1.98758 52.7662 1.91496 52.7823 1.84437 52.77C1.77378 52.7576 1.711 52.7178 1.66985 52.6592C1.62869 52.6006 1.61253 52.5281 1.62492 52.4577C1.63731 52.3872 1.67723 52.3245 1.73591 52.2834L75.9172 0.340946C76.2348 0.119614 76.6127 0.000638014 77 2.55882e-06C77.3874 -0.000632896 77.7656 0.117102 78.084 0.33739L152.803 52.2829C152.851 52.3157 152.886 52.3628 152.905 52.4171C152.923 52.4715 152.924 52.5303 152.907 52.5851C152.889 52.6399 152.855 52.6878 152.809 52.7217C152.762 52.7557 152.706 52.774 152.649 52.774L152.649 52.774Z"
        fill="#333333"
      />
      <path
        d="M6.28528 54.6153L77.0745 2.24368L148.401 58.3418L80.7219 98.3926L43.978 90.0319L6.28528 54.6153Z"
        fill="#E6E6E6"
      />
      <path
        d="M47.0795 123.13H12.1079C11.8931 123.131 11.6805 123.089 11.482 123.007C11.2835 122.925 11.1032 122.805 10.9512 122.653C10.7993 122.502 10.6787 122.322 10.5965 122.124C10.5143 121.926 10.4719 121.714 10.4719 121.499C10.4719 121.285 10.5143 121.073 10.5965 120.875C10.6787 120.677 10.7993 120.497 10.9512 120.345C11.1032 120.194 11.2835 120.074 11.482 119.992C11.6805 119.91 11.8931 119.868 12.1079 119.868H47.0795C47.2942 119.868 47.5069 119.91 47.7054 119.992C47.9038 120.074 48.0842 120.194 48.2361 120.345C48.3881 120.497 48.5086 120.677 48.5909 120.875C48.6731 121.073 48.7154 121.285 48.7154 121.499C48.7154 121.714 48.6731 121.926 48.5909 122.124C48.5086 122.322 48.3881 122.502 48.2361 122.653C48.0842 122.805 47.9038 122.925 47.7054 123.007C47.5069 123.089 47.2942 123.131 47.0795 123.13V123.13Z"
        fill="#011F5B"
      />
      <path
        d="M24.6549 116.118H12.1079C11.8931 116.118 11.6805 116.076 11.482 115.994C11.2835 115.913 11.1032 115.793 10.9512 115.641C10.7993 115.49 10.6787 115.31 10.5965 115.112C10.5143 114.914 10.4719 114.701 10.4719 114.487C10.4719 114.273 10.5143 114.06 10.5965 113.862C10.6787 113.664 10.7993 113.484 10.9512 113.333C11.1032 113.182 11.2835 113.061 11.482 112.98C11.6805 112.898 11.8931 112.856 12.1079 112.856H24.6549C24.8696 112.856 25.0823 112.898 25.2808 112.98C25.4793 113.061 25.6596 113.182 25.8116 113.333C25.9635 113.484 26.0841 113.664 26.1663 113.862C26.2485 114.06 26.2909 114.273 26.2909 114.487C26.2909 114.701 26.2485 114.914 26.1663 115.112C26.0841 115.31 25.9635 115.49 25.8116 115.641C25.6596 115.793 25.4793 115.913 25.2808 115.994C25.0823 116.076 24.8696 116.118 24.6549 116.118Z"
        fill="#011F5B"
      />
      <path
        d="M78.0701 83.7857C77.8043 83.786 77.541 83.7345 77.2949 83.6341L33.6371 65.5509V8.9473C33.6377 8.44678 33.8371 7.96692 34.1917 7.61299C34.5462 7.25906 35.0269 7.05997 35.5283 7.05939H119.283C119.784 7.05997 120.265 7.25906 120.619 7.61299C120.974 7.96692 121.173 8.44678 121.174 8.9473V65.5904L121.092 65.6254L78.8688 83.6253C78.6159 83.7313 78.3444 83.7859 78.0701 83.7857Z"
        fill="white"
      />
      <path
        d="M78.0701 83.9204C77.7866 83.9207 77.5059 83.8658 77.2435 83.7589L33.502 65.6409V8.94727C33.5026 8.41098 33.7162 7.89684 34.0961 7.51763C34.476 7.13842 34.991 6.92511 35.5283 6.9245H119.283C119.82 6.92511 120.335 7.13842 120.715 7.51763C121.095 7.89684 121.308 8.41098 121.309 8.94727V65.6794L78.9218 83.7492C78.6522 83.8624 78.3626 83.9206 78.0701 83.9204V83.9204ZM34.0423 65.2806L77.4495 83.2602C77.8544 83.4242 78.3078 83.4215 78.7107 83.2527L120.769 65.3233V8.94727C120.768 8.554 120.611 8.17697 120.333 7.89889C120.054 7.6208 119.677 7.46437 119.283 7.4639H35.5283C35.1343 7.46437 34.7566 7.6208 34.4781 7.89889C34.1995 8.17697 34.0428 8.554 34.0423 8.94727L34.0423 65.2806Z"
        fill="#333333"
      />
      <path
        d="M152.109 52.2346H152.055L121.039 65.4554L78.6022 83.5444C78.4364 83.614 78.2584 83.6503 78.0785 83.6512C77.8986 83.6522 77.7203 83.6177 77.5538 83.5498L33.7719 65.4176L1.99386 52.2562L1.94532 52.2346H1.89123C1.38981 52.2351 0.909094 52.4342 0.554541 52.7882C0.199987 53.1421 0.000556359 53.622 0 54.1225V136.112C0.000557073 136.613 0.199987 137.092 0.554541 137.446C0.909094 137.8 1.38981 137.999 1.89123 138H152.109C152.61 137.999 153.091 137.8 153.445 137.446C153.8 137.092 153.999 136.613 154 136.112V54.1225C153.999 53.622 153.8 53.1421 153.445 52.7882C153.091 52.4342 152.61 52.2351 152.109 52.2346V52.2346ZM153.46 136.112C153.459 136.47 153.317 136.813 153.064 137.065C152.811 137.318 152.467 137.46 152.109 137.461H1.89123C1.533 137.46 1.18948 137.318 0.936173 137.065C0.682866 136.813 0.540494 136.47 0.540351 136.112V54.1225C0.540841 53.7738 0.676347 53.4387 0.918537 53.1874C1.16073 52.9361 1.49085 52.788 1.83991 52.774L33.7719 66.0002L77.3459 84.0487C77.8176 84.2403 78.3461 84.2374 78.8156 84.0406L121.039 66.0407L152.163 52.774C152.511 52.789 152.841 52.9376 153.082 53.1887C153.324 53.4399 153.459 53.7743 153.46 54.1225V136.112Z"
        fill="#333333"
      />
      <path
        d="M77.6337 67.735C76.022 67.738 74.453 67.2177 73.1634 66.2527L73.0834 66.1927L56.2485 53.3259C55.4689 52.7296 54.8145 51.9858 54.3228 51.137C53.8311 50.2882 53.5117 49.3511 53.3828 48.3791C53.2539 47.4071 53.3181 46.4192 53.5716 45.472C53.8251 44.5247 54.2631 43.6366 54.8605 42.8583C55.4579 42.08 56.2029 41.4268 57.0532 40.936C57.9035 40.4451 58.8423 40.1263 59.816 39.9976C60.7897 39.8689 61.7793 39.933 62.7282 40.1861C63.6771 40.4392 64.5668 40.8764 65.3464 41.4727L76.2508 49.8194L102.02 16.272C102.617 15.4939 103.363 14.8409 104.213 14.3504C105.063 13.8598 106.002 13.5413 106.976 13.413C107.95 13.2846 108.939 13.3491 109.888 13.6025C110.837 13.856 111.727 14.2935 112.506 14.8901L112.346 15.1073L112.51 14.8933C114.082 16.0996 115.111 17.8791 115.371 19.8416C115.63 21.8041 115.099 23.7892 113.894 25.3614L83.5841 64.8212C82.8831 65.7305 81.9817 66.4663 80.9497 66.9717C79.9178 67.477 78.7831 67.7382 77.6337 67.735V67.735Z"
        fill="#011F5B"
      />
    </g>
    <defs>
      <clipPath id="clip0_789_1043">
        <rect width="154" height="138" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailSentIcon;
