import {
  SIGN_UP_FIELD_NAME_KEYS,
  SIGN_IN_FIELD_NAME_KEYS,
  SIGN_IN,
  SIGN_UP,
} from 'components/Forms/SignForm/constants';
import {
  isEmptyFieldValidator,
  hbsEmailValidator,
} from 'utils/inputValidators';
import {
  passwordValidator,
  repeatPasswordValidator,
} from 'components/Forms/SignForm/passwordValidator';
import { loginUser, registerUser } from 'services/requests';

export const SIGN_UP_FORM_INITIAL_STATE = {
  [SIGN_UP_FIELD_NAME_KEYS.FULL_NAME]: {
    label: 'Your full name*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your full name',
    validator: isEmptyFieldValidator,
  },
  [SIGN_UP_FIELD_NAME_KEYS.HBS_EMAIL]: {
    label: 'Email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your Wharton email',
    validator: hbsEmailValidator,
  },
  [SIGN_UP_FIELD_NAME_KEYS.PASSWORD]: {
    label: 'Password*',
    inputType: 'password',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Minimum 7 characters required',
    validator: passwordValidator,
  },
  [SIGN_UP_FIELD_NAME_KEYS.CONFIRM_PASSWORD]: {
    label: 'Repeat password*',
    inputType: 'password',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Passwords don’t match',
    validator: repeatPasswordValidator,
  },
};

export const SIGN_IN_FORM_INITIAL_STATE = {
  [SIGN_IN_FIELD_NAME_KEYS.HBS_EMAIL]: {
    label: 'Email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your Wharton email',
    validator: hbsEmailValidator,
  },
  [SIGN_IN_FIELD_NAME_KEYS.PASSWORD]: {
    label: 'Password*',
    inputType: 'password',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your password',
    validator: isEmptyFieldValidator,
  },
};

export const SUBMIT_FUNCS = {
  [SIGN_IN]: loginUser,
  [SIGN_UP]: registerUser,
};
