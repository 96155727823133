import { CONTACT_EMAIL } from 'constants/other';

export const ADD_A_COMPANY = 'Add a company';
export const UPDATE_A_COMPANY = 'Update a company';

export const STEPS = {
  ENTRY_MODAL: 'ENTRY_MODAL',
  SELECT_COMPANY: 'SELECT_COMPANY',
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  STEP_THREE: 'STEP_THREE',
  MESSAGE: 'MESSAGE',
};

export const ADD_PROGRESS = {
  [STEPS.ENTRY_MODAL]: '0%',
  [STEPS.STEP_ONE]: '33%',
  [STEPS.STEP_TWO]: '66%',
  [STEPS.STEP_THREE]: '100%',
  [STEPS.MESSAGE]: '0%',
};

export const UPDATE_PROGRESS = {
  [STEPS.ENTRY_MODAL]: '0%',
  [STEPS.SELECT_COMPANY]: '25%',
  [STEPS.STEP_ONE]: '50%',
  [STEPS.STEP_TWO]: '75%',
  [STEPS.STEP_THREE]: '100%',
  [STEPS.MESSAGE]: '0%',
};

export const STATIC_TEXT = {
  [UPDATE_A_COMPANY]: {
    START_TITLE: 'Update a company',
    START_DESCRIPTION: `If the information for your company is incorrect or needs updating, please first input the company name as it currently appears on WhartonBuilds, and then enter the most updated information below as you wish for it to appear`,
    CONTACT_INFO: `For questions or assistance, please reach out to`,
    CONTACT_EMAIL,

    END_TITLE: 'Thank you!',
    END_DESCRIPTION:
      'Thank you for updating the information about your company and contributing to our community.',
  },
  [ADD_A_COMPANY]: {
    START_TITLE: 'Add a company',
    START_DESCRIPTION: `WhartonBuilds is the definitive platform to find companies with Wharton students or alums on the founding team. Please check (http://whartonbuilds.com/) to make sure your company is not already listed before continuing.`,
    CONTACT_INFO: `For questions or assistance, please reach out to`,
    CONTACT_EMAIL,

    END_TITLE: 'Thank you!',
    END_DESCRIPTION:
      'Thank you for contributing to our community. If you want to access exclusive deals, please go to sign-up button and create an account.',
  },
};

export const blankFounder = {
  fullName: '',
  classYear: new Date().getFullYear(),
  founderEmail: '',
  founderLinkedin: '',
  department: '',
};
