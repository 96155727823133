const UpdatedCompanyIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37" cy="37" r="32" fill="white" />
    <circle cx="37" cy="37" r="36.5" stroke="#212121" />
    <path
      d="M39.06 34L40 34.94L30.92 44H30V43.08L39.06 34ZM42.66 28C42.41 28 42.15 28.1 41.96 28.29L40.13 30.12L43.88 33.87L45.71 32.04C46.1 31.65 46.1 31 45.71 30.63L43.37 28.29C43.17 28.09 42.92 28 42.66 28ZM39.06 31.19L28 42.25V46H31.75L42.81 34.94L39.06 31.19Z"
      fill="#212121"
    />
  </svg>
);

export default UpdatedCompanyIcon;
