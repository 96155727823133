import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  readLocalStorage,
  removeLocalStorageItem,
  writeLocalStorage,
} from 'utils/localStorageUtils';
import getTokenExpirationTimestamp from 'utils/getTokenExpirationTimestamp';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [activeForm, setActiveForm] = useState(null);
  const [successCallback, setSuccessCallback] = useState(null);
  const [user, setUser] = useState(readLocalStorage('user'));
  const removeUser = useCallback(() => {
    setUser(null);
    removeLocalStorageItem('user');
  }, [setUser]);

  const addUser = useCallback(
    (userData) => {
      setUser(userData);
      writeLocalStorage('user', {
        ...userData,
        expiresIn: getTokenExpirationTimestamp(userData.token),
      });
    },
    [setUser],
  );

  const getUserRole = () =>
    !!user && JSON.parse(atob(user.token.split('.')[1])).role;

  const setCallback = (callback) => {
    setSuccessCallback(() => callback);
  };
  const openActiveForm = (form, callback) => {
    if (callback) {
      setCallback(callback);
    }
    setActiveForm(form);
  };
  const closeForm = () => {
    setActiveForm(null);
    setSuccessCallback(null);
  };
  const value = useMemo(
    () => ({
      activeForm,
      setActiveForm: openActiveForm,
      user,
      addUser,
      removeUser,
      getUserRole,
      successCallback,
      setCallback,
      closeForm,
    }),
    [activeForm, setActiveForm, user, successCallback],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
