import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { dataProps } from 'components/Forms/Resources/propTypes';
import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';
import {
  ERROR_CONFIG,
  THANK_YOU_CONFIG,
} from 'components/Forms/Resources/Response/constants';
import { useAppContext } from 'contexts/AppContext';

const Response = ({ resourceData, request }) => {
  const [success, setSuccess] = useState(true);
  const { successCallback } = useAppContext();

  let ignore = false;
  useEffect(() => {
    const addResource = async () => {
      try {
        if (!ignore) {
          const responseData = await request(resourceData);

          if (responseData.status !== StatusCodes.OK)
            throw new Error(`Failed: ${responseData.response}`);
        }

        setSuccess(true);
        if (successCallback) {
          successCallback();
        }
      } catch (error) {
        setSuccess(false);
      }
    };

    addResource();

    return () => {
      ignore = true;
    };
  }, []);

  return <ResponsePopUp {...(success ? THANK_YOU_CONFIG : ERROR_CONFIG)} />;
};

Response.propTypes = {
  resourceData: dataProps().isRequired,
  request: PropTypes.func.isRequired,
};

export default Response;
