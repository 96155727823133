import { DARK_CHARCOAL, DAVY_GRAY } from 'constants/colors';
import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const FounderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 3%;
  width: 100%;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    align-items: center;
  }
`;

export const SingleFounder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;
  width: calc(100% / 3);

  @media ${DEVICE.formResponsive} {
    align-items: center;
    width: 100%;
  }
`;

export const FounderName = styled.p`
  color: ${DARK_CHARCOAL};
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
`;

export const MbaContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.formResponsive} {
    align-items: center;
  }
`;

export const Mba = styled.span`
  color: ${DAVY_GRAY};
  font-size: 12px;
`;

export const Founder = styled.span`
  color: ${DAVY_GRAY};
  font-size: 12px;
`;
