import isURL from 'validator/lib/isURL';
import PropTypes from 'prop-types';
import validateURL from 'utils/linkValidator';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import SUBMIT_BUTTON_CSS from 'pages/EventPage/components/Content/UpcomingEvents/UpcomingEventCard/constants';
import {
  UpcomingEventCardContent,
  UpcomingEventCardDate,
  UpcomingEventCardDateContainer,
  UpcomingEventCardEventName,
  UpcomingEventCardWrapper,
  UpcomingEventsCardFooter,
} from 'pages/EventPage/components/Content/UpcomingEvents/UpcomingEventCard/UpcomingEventCard.styled';
import {
  formatDateGetDay,
  formatDateGetTime,
} from 'pages/EventPage/components/Content/utils';
import {
  eventDefaultProps,
  eventPreviewPropTypes,
} from 'pages/EventPage/components/eventPropTypes';

const UpcomingEventCard = ({ upcomingEvent, handleClick }) => {
  const handleGoToLink = (e) => {
    e.preventDefault();

    if (!!upcomingEvent.rsvpLink && isURL(upcomingEvent.rsvpLink)) {
      window.open(validateURL(upcomingEvent.rsvpLink), '_blank');
    }
  };

  const actionButtonTitle = upcomingEvent.rsvpLink
    ? 'Register for the event'
    : 'View more';

  return (
    <UpcomingEventCardWrapper onClick={handleClick}>
      <UpcomingEventCardContent>
        <UpcomingEventCardEventName>
          {upcomingEvent.eventName}
        </UpcomingEventCardEventName>
        <UpcomingEventsCardFooter>
          <UpcomingEventCardDateContainer>
            <UpcomingEventCardDate>
              {formatDateGetDay(upcomingEvent.eventDate)}
            </UpcomingEventCardDate>
            <UpcomingEventCardDate>
              {formatDateGetTime(upcomingEvent.eventDate)}
            </UpcomingEventCardDate>
          </UpcomingEventCardDateContainer>
          <PrimaryButton {...SUBMIT_BUTTON_CSS} onClick={handleGoToLink}>
            {actionButtonTitle}
          </PrimaryButton>
        </UpcomingEventsCardFooter>
      </UpcomingEventCardContent>
    </UpcomingEventCardWrapper>
  );
};

UpcomingEventCard.defaultProps = {
  ...eventDefaultProps(),
};

UpcomingEventCard.propTypes = {
  upcomingEvent: PropTypes.shape(eventPreviewPropTypes()).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default UpcomingEventCard;
