import PropTypes from 'prop-types';
import {
  dateSortFunction,
  formatDateGetDay,
} from 'pages/EventPage/components/Content/utils';
import { eventsPropTypes } from 'pages/EventPage/components/eventPropTypes';
import { MobileBottomSpacer } from 'pages/EventPage/components/Content/EventPageContent.styled';
import {
  PastEvent,
  PastEventContent,
  PastEventDate,
  PastEventsContainer,
  PastEventTitle,
} from './PastEvents.styled';

const PastEvents = ({ pastEvents, handleEventCardClick }) => (
  <PastEventsContainer>
    {pastEvents
      .sort((eventA, eventB) =>
        dateSortFunction(eventA.eventDate, eventB.eventDate),
      )
      .map((event) => (
        <PastEvent
          key={event.id + event.eventName}
          onClick={() => handleEventCardClick(event.id)}
        >
          <PastEventContent>
            <PastEventTitle>{event.eventName}</PastEventTitle>
            <PastEventDate>{formatDateGetDay(event.eventDate)}</PastEventDate>
          </PastEventContent>
        </PastEvent>
      ))}
    <MobileBottomSpacer />
  </PastEventsContainer>
);

PastEvents.propTypes = {
  pastEvents: eventsPropTypes().isRequired,
  handleEventCardClick: PropTypes.func.isRequired,
};

export default PastEvents;
