import styled from 'styled-components';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import DEVICE from 'constants/deviceSizes';

export const ButtonWrapper = styled.div`
  padding-top: 2%;
`;

export const AddEventButton = styled(PrimaryButton)`
  border: none;
  height: 48px;
  width: 178px;
  margin-right: 24px;
  font-size: 16px;
  font-weight: 600;

  @media ${DEVICE.laptopL} {
    font-size: 14px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    padding: 8px 12px;
  }
`;

export const UpdateEventButton = styled(SecondaryButton)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 178px;
  padding: 11px 20px;
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  border-color: var(--GRAY_80_OR_DAVY_GRAY);

  @media ${DEVICE.laptopL} {
    font-size: 14px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    padding: 8px 12px;
  }
`;
