import PropTypes from 'prop-types';

const BinIcon = ({ fillColor = '#011F5B' }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.49992 0.5V1.33333H0.333252V3H1.16659V13.8333C1.16659 14.2754 1.34218 14.6993 1.65474 15.0118C1.9673 15.3244 2.39122 15.5 2.83325 15.5H11.1666C11.6086 15.5 12.0325 15.3244 12.3451 15.0118C12.6577 14.6993 12.8333 14.2754 12.8333 13.8333V3H13.6666V1.33333H9.49992V0.5H4.49992ZM2.83325 3H11.1666V13.8333H2.83325V3ZM4.49992 4.66667V12.1667H6.16658V4.66667H4.49992ZM7.83325 4.66667V12.1667H9.49992V4.66667H7.83325Z"
      fill={fillColor}
    />
  </svg>
);

BinIcon.defaultProps = {
  fillColor: 'var(--WHITE_OR_CRIMSON)',
};

BinIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default BinIcon;
