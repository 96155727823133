const ForgotPasswordIcon = () => (
  <svg
    width="180"
    height="144"
    viewBox="0 0 180 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M174.344 143.336H5.65619C4.15607 143.336 2.7174 142.741 1.65666 141.682C0.595917 140.623 0 139.187 0 137.689C61.1492 130.594 121.149 130.594 180 137.689C180 139.187 179.404 140.623 178.343 141.682C177.283 142.741 175.844 143.336 174.344 143.336Z"
      fill="#333333"
    />
    <path
      d="M180 138.021L0 137.689L20.8614 102.644L20.9612 102.478V25.2425C20.9611 24.305 21.146 23.3767 21.5053 22.5105C21.8646 21.6444 22.3913 20.8574 23.0552 20.1945C23.7192 19.5317 24.5074 19.0058 25.375 18.6471C26.2425 18.2884 27.1723 18.1038 28.1113 18.1039H150.891C151.83 18.1038 152.759 18.2884 153.627 18.6471C154.494 19.0058 155.283 19.5317 155.947 20.1945C156.611 20.8574 157.137 21.6444 157.497 22.5105C157.856 23.3767 158.041 24.305 158.041 25.2425V103.009L180 138.021Z"
      fill="#767674"
    />
    <path
      d="M27.9484 23.0866C27.3309 23.0873 26.7389 23.3325 26.3023 23.7684C25.8657 24.2043 25.6201 24.7954 25.6194 25.4118V95.8342C25.6201 96.4507 25.8657 97.0417 26.3023 97.4776C26.7389 97.9135 27.3309 98.1588 27.9484 98.1595H152.052C152.669 98.1587 153.261 97.9135 153.698 97.4776C154.135 97.0417 154.38 96.4507 154.381 95.8342V25.4118C154.38 24.7954 154.135 24.2043 153.698 23.7684C153.261 23.3325 152.669 23.0873 152.052 23.0866H27.9484Z"
      fill="white"
    />
    <path
      d="M28.468 107.461C28.2772 107.461 28.0905 107.516 27.9298 107.618C27.7691 107.721 27.6412 107.868 27.5612 108.041L21.1331 121.992C21.0632 122.144 21.0326 122.311 21.0441 122.478C21.0557 122.644 21.1091 122.806 21.1994 122.946C21.2897 123.087 21.414 123.203 21.5609 123.283C21.7077 123.363 21.8724 123.405 22.0398 123.405H157.218C157.388 123.405 157.555 123.362 157.704 123.279C157.853 123.196 157.978 123.077 158.067 122.933C158.157 122.788 158.207 122.623 158.215 122.453C158.223 122.284 158.187 122.115 158.111 121.963L151.124 108.011C151.041 107.846 150.914 107.706 150.756 107.609C150.598 107.511 150.416 107.46 150.231 107.461H28.468Z"
      fill="#545556"
    />
    <path
      d="M89.5006 21.9239C90.3275 21.9239 90.9979 21.2547 90.9979 20.4291C90.9979 19.6036 90.3275 18.9343 89.5006 18.9343C88.6737 18.9343 88.0034 19.6036 88.0034 20.4291C88.0034 21.2547 88.6737 21.9239 89.5006 21.9239Z"
      fill="white"
    />
    <path
      d="M76.2138 126.063C75.9944 126.063 75.7811 126.135 75.607 126.269C75.4328 126.402 75.3074 126.589 75.2501 126.8L73.6377 132.779C73.5978 132.927 73.5925 133.082 73.6222 133.232C73.652 133.382 73.7159 133.523 73.8091 133.645C73.9023 133.766 74.0222 133.864 74.1596 133.932C74.297 134 74.4482 134.035 74.6014 134.035H105.266C105.425 134.035 105.582 133.997 105.723 133.924C105.865 133.851 105.987 133.746 106.079 133.616C106.171 133.487 106.231 133.337 106.254 133.18C106.276 133.023 106.261 132.863 106.209 132.712L104.136 126.733C104.068 126.537 103.94 126.368 103.771 126.248C103.602 126.127 103.4 126.063 103.192 126.063H76.2138Z"
      fill="#333333"
    />
    <path
      d="M158.04 101.315V102.644H20.8611L20.9643 102.478V101.315H158.04Z"
      fill="#333333"
    />
    <path
      d="M154.214 46.5053C167.077 46.5053 177.504 36.0948 177.504 23.2527C177.504 10.4106 167.077 0 154.214 0C141.351 0 130.924 10.4106 130.924 23.2527C130.924 36.0948 141.351 46.5053 154.214 46.5053Z"
      fill="#011F5B"
    />
    <path
      d="M163.863 33.3842H144.566C144.213 33.3839 143.875 33.2438 143.625 32.9947C143.376 32.7456 143.235 32.4078 143.235 32.0555V20.4292C143.235 20.0769 143.376 19.7391 143.625 19.49C143.875 19.2409 144.213 19.1008 144.566 19.1004H163.863C164.216 19.1008 164.554 19.2409 164.804 19.49C165.053 19.7391 165.194 20.0769 165.194 20.4292V32.0555C165.194 32.4078 165.054 32.7456 164.804 32.9947C164.554 33.2438 164.216 33.3839 163.863 33.3842ZM144.566 20.4292V32.0555H163.864L163.863 20.4292L144.566 20.4292Z"
      fill="white"
    />
    <path
      d="M160.203 20.4291H148.225V15.1142C148.225 11.2646 150.744 8.47061 154.214 8.47061C157.684 8.47061 160.203 11.2646 160.203 15.1142L160.203 20.4291ZM149.556 19.1004H158.872V15.1142C158.872 11.9849 156.957 9.79934 154.214 9.79934C151.472 9.79934 149.556 11.985 149.556 15.1142L149.556 19.1004Z"
      fill="white"
    />
    <path
      d="M154.214 27.2388C154.949 27.2388 155.545 26.6439 155.545 25.9101C155.545 25.1763 154.949 24.5814 154.214 24.5814C153.479 24.5814 152.883 25.1763 152.883 25.9101C152.883 26.6439 153.479 27.2388 154.214 27.2388Z"
      fill="white"
    />
    <path
      d="M137.956 74.273H42.044C41.5731 74.2724 41.1216 74.0854 40.7886 73.753C40.4557 73.4205 40.2684 72.9698 40.2678 72.4997V48.7464C40.2684 48.2763 40.4557 47.8256 40.7886 47.4931C41.1216 47.1607 41.5731 46.9737 42.044 46.9731H137.956C138.427 46.9737 138.878 47.1607 139.211 47.4931C139.544 47.8256 139.731 48.2763 139.732 48.7464V72.4997C139.731 72.9698 139.544 73.4205 139.211 73.753C138.878 74.0854 138.427 74.2724 137.956 74.273ZM42.044 47.6825C41.7614 47.6828 41.4905 47.795 41.2907 47.9944C41.091 48.1939 40.9786 48.4643 40.9783 48.7464V72.4997C40.9786 72.7818 41.091 73.0522 41.2907 73.2517C41.4905 73.4511 41.7614 73.5633 42.044 73.5636H137.956C138.238 73.5633 138.509 73.4511 138.709 73.2517C138.909 73.0522 139.021 72.7818 139.021 72.4997V48.7464C139.021 48.4643 138.909 48.1939 138.709 47.9944C138.509 47.795 138.238 47.6828 137.956 47.6825L42.044 47.6825Z"
      fill="#EAEAEA"
    />
    <path
      d="M56.9467 66.935C60.9425 66.935 64.1818 63.701 64.1818 59.7116C64.1818 55.7222 60.9425 52.4882 56.9467 52.4882C52.9509 52.4882 49.7117 55.7222 49.7117 59.7116C49.7117 63.701 52.9509 66.935 56.9467 66.935Z"
      fill="#EAEAEA"
    />
    <path
      d="M73.6565 54.896C73.498 54.8957 73.3409 54.9267 73.1944 54.9871C73.0479 55.0475 72.9148 55.1361 72.8026 55.2479C72.6904 55.3598 72.6014 55.4926 72.5407 55.6387C72.48 55.7849 72.4487 55.9416 72.4487 56.0999C72.4487 56.2582 72.48 56.4149 72.5407 56.561C72.6014 56.7072 72.6904 56.84 72.8026 56.9518C72.9148 57.0637 73.0479 57.1523 73.1944 57.2127C73.3409 57.2731 73.498 57.304 73.6565 57.3038H130.503C130.823 57.3038 131.13 57.1769 131.356 56.9512C131.582 56.7254 131.709 56.4192 131.709 56.0999C131.709 55.7806 131.582 55.4744 131.356 55.2486C131.13 55.0228 130.823 54.896 130.503 54.896H73.6565Z"
      fill="#EAEAEA"
    />
    <path
      d="M73.6565 62.1194C73.3367 62.1194 73.03 62.2462 72.8039 62.472C72.5777 62.6978 72.4507 63.004 72.4507 63.3233C72.4507 63.6426 72.5777 63.9488 72.8039 64.1746C73.03 64.4003 73.3367 64.5272 73.6565 64.5272H98.1179C98.4377 64.5272 98.7444 64.4003 98.9705 64.1746C99.1967 63.9488 99.3237 63.6426 99.3237 63.3233C99.3237 63.004 99.1967 62.6978 98.9705 62.472C98.7444 62.2462 98.4377 62.1194 98.1179 62.1194H73.6565Z"
      fill="#EAEAEA"
    />
  </svg>
);

export default ForgotPasswordIcon;
