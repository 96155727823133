const emptyStringToNull = (str) => (str === '' ? null : str);

const repackFormState = (formState) => ({
  creatorFullName: formState.creatorFullName,
  creatorHbsEmail: formState.creatorHbsEmail,
  creatorHbsClasscardLink: formState.creatorHbsClasscardLink,
  isHiring: formState.isHiring === 'Yes',
  companyName: formState.companyName,
  companyWebsite: emptyStringToNull(formState.companyWebsite),
  foundingYear: +formState.foundingYear.label,
  companyDescription: formState.companyDescription,
  fundingStageId: formState.fundingStage.id,
  companyStageId: formState.companyStage.id,
  companyCardColorId: formState.companyCardColor.id,
  fundraising: formState.fundraising.id,
  founderAttributes: formState.founderAttributes.length
    ? formState.founderAttributes.map((founderAttribute) => founderAttribute.id)
    : null,
  geographicFocus: formState.geographicFocus.length
    ? formState.geographicFocus.map(
        (geographicLocation) => geographicLocation.id,
      )
    : null,
  categories: formState.categories.map((category) => category.id),
  founders: formState.founders.map((founder) => ({
    fullName: founder.fullName.value,
    classYear: +founder.classYear.selectedValue.label,
    founderEmail: founder.founderEmail.value,
    founderLinkedin: emptyStringToNull(founder.founderLinkedin.value),
    position: founder.position,
    department: founder.department.value,
  })),
});

export default repackFormState;
