import Check from 'assets/icons/Check';
import {
  SubscribeWrapper,
  SubscribeInput,
  SubscribeButton,
  LoadingSpinner,
} from 'components/common/SubscribeInput/SubscribeInput.styled';
import { useState } from 'react';
import { subscribeToNewsletter } from 'services/requests';
import { emailValidator } from 'utils/inputValidators';
import { StatusCodes } from 'http-status-codes';
import {
  InputHelperText,
  FormControl,
} from 'components/common/FormComponents/Input/Input.styled';
import InputErrorIcon from 'assets/icons/InputErrorIcon';
import { CRIMSON } from 'constants/colors';

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const subscribe = async () => {
    setLoading(true);

    try {
      const responseData = await subscribeToNewsletter(email);
      if (responseData.status !== StatusCodes.OK)
        throw new Error(`Failed: ${responseData.response}`);
      setEmailSent(true);
    } catch (err) {
      setIsError(true);
      setErrorMsg(err?.response?.data?.message);
    }

    setLoading(false);
  };

  const isEmailValid = emailValidator(email);

  const buttonContent = () => {
    if (!emailSent && loading) return <LoadingSpinner color={CRIMSON} />;

    if (!loading && isError) return <InputErrorIcon />;

    if (emailSent && !loading)
      return <Check fill="var(--RAISING_BLACK_OR_WHITE)" />;

    return 'Subscribe';
  };
  const setNewEmailValue = (value) => {
    setEmail(value);
    setIsError(false);
  };

  return (
    <FormControl maxWidth={500}>
      <SubscribeWrapper>
        <SubscribeInput
          type="email"
          disabled={emailSent}
          placeholder="Enter your email..."
          value={email}
          onChange={({ target: { value } }) => setNewEmailValue(value)}
        />
        <SubscribeButton
          type="button"
          onClick={subscribe}
          disabled={!isEmailValid || emailSent || isError}
        >
          {buttonContent()}
        </SubscribeButton>
      </SubscribeWrapper>
      {isError && <InputHelperText>{errorMsg}</InputHelperText>}
    </FormControl>
  );
};

export default Subscribe;
