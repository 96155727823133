import { useState } from 'react';
import { PROGRESS, STEPS } from 'components/Forms/Events/constants';
import Modal from 'components/common/Modal/Modal';
import EntryModalEvents from 'components/Forms/Events/EntryModal/EntryModalEvents';
import StepOne from 'components/Forms/Events/StepOne/StepOne';
import { defaultEventFormStateProps } from 'components/Forms/Events/propTypes';
import StepTwo from 'components/Forms/Events/StepTwo/StepTwo';
import Response from 'components/Forms/Events/Response/Response';
import SelectEvent from 'components/Forms/Events/SelectEvent/SelectEvent';
import { updateEvent } from 'services/requests';
import { useAppContext } from 'contexts/AppContext';
import useAudienceOranizersData from './useAudienceOranizersData';

const UpdateEvents = () => {
  const { closeForm } = useAppContext();
  const [step, setStep] = useState(STEPS.ENTRY_MODAL);
  const [formState, setFormState] = useState({
    ...defaultEventFormStateProps(),
  });

  const { audience, organizers } = useAudienceOranizersData();

  return (
    <Modal closeForm={closeForm} progress={PROGRESS[step]}>
      {step === STEPS.ENTRY_MODAL && (
        <EntryModalEvents
          nextStep={() => setStep(STEPS.SELECT_EVENT)}
          closeForm={closeForm}
          update
        />
      )}
      {step === STEPS.SELECT_EVENT && (
        <SelectEvent
          nextStep={() => setStep(STEPS.STEP_ONE)}
          closeForm={closeForm}
          setFormState={setFormState}
          audience={audience}
          organizers={organizers}
        />
      )}
      {step === STEPS.STEP_ONE && (
        <StepOne
          currentStep="1/2"
          nextStep={() => setStep(STEPS.STEP_TWO)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
          update
        />
      )}
      {step === STEPS.STEP_TWO && (
        <StepTwo
          currentStep="2/2"
          backStep={() => setStep(STEPS.STEP_ONE)}
          nextStep={() => setStep(STEPS.RESPONSE)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
          update
          audience={audience}
          organizers={organizers}
        />
      )}
      {step === STEPS.RESPONSE && (
        <Response
          formState={formState}
          request={updateEvent}
          update
          closeForm={closeForm}
        />
      )}
    </Modal>
  );
};

export default UpdateEvents;
