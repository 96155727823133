import HBSResources from 'pages/ResourcePage/components/HBSResources/HBSResources';
import {
  ResourcesSection,
  LandingSectionWrapper,
  ResourcesAnchorPartnerWrapper,
  LandingSection,
} from 'pages/ResourcePage/ResourcePage.styled';
import AnchorPartner from 'components/common/AnchorPartner/AnchorPartner';
import IlabInfoSection from 'pages/ResourcePage/components/IlabInfoSection/IlabInfoSection';
import useResources from 'hooks/api/useResources';
import PublicResourcesContainer from './components/PublicResourcesContainer/PublicResourcesContainer';

const ResourcePage = () => {
  const resourcesContext = useResources();
  const { fetchPublicResources } = resourcesContext;
  return (
    <ResourcesSection>
      <LandingSection>
        <LandingSectionWrapper>
          <HBSResources successCallback={fetchPublicResources} />
          <ResourcesAnchorPartnerWrapper>
            <AnchorPartner />
          </ResourcesAnchorPartnerWrapper>
        </LandingSectionWrapper>
      </LandingSection>
      <PublicResourcesContainer resourcesContext={resourcesContext} />
      <IlabInfoSection />
    </ResourcesSection>
  );
};
export default ResourcePage;
