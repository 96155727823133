import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import useRequest from 'hooks/useRequest';
import { PUBLIC_RESOURCES_PARAMS } from 'pages/ResourcePage/components/PublicResourcesContainer/constants';
import usePublicResourcesQueryParams from 'pages/ResourcePage/components/PublicResourcesContainer/usePublicResourcesParams';
import {
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/ResourcePage/constants';
import { useEffect, useState } from 'react';
import {
  getPublicResourceOptions,
  getPublicResources,
} from 'services/requests';

export default function useResources() {
  const {
    publicResourcesQueryParams,
    setCategory,
    setResourceName,
    setSortType,
    resetFilters,
    setTargetAudience,
  } = usePublicResourcesQueryParams();
  const [previewResource, setPreviewResource] = useState(null);
  const [categories, setCategories] = useState([]);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [isPublicFiltersCleared, setIsPublicFiltersCleared] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [publicResourcesData, setPublicResourcesData] = useState({
    data: [],
    total: 0,
  });

  const { request } = useRequest();

  const closePopup = () => setPreviewResource(null);

  const isFiltersApplied = Boolean(
    publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME] ||
      publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.TARGET_AUDIENCE] ||
      publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.CATEGORY],
  );

  const clearFilters = () => {
    if (isFiltersApplied) {
      setFetching(true);
      resetFilters();
      setIsPublicFiltersCleared(!isPublicFiltersCleared);
    }
  };
  const fetchPublicResources = async () => {
    const fetchedResources = await request(() =>
      getPublicResources(publicResourcesQueryParams),
    );

    if (fetchedResources) {
      setPublicResourcesData({
        data: fetchedResources.data.data,
        total: fetchedResources.data.total,
      });

      setFetching(false);
    }
  };

  let ignore = false;
  useEffect(() => {
    if (!ignore) fetchPublicResources();

    return () => {
      ignore = true;
    };
  }, [request, publicResourcesQueryParams]);

  useEffect(() => {
    let catIgnore = false;
    const fetchOptions = async () => {
      const allData = await request(() => getPublicResourceOptions());
      if (!catIgnore && allData) {
        setTargetAudiences(
          mapCategoriesToOptions(
            allData[0].data,
            PUBLIC_RESOURCE_TARGET_AUDIENCES,
          ),
        );
        setCategories(
          mapCategoriesToOptions(allData[1].data, PUBLIC_RESOURCE_CATEGORIES),
        );
      }
    };

    fetchOptions();

    return () => {
      catIgnore = true;
    };
  }, []);

  return {
    setTargetAudiences,
    setCategories,
    fetching,
    publicResourcesData,
    setPreviewResource,
    displayFilters,
    clearFilters,
    categories,
    publicResourcesQueryParams,
    setCategory,
    setResourceName,
    setSortType,
    setTargetAudience,
    targetAudiences,
    isPublicFiltersCleared,
    previewResource,
    closePopup,
    setDisplayFilters,
    fetchPublicResources,
  };
}
