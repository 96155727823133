export const STEP_ONE_KEYS = {
  EVENT_NAME: 'eventName',
  RSVP_LINK: 'rsvpLink',
  EVENT_DATE: 'eventDate',
};

export const GENERAL_INFO_TEXT = {
  DESCRIPTION: 'Events are available for all members of Wharton Community.',
  GRATITUDE: 'Thanks for adding your event!',
};
