import PropTypes from 'prop-types';
import { SORT_TYPES } from 'pages/ExclusiveResources/constants';
import { EXCLUSIVE_RESOURCES_PARAMS } from 'pages/ExclusiveResources/components/ExclusiveResourcesContainer/constants';
import FilterInput from 'components/common/ResourcePages/Filters/FilterInput/FilterInput';
import FilterSelect from 'components/common/ResourcePages/Filters/FilterSelect/FilterSelect';
import { FiltersContainer } from 'components/common/ResourcePages/Filters/Filters.styled';
import ResourcesSort from 'components/common/ResourcePages/Filters/ResourcesSort/ResourcesSort';
import { exclusiveResourcesFiltersSchema } from '../../schema';

const radioButtonOptions = Object.values(SORT_TYPES).map((value) => ({
  label: value,
  name: 'exclusiveResourcesSort',
}));

const ExclusiveResourcesFilters = ({
  // eslint-disable-next-line react/prop-types
  exclusiveResourcesQueryParams,
  orderByParam,
  sortByParam,
  isFiltersCleared,
  categories,
  setCategory,
  setSortType,
  companyName,
  setCompanyName,
  dealDescription,
  setDealDescription,
}) => {
  return (
    <FiltersContainer>
      <FilterInput
        {...exclusiveResourcesFiltersSchema.COMPANY_NAME}
        value={companyName}
        setValue={setCompanyName}
      />
      <FilterInput
        {...exclusiveResourcesFiltersSchema.DEAL_DESCRIPTION}
        value={dealDescription}
        setValue={setDealDescription}
      />
      <FilterSelect
        isFiltersCleared={isFiltersCleared}
        {...exclusiveResourcesFiltersSchema.CATEGORY}
        options={categories}
        value={
          exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.CATEGORY]
        }
        setValue={setCategory}
      />
      <ResourcesSort
        setSortType={setSortType}
        sortedBy={sortByParam}
        orderedBy={orderByParam}
        options={radioButtonOptions}
        {...exclusiveResourcesFiltersSchema.SORT}
      />
    </FiltersContainer>
  );
};

ExclusiveResourcesFilters.propTypes = {
  companyName: PropTypes.string.isRequired,
  dealDescription: PropTypes.string.isRequired,
  sortByParam: PropTypes.string.isRequired,
  orderByParam: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  setCategory: PropTypes.func.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setDealDescription: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  isFiltersCleared: PropTypes.bool.isRequired,
};

export default ExclusiveResourcesFilters;
