import {
  emailValidator,
  isEmptyFieldValidator,
  isTruthy,
  isUrlValid,
} from 'utils/inputValidators';

import STEP_THREE_KEYS from 'components/Forms/Company/StepThree/constants';

const FOUNDER_INITIAL_STATE = {
  [STEP_THREE_KEYS.FULL_NAME]: {
    label: 'Full name*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your full name',
    validator: isEmptyFieldValidator,
  },
  [STEP_THREE_KEYS.EMAIL]: {
    label: 'Email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid email',
    validator: emailValidator,
  },
  [STEP_THREE_KEYS.LINKEDIN]: {
    label: 'LinkedIn account*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a LinkedIn account link',
    validator: isUrlValid,
  },
  [STEP_THREE_KEYS.CLASS_YEAR]: {
    label: 'Class year*',
    placeHolder: 'Select a year',
    MaxHeight: '110px',
    isError: false,
    selectedValue: null,
    validator: isTruthy,
  },
  [STEP_THREE_KEYS.DEPARTMENT]: {
    label: 'Which school were you in at Penn (E.g., WG, GSE, etc.)*',
    MaxHeight: '110px',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a department',
    validator: isEmptyFieldValidator,
  },
};

export const FoundersSelectSchema = {
  [STEP_THREE_KEYS.FOUNDER_ATTRIBUTES]: {
    label: 'Founder(s) attributes',
    placeHolder: 'Pick up to 3 attributes',
    MaxHeight: '170px',
    isError: false,
    disabledDarkMode: true,
    validator: () => true,
  },
};

export default FOUNDER_INITIAL_STATE;
