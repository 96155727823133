import { useState } from 'react';
import styled from 'styled-components';
import { WHITE, DARK_CHARCOAL } from 'constants/colors';

const SvgWrapper = styled.div`
  background-color: ${(props) => (props.hover ? DARK_CHARCOAL : 'transparent')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: 30px;
  width: 30px;
`;

const PageNavigationForward = () => {
  const [hover, setHover] = useState(false);

  return (
    <SvgWrapper hover={hover}>
      <svg
        width="30"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <path
          d="M8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6L8.59 7.41Z"
          style={{
            ...(hover ? { fill: 'var(--WHITE_OR_CRIMSON)' } : { fill: WHITE }),
          }}
        />
      </svg>
    </SvgWrapper>
  );
};

export default PageNavigationForward;
