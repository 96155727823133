import PropTypes from 'prop-types';

import { LinkItUrl } from 'react-linkify-it';
import {
  ButtonsContainer,
  DescriptionContainer,
  DescriptionParagraph,
  Title,
  Wrapper,
} from 'components/Forms/Resources/EntryModal/EntryModal.styled';
import { ADD_A_RESOURCE } from 'components/Forms/Resources/constants';
import RESOURCE_STATIC_TEXT from 'components/Forms/Resources/EntryModal/constants';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { EmailLink } from 'components/Footer/Footer.styled';

const EntryModalResource = ({ nextStep, closeForm }) => (
  <Wrapper>
    <DescriptionContainer>
      <Title>{RESOURCE_STATIC_TEXT[ADD_A_RESOURCE].START_TITLE}</Title>
      <LinkItUrl>
        <DescriptionParagraph>
          {RESOURCE_STATIC_TEXT[ADD_A_RESOURCE].START_DESCRIPTION}
        </DescriptionParagraph>
      </LinkItUrl>
      <DescriptionParagraph>
        {RESOURCE_STATIC_TEXT[ADD_A_RESOURCE].CONTACT_INFO}{' '}
        <EmailLink
          href={`mailto:${RESOURCE_STATIC_TEXT[ADD_A_RESOURCE].CONTACT_EMAIL}`}
        >
          {RESOURCE_STATIC_TEXT[ADD_A_RESOURCE].CONTACT_EMAIL}
        </EmailLink>
      </DescriptionParagraph>
    </DescriptionContainer>
    <ButtonsContainer>
      <DarkButton
        Border="none"
        Height="40px"
        Width="220px"
        type="button"
        onClick={nextStep}
      >
        Start
      </DarkButton>
      <SecondaryButton
        Border="none"
        Height="40px"
        Width="220px"
        type="button"
        noDark
        onClick={closeForm}
      >
        Cancel
      </SecondaryButton>
    </ButtonsContainer>
  </Wrapper>
);

EntryModalResource.propTypes = {
  nextStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default EntryModalResource;
