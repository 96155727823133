import { useAppContext } from 'contexts/AppContext';
import {
  ADD_A_COMPANY,
  UPDATE_A_COMPANY,
} from 'components/Forms/Company/constants';
import AddCompany from 'components/Forms/Company/AddCompany';
import UpdateCompany from 'components/Forms/Company/UpdateCompany';
import UpdateEvent from 'components/Forms/Events/UpdateEvent';
import SignForm from 'components/Forms/SignForm/SignForm';
import { SIGN_IN, SIGN_UP } from 'components/Forms/SignForm/constants';
import AddResource from 'components/Forms/Resources/AddResources';
import { ADD_A_RESOURCE } from 'components/Forms/Resources/constants';
import { ADD_NEW_EVENT, UPDATE_EVENT } from 'components/Forms/Events/constants';
import AddEvents from 'components/Forms/Events/AddEvents';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { FORGOT_PASSWORD } from './ForgotPassword/constants';
import AddExclusiveDeal from './ExclusiveDeals/AddExclusiveDeal';
import { ADD_AN_EXCLUSIVE_DEAL } from './ExclusiveDeals/constants';

const FormContainer = () => {
  const { activeForm } = useAppContext();

  return (
    !!activeForm && (
      <>
        {activeForm === ADD_A_COMPANY && <AddCompany key={activeForm} />}
        {activeForm === UPDATE_A_COMPANY && <UpdateCompany key={activeForm} />}
        {activeForm === UPDATE_EVENT && <UpdateEvent key={activeForm} />}
        {activeForm === ADD_A_RESOURCE && <AddResource key={activeForm} />}
        {activeForm === ADD_AN_EXCLUSIVE_DEAL && (
          <AddExclusiveDeal key={activeForm} />
        )}
        {activeForm === ADD_NEW_EVENT && <AddEvents key={activeForm} />}
        {activeForm === SIGN_IN && <SignForm key={activeForm} />}
        {activeForm === SIGN_UP && <SignForm key={activeForm} />}
        {activeForm === FORGOT_PASSWORD && <ForgotPassword key={activeForm} />}
      </>
    )
  );
};

export default FormContainer;
