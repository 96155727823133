import PropTypes from 'prop-types';
import {
  dateSortFunction,
  formatDateGetDay,
} from 'pages/EventPage/components/Content/utils';
import { eventsPropTypes } from 'pages/EventPage/components/eventPropTypes';
import { MobileBottomSpacer } from 'pages/EventPage/components/Content/EventPageContent.styled';
import {
  AllEvent,
  AllEventContent,
  AllEventDate,
  AllEventsContainer,
  AllEventTitle,
} from './AllEvents.styled';

const AllEvents = ({ allEvents, handleEventCardClick }) => (
  <AllEventsContainer>
    {allEvents
      .sort((eventA, eventB) =>
        dateSortFunction(eventA.eventDate, eventB.eventDate, false),
      )
      .map((event) => (
        <AllEvent
          key={event.id + event.eventName}
          onClick={() => handleEventCardClick(event.id)}
        >
          <AllEventContent>
            <AllEventTitle>{event.eventName}</AllEventTitle>
            <AllEventDate>{formatDateGetDay(event.eventDate)}</AllEventDate>
          </AllEventContent>
        </AllEvent>
      ))}
    <MobileBottomSpacer />
  </AllEventsContainer>
);

AllEvents.propTypes = {
  allEvents: eventsPropTypes().isRequired,
  handleEventCardClick: PropTypes.func.isRequired,
};

export default AllEvents;
