import PropTypes from 'prop-types';
import {
  Wrapper,
  DescriptionContainer,
} from 'components/Forms/ExclusiveDeals/StepOne/StepOne.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import generalInfoInputSchema from 'components/Forms/ExclusiveDeals/StepOne/schemas';
import { GENERAL_INFO_TEXT } from 'components/Forms/ExclusiveDeals/StepOne/constants';
import { useState } from 'react';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { exclusiveFormStateProps } from 'components/Forms/ExclusiveDeals/propTypes';
import {
  BUTTONS_CSS,
  EXCLUSIVE_KEYS,
} from 'components/Forms/ExclusiveDeals/constants';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  ButtonsContainer,
} from '../AddExclusiveDeal.styled';

const StepOne = ({
  currentStep,
  closeForm,
  nextStep,
  setFormState,
  formState,
}) => {
  const [formInputState, setFormInputState] = useState(generalInfoInputSchema);

  const { onBlurHandler, triggerError } = useFormInput();

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      setFormInputState((prevState) => ({
        ...prevState,
        [EXCLUSIVE_KEYS.COMPANY_NAME]: {
          ...prevState[EXCLUSIVE_KEYS.COMPANY_NAME],
          isError:
            prevState[EXCLUSIVE_KEYS.COMPANY_NAME].isError &&
            !prevState[EXCLUSIVE_KEYS.COMPANY_NAME].validator(value),
        },
      }));
    };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !formInputState[EXCLUSIVE_KEYS.COMPANY_NAME].validator(
        formState[EXCLUSIVE_KEYS.COMPANY_NAME],
      )
    ) {
      triggerError(setFormInputState, EXCLUSIVE_KEYS.COMPANY_NAME);
      return;
    }

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>{`Step ${currentStep}`}</Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer onSubmit={onSubmit}>
        <DescriptionContainer>
          <b>{GENERAL_INFO_TEXT.DESCRIPTION_LABEL}</b>
          <Spacer Height="5px" />
          <p>{GENERAL_INFO_TEXT.DESCRIPTION} </p>
        </DescriptionContainer>
        <Spacer Height="20px" />
        <FormInput
          {...formInputState[EXCLUSIVE_KEYS.COMPANY_NAME]}
          name="name"
          key={EXCLUSIVE_KEYS.COMPANY_NAME}
          value={formState[EXCLUSIVE_KEYS.COMPANY_NAME]}
          onBlurHandler={onBlurHandler(
            setFormInputState,
            EXCLUSIVE_KEYS.COMPANY_NAME,
          )}
          onChangeHandler={onChangeHandler(EXCLUSIVE_KEYS.COMPANY_NAME)}
        />
        <DescriptionContainer>
          <p>{GENERAL_INFO_TEXT.GRATITUDE} </p>
        </DescriptionContainer>
        <ButtonsContainer>
          <SecondaryButton
            {...BUTTONS_CSS.secondary}
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <DarkButton {...BUTTONS_CSS.primary}>Next: Resource Info</DarkButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepOne.propTypes = {
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: exclusiveFormStateProps().isRequired,
};

export default StepOne;
