import PropTypes from 'prop-types';

export const AdminCompanyPopupDefaultProps = () => ({
  foundingYear: null,
  fundingStage: null,
  companyWebsite: null,
  creatorHbsClasscardLink: null,
  geographicFocus: [],
  founderAttributes: [],
  founders: {
    classYear: null,
  },
});

export const AdminCompanyPopupPropTypes = () => ({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  companyWebsite: PropTypes.string,
  isHiring: PropTypes.bool.isRequired,
  companyStage: PropTypes.string.isRequired,
  foundingYear: PropTypes.number,
  fundingStage: PropTypes.string,
  creatorHbsClasscardLink: PropTypes.string,
  creatorHbsEmail: PropTypes.string.isRequired,
  geographicFocus: PropTypes.arrayOf(PropTypes.string),
  founderAttributes: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyDescription: PropTypes.string.isRequired,
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      classYear: PropTypes.number,
      position: PropTypes.number.isRequired,
      department: PropTypes.string,
    }),
  ),
  closeForm: PropTypes.func.isRequired,
});
