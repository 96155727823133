import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import 'react-datepicker/dist/react-datepicker.css';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0px 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    justify-content: center;
    height: auto;
    margin: 0px 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    gap: 0px;
  }
`;

export const FieldContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const TooltipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 10;
`;

export const ImagePreview = styled.div``;
