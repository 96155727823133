import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FieldContainer,
  RowContainer,
  ClickableDiv,
} from 'components/Forms/Company/StepThree/StepThree.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import AddIcon from 'assets/icons/AddIcon';
import FOUNDER_INITIAL_STATE from 'components/Forms/Company/StepThree/schema';
import { Divider } from 'components/common/Divider/Divider.styled';
import { RAISIN_BLACK_ALPHA_03 } from 'constants/colors';
import {
  founderInputProps,
  founderSelectProps,
} from 'components/Forms/Company/StepThree/Founder/propTypes';
import Founder from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/Founders/Founder';

const Founders = ({ founders, setFounders }) => {
  const [numberOfFounders, setNumberOfFounders] = useState(founders.length);

  const setFounder = (position) => (founderState) => {
    const index = founders.findIndex(
      (founder) => founder.position === position,
    );
    const foundersCopy = founders;

    foundersCopy[index] = founderState;

    setFounders(foundersCopy);
  };

  const sliceFounders = (position) =>
    founders
      .filter((founder) => founder.position !== position)
      .map((founder) =>
        founder.position > position
          ? { ...founder, position: founder.position - 1 }
          : founder,
      );

  const removeFounder = (e, position) => {
    e.preventDefault();

    setFounders(sliceFounders(position));
    setNumberOfFounders((prevState) => prevState - 1);
  };

  const addFounder = (e) => {
    e.preventDefault();

    setNumberOfFounders((prevState) => prevState + 1);
    setFounders([
      ...founders,
      { ...FOUNDER_INITIAL_STATE, position: numberOfFounders + 1 },
    ]);
  };

  return (
    <>
      <Spacer Height="20px" />
      <Divider color={RAISIN_BLACK_ALPHA_03} />
      <Spacer Height="25px" />
      <div key={numberOfFounders}>
        {founders.map((founder) => (
          <Founder
            key={founder.position}
            founder={founder}
            setFounder={setFounder(founder.position)}
            position={+founder.position}
            removeFounder={removeFounder}
          />
        ))}
      </div>

      <RowContainer>
        <FieldContainer>
          <ClickableDiv>
            <p>
              WBSBer #{numberOfFounders + 1} on Founding Team (if applicable)
            </p>
            <button onClick={addFounder} type="button">
              <AddIcon />
            </button>
          </ClickableDiv>
          <Spacer Heigth="30px" />
        </FieldContainer>
      </RowContainer>
    </>
  );
};

Founders.propTypes = {
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: founderInputProps(),
      classYear: founderInputProps(),
      founderLinkedin: founderSelectProps(),
      founderEmail: founderInputProps(),
      department: founderInputProps(),
    }),
  ).isRequired,
  setFounders: PropTypes.func.isRequired,
};

export default Founders;
