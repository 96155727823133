import {
  founderInputProps,
  founderSelectProps,
} from 'components/Forms/Company/StepThree/Founder/propTypes';
import PropTypes, { bool } from 'prop-types';

const { shape, number, string, arrayOf } = PropTypes;

export const defaultFormStateProp = () => ({
  creatorFullName: '',
  creatorHbsEmail: '',
  creatorHbsClasscardLink: '',
  isHiring: 'No',
  companyName: '',
  companyWebsite: '',
  foundingYear: null,
  companyDescription: '',
  personalEmail: '',
  companyCardColor: null,
  companyStage: null,
  fundingStage: null,
  fundraising: null,
  founderAttributes: [],
  geographicFocus: [],
  categories: [],
  founders: [],
});

export const formStateProp = () =>
  shape({
    id: number,
    creatorFullName: string,
    creatorHbsEmail: string,
    creatorHbsClasscardLink: string,
    isHiring: string,
    companyName: string,
    companyWebsite: string,
    foundingYear: shape({
      id: PropTypes.number,
      label: PropTypes.string,
      bgColor: PropTypes.string,
      color: PropTypes.string,
    }),
    companyDescription: string,
    personalEmail: string,
    companyCardColor: shape({
      colorName: string,
      colorValue: string,
      id: number,
    }),
    companyStage: shape({
      stageName: string,
      id: number,
    }),
    fundingStage: shape({
      fundingStageName: string,
      id: number,
    }),
    fundraising: shape({
      id: bool,
      fundraisingName: string,
    }),
    founderAttributes: arrayOf(
      shape({
        id: number,
        label: string,
      }),
    ),
    geographicFocus: arrayOf(
      shape({
        geographicLocationName: string,
        id: number,
      }),
    ),
    categories: arrayOf(
      shape({
        companyCategoryName: string,
        id: number,
      }),
    ),
    founders: arrayOf(
      shape({
        founderEmail: founderInputProps(),
        fullName: founderInputProps(),
        classYear: founderSelectProps(),
        position: PropTypes.number,
        department: founderSelectProps(),
      }),
    ),
  });
