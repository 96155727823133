import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-bottom: 60px;

  @media ${DEVICE.formResponsive} {
    max-height: 250px;
  }
`;

export const RedLine = styled.div`
  background: var(--WHITE_OR_CRIMSON);
  height: 8px;
  margin: 25px 0;
  width: 80px;

  @media ${DEVICE.formResponsive} {
    margin: 12px 0;
  }

  @media ${DEVICE.tablet} {
    width: 60px;
    height: 5px;
  }
`;

export const Title = styled.p`
  color: var(--WHITE_OR_CRIMSON);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  max-width: 603px;
  margin: 16px 0;

  @media ${DEVICE.formResponsive} {
    font-size: 24px;
    line-height: 36px;
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  margin: 0;
  max-height: 110px;
  max-width: 664px;

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const IlabLink = styled.a`
  color: var(--WHITE_OR_CRIMSON);
`;
