import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DAVY_GRAY } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const Container = styled.header`
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 28px 40px;
  width: calc(100% - 80px);

  @media ${DEVICE.laptop} {
    padding: 10px 0 0 20px;
    width: auto;
  }
`;

export const HBSBuilds = styled.div`
  margin-top: 5px;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;

export const StyledLink = styled(Link)`
  color: var(--WHITE_OR_RAISING_BLACK);
`;

export const ToggleContainer = styled.section`
  display: flex;
  margin-left: 40px;
  justify-content: space-between;

  @media ${DEVICE.laptop} {
    margin-left: 30px;
  }

  @media ${DEVICE.laptop} {
    display: none;
    margin-left: 0;
    min-width: auto;
  }
`;

export const Title = styled.h1`
  color: var(--WHITE_OR_CRIMSON);
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

export const Nav = styled.nav`
  align-items: center;
  gap: 0 40px;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 330px);

  span {
    cursor: pointer;
    font-size: 14px;
  }

  @media ${DEVICE.laptop} {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 40px;

  @media ${DEVICE.laptop} {
    gap: 0 30px;
  }
`;

export const UserInfo = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const HeaderTooltipContainer = styled.div`
  position: absolute;
  right: 130px;
  top: 90px;

  svg > path {
    fill: ${(props) =>
      props.disabledDarkMode ? DAVY_GRAY : 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)'};
  }
`;

export const DropdownMenu = styled.div`
  align-items: center;
  background-color: var(--RAISING_BLACK_OR_WHITE);
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-height: ${(props) => props.MaxHeight};
  padding-top: 10px;
  right: 30px;
  position: absolute;
  top: 65px;
  z-index: 30;
  width: 130px;
`;

export const DropdownButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.Color};
  font-size: 14px;
  font-family: 'Poppins';
  margin: 10px;
  z-index: 10;

  &:hover {
    opacity: 0.8;
  }
`;
