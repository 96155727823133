export const COMPANIES_ACTIONS = {
  DATA_RECEIVED: 'DATA_RECEIVED',
  DATA_ERROR: 'DATA_ERROR',
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
};

export const loadingStarted = () => ({
  type: COMPANIES_ACTIONS.LOADING_STARTED,
});

export const loadingFinished = () => ({
  type: COMPANIES_ACTIONS.LOADING_FINISHED,
});

export const dataReceived = (data) => ({
  type: COMPANIES_ACTIONS.DATA_RECEIVED,
  payload: data,
});

export const dataError = (error) => ({
  type: COMPANIES_ACTIONS.DATA_ERROR,
  payload: error,
});
