import PropTypes from 'prop-types';
import {
  StyledAlert,
  StyledAlertText,
} from 'components/common/Alert/Alert.styled';
import InputErrorIcon from 'assets/icons/InputErrorIcon';

const AlertDanger = ({ alertText, textColor, bgColor, showIcon }) => (
  <StyledAlert bgColor={bgColor}>
    <StyledAlertText textColor={textColor}>{alertText}</StyledAlertText>
    {showIcon && <InputErrorIcon />}
  </StyledAlert>
);

AlertDanger.defaultProps = {
  bgColor: 'rgba(165, 28, 48, 0.2)',
  textColor: 'var(--WHITE_OR_CRIMSON)',
  showIcon: true,
};

AlertDanger.propTypes = {
  alertText: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default AlertDanger;
