import {
  AboutContainer,
  WelcomeAndAnchorContainer,
} from 'pages/AboutPage/AboutPage.styled';
import AboutSection from 'pages/AboutPage/AboutSection/AboutSection';
import AnchorPartnerInfo from 'pages/AboutPage/AboutSection/AnchorPartnerSection/AnchorPartnerSection';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const AboutPage = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && document.getElementById(hash))
      document.getElementById(hash).scrollIntoView();
  }, [hash]);

  return (
    <AboutContainer>
      <WelcomeAndAnchorContainer>
        <AboutSection />
        <AnchorPartnerInfo />
      </WelcomeAndAnchorContainer>
      {/* <FounderCategories /> */}
    </AboutContainer>
  );
};

export default AboutPage;
