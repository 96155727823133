import Modal from 'components/common/Modal/Modal';
import ResourcePopup from 'components/common/ResourcePages/ResourcePopup/ResourcePopup';
import ResourceCard from 'components/common/ResourcePages/ResourceCard/ResourceCard';
import Carousel from 'components/common/Carousel/Carousel.styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  RESOURCES_CAROUSEL_SETTINGS,
  STARTUP_GUIDE_SITE_LINK,
} from 'pages/ResourcePage/constants';
import { mapPublicData } from 'pages/ResourcePage/utils';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import ResourcesNotFound from 'components/common/ResourcePages/ResourcesNotFound/ResourcesNotFound';
import FilterControls from 'components/common/ResourcePages/FilterControls/FilterControls';
import PropTypes from 'prop-types';
import PublicResourcesFilters from './components/PublicResourcesFilters/PublicResourcesFilters';
import {
  ResourcesContainer,
  ResourcesHeading,
  StartupLink,
} from '../../ResourcePage.styled';
import { PUBLIC_RESOURCES_PARAMS } from './constants';

const PublicResourcesContainer = ({ resourcesContext }) => {
  const {
    fetching,
    publicResourcesData,
    setPreviewResource,
    displayFilters,
    clearFilters,
    categories,
    publicResourcesQueryParams,
    setCategory,
    setResourceName,
    setSortType,
    setTargetAudience,
    targetAudiences,
    isPublicFiltersCleared,
    previewResource,
    closePopup,
    setDisplayFilters,
  } = resourcesContext;

  const getDisplay = () => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!publicResourcesData.data.length) return <ResourcesNotFound />;

    return (
      <Carousel {...RESOURCES_CAROUSEL_SETTINGS}>
        {publicResourcesData.data.map((publicResource) => (
          <ResourceCard
            key={publicResource.dealName}
            {...mapPublicData(publicResource)}
            previewResourceDetails={() => setPreviewResource(publicResource)}
          />
        ))}
      </Carousel>
    );
  };

  return (
    <ResourcesContainer>
      <ResourcesHeading>Marquee Resources & Opportunities</ResourcesHeading>
      <StartupLink
        href={STARTUP_GUIDE_SITE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        *The Rock Center&apos;s Startup Guide
      </StartupLink>
      <ResourcesHeading>
        General Student & Alumni Opportunities & Resources (
        {publicResourcesData.total})
      </ResourcesHeading>
      <FilterControls
        toggleFilters={() => setDisplayFilters(!displayFilters)}
        clearFilters={clearFilters}
      />
      {displayFilters && (
        <PublicResourcesFilters
          publicResourcesQueryParams={publicResourcesQueryParams}
          categories={categories}
          orderByParam={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.ORDER_BY]
          }
          resourceName={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME]
          }
          sortByParam={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.SORT_BY]
          }
          setCategory={setCategory}
          setResourceName={setResourceName}
          setSortType={setSortType}
          setTargetAudience={setTargetAudience}
          targetAudiences={targetAudiences}
          isFiltersCleared={isPublicFiltersCleared}
        />
      )}
      {!!previewResource && (
        <Modal closeOnEscape closeForm={closePopup}>
          <ResourcePopup {...mapPublicData(previewResource)} />
        </Modal>
      )}
      {getDisplay()}
    </ResourcesContainer>
  );
};

PublicResourcesContainer.propTypes = {
  resourcesContext: PropTypes.shape({
    fetching: PropTypes.bool,
    publicResourcesData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      total: PropTypes.number,
    }),
    setPreviewResource: PropTypes.func,
    displayFilters: PropTypes.bool,
    clearFilters: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    publicResourcesQueryParams: PropTypes.shape(Object),
    setCategory: PropTypes.func,
    setResourceName: PropTypes.func,
    setSortType: PropTypes.func,
    setTargetAudience: PropTypes.func,
    targetAudiences: PropTypes.arrayOf(PropTypes.shape(Object)),
    isPublicFiltersCleared: PropTypes.bool,
    previewResource: PropTypes.shape(Object),
    closePopup: PropTypes.func,
    setDisplayFilters: PropTypes.func,
  }).isRequired,
};

export default PublicResourcesContainer;
