import { RED } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const StatusBoxContainer = styled.div`
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 2px 10px 1px rgba(170, 67, 78, 0.5);
  background: ${(props) => (props.isActive ? RED : 'var(--WHITE_OR_CRIMSON)')};
  min-height: 114px;
  display: flex;
  gap: 40px;
  justify-content: center;
  width: 100%;
  color: var(--RAISING_BLACK_OR_WHITE);

  @media ${DEVICE.laptopL} {
    gap: 20px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const StatusBoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusBoxValue = styled.p`
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  margin: 0;
`;

export const StatusBoxDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;

  @media ${DEVICE.laptop} {
    max-width: 120px;
  }
`;
