import { useLocation, useNavigate } from 'react-router-dom';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import Modal from 'components/common/Modal/Modal';
import useFetchCompanyById from 'pages/AdminPage/components/Forms/Company/EditCompany/useFetchCompanyById';
import useEditModalOptions from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/hooks/useEditModalOptions';
import EditCombinedSteps from './EditCombinedSteps/EditCombinedSteps';

const EditCompany = () => {
  const {
    state: { ids },
  } = useLocation();

  const navigate = useNavigate();
  const closeForm = () => navigate({ pathname: '/administration' });

  const { isLoading, initialCompanyData } = useFetchCompanyById(ids);

  const {
    isFetchingOptions,
    yearOptions,
    cardColors,
    categories,
    companyStages,
    fundingStages,
    geographicFocus,
    founderAttributes,
  } = useEditModalOptions();

  return !isLoading && !isFetchingOptions ? (
    <Modal closeForm={closeForm} closeOnEscape>
      <EditCombinedSteps
        closeForm={closeForm}
        initialCompanyData={initialCompanyData}
        yearOptions={yearOptions}
        cardColors={cardColors}
        categories={categories}
        companyStages={companyStages}
        fundingStages={fundingStages}
        geographicFocus={geographicFocus}
        founderAttributes={founderAttributes}
      />
    </Modal>
  ) : (
    // TODO: Fix loading spinner position
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  );
};

export default EditCompany;
