import CATEGORY_COLORS from 'constants/category_colors';

export const SORT_TYPES = {
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  ALPHABETICAL_ASC: 'A-Z',
  ALPHABETICAL_DESC: 'Z-A',
  EXPIRY_DATE_NEWEST: 'Expiry date - Newest',
  EXPIRY_DATE_OLDEST: 'Expiry date - Oldest',
};

export const RESOURCES_SORT_PARAMS = {
  SORT_BY: 'sortBy',
  ORDER_BY: 'orderBy',
};

export const RESOURCES_PAGINATION_PARAMS = {
  LIMIT: 'limit',
  OFFSET: 'offset',
};

export const resourcesParamsActions = {
  SET_FILTER_OR_PAGINATION_PARAM: 'SET_FILTER_OR_PAGINATION_PARAM',
  SET_SORT_PARAM: 'SET_SORT_PARAM',
  SET_INITIAL_QUERY_PARAMS: 'SET_INITIAL_QUERY_PARAMS',
};

export const sortConfig = {
  NEWEST: {
    [RESOURCES_SORT_PARAMS.SORT_BY]: 'createdAt',
    [RESOURCES_SORT_PARAMS.ORDER_BY]: 'DESC',
  },
  OLDEST: {
    [RESOURCES_SORT_PARAMS.SORT_BY]: 'createdAt',
    [RESOURCES_SORT_PARAMS.ORDER_BY]: 'ASC',
  },
  ALPHABETICAL_DESC: {
    [RESOURCES_SORT_PARAMS.SORT_BY]: 'name',
    [RESOURCES_SORT_PARAMS.ORDER_BY]: 'DESC',
  },
  ALPHABETICAL_ASC: {
    [RESOURCES_SORT_PARAMS.SORT_BY]: 'name',
    [RESOURCES_SORT_PARAMS.ORDER_BY]: 'ASC',
  },
  EXPIRY_DATE_NEWEST: {
    [RESOURCES_SORT_PARAMS.SORT_BY]: 'expiringDate',
    [RESOURCES_SORT_PARAMS.ORDER_BY]: 'DESC',
  },
  EXPIRY_DATE_OLDEST: {
    [RESOURCES_SORT_PARAMS.SORT_BY]: 'expiringDate',
    [RESOURCES_SORT_PARAMS.ORDER_BY]: 'ASC',
  },
};

export const RESOURCES_CAROUSEL_SETTINGS = {
  infinite: false,
  responsive: [
    {
      breakpoint: 3850,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10,
      },
    },
    {
      breakpoint: 3720,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9,
      },
    },
    {
      breakpoint: 3440,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: 2960,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 2480,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 2100,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1720,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1360,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 690,
      settings: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: false,
        prevArrow: false,
      },
    },
  ],
};

export const PUBLIC_RESOURCE_CATEGORIES = {
  [CATEGORY_COLORS.BLUE]: ['Student Group / Club', 'Conference'],
  [CATEGORY_COLORS.SKY_BLUE]: ['VC with Campus Focus', 'Social Impact Fund'],
  [CATEGORY_COLORS.TURQUOISE]: [
    'VC with Student Representatives',
    'Community Groups',
  ],
  [CATEGORY_COLORS.GREEN]: ['University Run / Sponsored Program', 'I-Lab'],
  [CATEGORY_COLORS.YELLOW]: ['Accelerator / Incubator', 'Rock Center'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Hackathon'],
  [CATEGORY_COLORS.SALMON]: ['Pitch / Business Competition'],
  [CATEGORY_COLORS.LAVANDER]: ['Founder / Entrepreneurship Fellowship'],
  [CATEGORY_COLORS.PURPLE]: ['VC / Investor Fellowship'],
  [CATEGORY_COLORS.GRAY]: ['Grant'],
};

export const PUBLIC_RESOURCE_TARGET_AUDIENCES = {
  [CATEGORY_COLORS.BLUE]: ['All Current Students', 'Female Founders'],
  [CATEGORY_COLORS.SKY_BLUE]: ['MBA Students', 'MA Startups'],
  [CATEGORY_COLORS.TURQUOISE]: ['Undergrad', 'Grad Students'],
  [CATEGORY_COLORS.GREEN]: ['Alumni'],
  [CATEGORY_COLORS.YELLOW]: ['i-lab members'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Everyone'],
  [CATEGORY_COLORS.SALMON]: ['St. Louis Area'],
  [CATEGORY_COLORS.LAVANDER]: ['DEI Founders'],
  [CATEGORY_COLORS.PURPLE]: ['Black Founders'],
  [CATEGORY_COLORS.GRAY]: ['LatinX Founders'],
};

export const EXCLUSIVE_RESOURCE_CATEGORIES = {
  [CATEGORY_COLORS.BLUE]: ['Banking', 'Sales', 'Swag', 'Email API'],
  [CATEGORY_COLORS.SKY_BLUE]: [
    'Business Intelligence',
    'CRM',
    'Naming',
    'Cloud Communications',
  ],
  [CATEGORY_COLORS.TURQUOISE]: [
    'Chat',
    'HR & Payroll',
    'Analytics / Data Services',
    'Influence Marketing',
  ],
  [CATEGORY_COLORS.GREEN]: [
    'Cloud Services',
    'Legal',
    'Insurance',
    'Accounting and Finance',
  ],
  [CATEGORY_COLORS.YELLOW]: [
    'Design',
    'Financial Services',
    'Website Design',
    'PR',
  ],
  [CATEGORY_COLORS.WARM_YELLOW]: [
    'Security',
    'Housing/Coworking',
    'Website Building',
    'Coaching',
  ],
  [CATEGORY_COLORS.SALMON]: ['Software', 'Logistics', 'ATS', 'R&D Tax Credit'],
  [CATEGORY_COLORS.LAVANDER]: [
    'Finance',
    'Project/Workforce Management & Tools',
    'OKR Tracking',
    'Office Supplies',
  ],
  [CATEGORY_COLORS.PURPLE]: [
    'Marketing',
    'Mental Health',
    'Benchmarking',
    'Messaging Platform',
    'Payment Services',
  ],
  [CATEGORY_COLORS.GRAY]: [
    'Content Marketing',
    'Hiring / Recruiting',
    'Cap Table Management',
  ],
};

export const STARTUP_GUIDE_SITE_LINK = 'https://startupguide.hbs.edu/';
