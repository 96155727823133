import { useState } from 'react';
import {
  ADD_RESOURCE_PROGRESS,
  ADD_RESOURCE_STEPS,
} from 'components/Forms/Resources/constants';
import Modal from 'components/common/Modal/Modal';
import EntryModalResource from 'components/Forms/Resources/EntryModal/EntryModalAdd';
import StepOne from 'components/Forms/Resources/StepOne/StepOne';
import StepTwoPublic from 'components/Forms/Resources/StepTwo/PublicResource/StepTwoPublic';
import StepThree from 'components/Forms/Resources/StepThree/StepThree';
import Response from 'components/Forms/Resources/Response/Response';
import { postPublicResource } from 'services/requests';
import { defaultPublicFormStateProps } from 'components/Forms/Resources/propTypes';
import preparePublicResourceData from 'components/Forms/Resources/utils';
import { useAppContext } from 'contexts/AppContext';

const AddResource = () => {
  const { closeForm } = useAppContext();
  const [step, setStep] = useState(ADD_RESOURCE_STEPS.ENTRY_MODAL);

  const [formState, setFormState] = useState({
    ...defaultPublicFormStateProps(),
  });

  return (
    <Modal closeForm={closeForm} progress={ADD_RESOURCE_PROGRESS[step]}>
      {step === ADD_RESOURCE_STEPS.ENTRY_MODAL && (
        <EntryModalResource
          nextStep={() => setStep(ADD_RESOURCE_STEPS.STEP_ONE)}
          closeForm={closeForm}
        />
      )}
      {step === ADD_RESOURCE_STEPS.STEP_ONE && (
        <StepOne
          currentStep="1/3"
          nextStep={() => setStep(ADD_RESOURCE_STEPS.STEP_TWO)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {step === ADD_RESOURCE_STEPS.STEP_TWO && (
        <StepTwoPublic
          currentStep="2/3"
          backStep={() => setStep(ADD_RESOURCE_STEPS.STEP_ONE)}
          nextStep={() => setStep(ADD_RESOURCE_STEPS.STEP_THREE)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {step === ADD_RESOURCE_STEPS.STEP_THREE && (
        <StepThree
          currentStep="3/3"
          backStep={() => setStep(ADD_RESOURCE_STEPS.STEP_TWO)}
          nextStep={() => setStep(ADD_RESOURCE_STEPS.RESPONSE)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {step === ADD_RESOURCE_STEPS.RESPONSE && (
        <Response
          resourceData={preparePublicResourceData(formState)}
          request={postPublicResource}
        />
      )}
    </Modal>
  );
};

export default AddResource;
