import {
  INITIAL_ALERT_STATE,
  SIGN_IN,
} from 'components/Forms/SignForm/constants';
import AlertDanger from 'components/common/Alert/Alert';
import Dots from 'components/common/Dots/Dots';
import { FormControl } from 'components/common/Dropdown/Select.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  SubscribeButton,
  SubscribeInput,
  SubscribeWrapper,
} from 'components/common/SubscribeInput/SubscribeInput.styled';
import {
  WelcomeToPageContainer,
  WelcomeToPageParagraph,
  WelcomeToPageRedLine,
  WelcomeToPageTitle,
} from 'components/common/WelcomeToPage/WelcomeToPage.styled';
import { useAppContext } from 'contexts/AppContext';
import {
  BottomContentWrapper,
  StayInTouch,
} from 'pages/AboutPage/AboutSection/AboutSection.styled';
import {
  HomePageContainer,
  LandingSection,
  LandingSectionWrapper,
} from 'pages/HomePage/HomePage.styled';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resendVerificationToken, userVerify } from 'services/requests';

const VerificationPage = () => {
  const [alert, setAlert] = useState(INITIAL_ALERT_STATE);
  const navigate = useNavigate();
  const { setActiveForm } = useAppContext();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const verifyUser = async () => {
    try {
      const res = await userVerify({ token });
      const responseOK = res && res.status === 200 && res.statusText === 'OK';
      if (responseOK) {
        setActiveForm(SIGN_IN);
        navigate('/');
      }
    } catch (err) {
      if (err.response.status === 409) {
        setActiveForm(SIGN_IN);
        navigate('/');
      }
      setError(err.response.data.message);
    } finally {
      setEmailSent(false);
    }
  };
  useEffect(() => {
    verifyUser();
  }, []);
  const resendVerification = async (e) => {
    e.preventDefault();
    try {
      await resendVerificationToken({
        email,
      });
      setAlert(INITIAL_ALERT_STATE);
      setError('');
      setEmailSent(true);
    } catch (err) {
      setAlert({
        shouldLoadAlert: true,
        alertText: err.response.data.message,
      });
    }
  };
  return (
    <HomePageContainer>
      <LandingSection>
        <LandingSectionWrapper>
          <WelcomeToPageContainer>
            <Dots />
            <WelcomeToPageTitle>Welcome to WhartonBuilds!</WelcomeToPageTitle>
            <WelcomeToPageRedLine />
            <WelcomeToPageParagraph>{error}</WelcomeToPageParagraph>
            {emailSent && (
              <WelcomeToPageParagraph>
                Check your inbox for a new verification link
              </WelcomeToPageParagraph>
            )}
            <BottomContentWrapper>
              <StayInTouch />
              {alert.shouldLoadAlert ? (
                <AlertDanger alertText={alert.alertText} />
              ) : (
                <Spacer Height="40px" Margin="0px 0px 10px 0px" />
              )}
              {error && (
                <form onSubmit={resendVerification}>
                  <FormControl maxWidth={500}>
                    <SubscribeWrapper>
                      <SubscribeInput
                        type="email"
                        disabled={emailSent}
                        placeholder="Enter your email..."
                        value={email}
                        onChange={({ target: { value } }) => setEmail(value)}
                      />
                      <SubscribeButton
                        type="submit"
                        disabled={emailSent}
                        style={{
                          width: 'auto',
                          whiteSpace: 'nowrap',
                          padding: '0 20px',
                        }}
                      >
                        Resend verification email
                      </SubscribeButton>
                    </SubscribeWrapper>
                  </FormControl>
                </form>
              )}
            </BottomContentWrapper>
          </WelcomeToPageContainer>
        </LandingSectionWrapper>
      </LandingSection>
    </HomePageContainer>
  );
};

export default VerificationPage;
