import { CompaniesContext } from 'contexts/CompaniesContext';
import { useContext } from 'react';
import { authGetAllCompanies } from 'services/requests';
import copyDefinedProps from 'utils/copyDefinedProps';
import {
  dataError,
  dataReceived,
  loadingFinished,
  loadingStarted,
} from './companiesActions';

const useCompaniesData = () => {
  const { dispatch } = useContext(CompaniesContext);

  const setData = async (request, companyQueryParams) => {
    dispatch(loadingStarted());
    const filterParams = {
      ...copyDefinedProps(companyQueryParams, Object.keys(companyQueryParams)),
      limit: companyQueryParams.limit,
      offset: companyQueryParams.offset,
    };

    try {
      const response = await request(() => authGetAllCompanies(filterParams));

      if (response.status === 200) {
        dispatch(dataReceived(response.data));
      }
    } catch (err) {
      dispatch(dataError(err));
    }

    dispatch(loadingFinished());
  };

  return {
    setData,
  };
};
export default useCompaniesData;
