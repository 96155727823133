import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminPageWrapper from 'pages/AdminPage/AdminPage.styled';
import AdminPageLandingSection from 'pages/AdminPage/LandingSection/AdminPageLandingSection';
import TabControl from 'pages/AdminPage/TabControl/TabControl';
import useDarkMode from 'hooks/useDarkMode';

const AdminPage = () => {
  const [isDark, themeToggler] = useDarkMode();

  useEffect(() => {
    if (!isDark) themeToggler(true);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AdminPageWrapper>
        <Outlet />
        <AdminPageLandingSection />
        <TabControl />
      </AdminPageWrapper>
    </>
  );
};

export default AdminPage;
