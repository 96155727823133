export const resourcesTypeOptions = [
  {
    label: 'Funding Opportunities & Resource for Everyone',
    name: 'resourceTypes',
  },
  { label: 'Exclusive Deal for WhartonBuilds Members', name: 'resourceTypes' },
];

export const GENERAL_INFO_TEXT = {
  DESCRIPTION_LABEL: 'Which type of resource are you adding?* ',
  DESCRIPTION: 'Resources are available for all campus members.',
  GRATITUDE: 'Thanks for adding your resource!',
};
