import { useState } from 'react';
import Modal from 'components/common/Modal/Modal';
import { postExclusiveResource } from 'services/requests';
import { useAppContext } from 'contexts/AppContext';
import { ADD_RESOURCE_PROGRESS, ADD_RESOURCE_STEPS } from './constants';
import EntryModalResource from './EntryModal/EntryModalAdd';
import StepOne from './StepOne/StepOne';
import StepTwoExclusive from './StepTwo/ExclusiveResource/StepTwoExclusive';
import StepThree from './StepThree/StepThree';
import Response from './Response/Response';
import { defaultExclusiveFormStateProps } from './propTypes';
import prepareExclusiveResourceData from './utils';

const AddExclusiveDeal = () => {
  const { closeForm } = useAppContext();
  const [step, setStep] = useState(ADD_RESOURCE_STEPS.ENTRY_MODAL);

  const [formState, setFormState] = useState({
    ...defaultExclusiveFormStateProps(),
  });

  return (
    <Modal closeForm={closeForm} progress={ADD_RESOURCE_PROGRESS[step]}>
      {step === ADD_RESOURCE_STEPS.ENTRY_MODAL && (
        <EntryModalResource
          nextStep={() => setStep(ADD_RESOURCE_STEPS.STEP_ONE)}
          closeForm={closeForm}
        />
      )}
      {step === ADD_RESOURCE_STEPS.STEP_ONE && (
        <StepOne
          currentStep="1/3"
          nextStep={() => setStep(ADD_RESOURCE_STEPS.STEP_TWO)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {step === ADD_RESOURCE_STEPS.STEP_TWO && (
        <StepTwoExclusive
          currentStep="2/3"
          backStep={() => setStep(ADD_RESOURCE_STEPS.STEP_ONE)}
          nextStep={() => setStep(ADD_RESOURCE_STEPS.STEP_THREE)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {step === ADD_RESOURCE_STEPS.STEP_THREE && (
        <StepThree
          currentStep="3/3"
          backStep={() => setStep(ADD_RESOURCE_STEPS.STEP_TWO)}
          nextStep={() => setStep(ADD_RESOURCE_STEPS.RESPONSE)}
          closeForm={closeForm}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {step === ADD_RESOURCE_STEPS.RESPONSE && (
        <Response
          resourceData={prepareExclusiveResourceData(formState)}
          request={postExclusiveResource}
        />
      )}
    </Modal>
  );
};

export default AddExclusiveDeal;
