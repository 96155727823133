import { useState } from 'react';
import {
  UPDATE_A_COMPANY,
  STEPS,
  UPDATE_PROGRESS,
} from 'components/Forms/Company/constants';
import SelectCompany from 'components/Forms/Company/SelectCompany/SelectCompany';
import EntryModalUpdate from 'components/Forms/Company/EntryModal/EntryModalUpdate';
import Modal from 'components/common/Modal/Modal';
import StepOne from 'components/Forms/Company/StepOne/StepOne';
import StepTwo from 'components/Forms/Company/StepTwo/StepTwo';
import StepThree from 'components/Forms/Company/StepThree/StepThree';
import Response from 'components/Forms/Company/Response/Response';
import { updateCompanyById } from 'services/requests';
import { defaultFormStateProp } from 'components/Forms/Company/propTypes';
import { useAppContext } from 'contexts/AppContext';

const UpdateCompany = () => {
  const { closeForm } = useAppContext();
  const [step, setStep] = useState(STEPS.ENTRY_MODAL);
  const [formState, setFormState] = useState({ ...defaultFormStateProp() });

  return (
    <Modal closeForm={closeForm} progress={UPDATE_PROGRESS[step]}>
      {step === STEPS.ENTRY_MODAL && (
        <EntryModalUpdate
          activeForm={UPDATE_A_COMPANY}
          setStep={setStep}
          closeForm={closeForm}
        />
      )}
      {step === STEPS.SELECT_COMPANY && (
        <SelectCompany
          nextStep={() => setStep(STEPS.STEP_ONE)}
          closeForm={closeForm}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_ONE && (
        <StepOne
          nextStep={() => setStep(STEPS.STEP_TWO)}
          currentStep="2/4"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_TWO && (
        <StepTwo
          backStep={() => setStep(STEPS.STEP_ONE)}
          nextStep={() => setStep(STEPS.STEP_THREE)}
          currentStep="3/4"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_THREE && (
        <StepThree
          backStep={() => setStep(STEPS.STEP_TWO)}
          nextStep={() => setStep(STEPS.STEP_MESSAGE)}
          currentStep="4/4"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_MESSAGE && (
        <Response formState={formState} request={updateCompanyById} />
      )}
    </Modal>
  );
};

export default UpdateCompany;
