export const AQUAMARINE = '#77CED9';
export const AERO_BLUE = '#CCF3E9';

export const BLACK = '#000000';
export const BLACK_OLIVE = '#393936';
export const RAISIN_BLACK = '#212121';
export const EERIE_BLACK = '#181818';
export const RAISIN_BLACK_ALPHA_03 = 'rgba(33, 33, 33, 0.3)';

export const CRIMSON = '#011F5B';
export const CRIMSON_03 = 'rgba(165, 28, 48, 0.3)';
export const CRIMSON_06 = 'rgba(165, 28, 48, 0.6)';

export const DARK_CHARCOAL = '#333333';

export const GRAY = '#93A1AD';
export const GRAY_80 = '#CCCCCC';
export const DAVY_GRAY = '#545556';
export const LIGHT_GRAY = '#D5D5D4';
export const BRIGHT_GRAY = '#EAEAEA';
export const SILVER_SAND = '#C4C4C4';
export const DIM_GRAY = '#6D6C69';

export const GREEN = '#4DB848';
export const LIME_GREEN = '#CBDB2A';

export const LAVANDER = '#BB89CA';
export const PURPLE = '#946EB7';
export const RED = '#ED1B34';
export const SALMON = '#EC8F9C';
export const SKY_BLUE = '#95B5DF';
export const TURQUOISE = '#00AAAD';
export const WARM_YELLOW = '#FCB315';

export const WHITE = '#FFFFFF';
export const ANTI_FLASH_WHITE = '#F2F2F2';

export const YELLOW = '#FFDE2D';

export const MODAL_BACKGROUND = 'rgba(255, 255, 255, 0.5);';

export const FOOTER_LIGHT_BACKGROUND = '#f8f8f8';
export const FOOTER_DARK_BACKGROUND = '#1e1e1e';
