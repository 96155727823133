import PropTypes from 'prop-types';

const BackToTopArrow = ({ handleScroll }) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="var(--GRAY_80_OR_BRIGHT_GRAY)"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleScroll}
  >
    <rect
      x="0.5"
      y="0.5"
      width="55"
      height="55"
      fill="var(--RAISING_BLACK_OR_WHITE)"
      stroke="var(--DAVY_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M28 19.97C23.59 19.97 19.97 23.59 19.97 28C19.97 32.41 23.59 36.03 28 36.03C32.41 36.03 36.03 32.41 36.03 28C36.03 23.59 32.41 19.97 28 19.97ZM28 18C33.54 18 38 22.46 38 28C38 33.54 33.54 38 28 38C22.46 38 18 33.54 18 28C18 22.46 22.46 18 28 18ZM29 26.46H32L28 22.5L24 26.46H27V33.5H29"
      fill="#011F5B"
    />
  </svg>
);

BackToTopArrow.propTypes = {
  handleScroll: PropTypes.func.isRequired,
};

export default BackToTopArrow;
