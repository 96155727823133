import SymphonyLogo from 'assets/icons/SymphonyLogo';
import { Divider } from 'components/common/Divider/Divider.styled';
import Subscribe from 'components/common/SubscribeInput/SubscribeInput';
import { SYMPHONY_WEBSITE, CONTACT_EMAIL } from 'constants/other';
import gaSymphonyVisit from 'utils/gaSymphonyVisit';
import {
  NewsletterSection,
  NewsletterParagraphsSection,
  FooterContainer,
  FooterContent,
  FooterContentParagraph,
  NewsletterParagraph,
  NewsletterTitle,
  EmailLink,
  MiddleFooterContent,
  SymphonyFooterLink,
} from './Footer.styled';

const Footer = () => (
  <FooterContainer>
    <NewsletterSection>
      <NewsletterParagraphsSection>
        <NewsletterTitle>Stay in touch!</NewsletterTitle>
        <NewsletterParagraph>
          Receive a Quarterly Roundup of Wharton-related startup news{' '}
        </NewsletterParagraph>
      </NewsletterParagraphsSection>
      <Subscribe />
    </NewsletterSection>
    <Divider />
    <FooterContent>
      <FooterContentParagraph>
        2023 WhartonBuilds. All rights reserved. Data compiled, owned, and
        protected by WhartonBuilds.
      </FooterContentParagraph>
      <MiddleFooterContent>
        <FooterContentParagraph>
          Made with love by Dave and Doug for the{' '}
          <b>Wharton & UPenn community</b>
        </FooterContentParagraph>
        <FooterContentParagraph>
          WhartonBuilds / this site is neither affiliated with nor endorsed by
          the University of Pennsylvania or The Wharton School.
        </FooterContentParagraph>
        <SymphonyFooterLink
          onClick={(e) => {
            e.stopPropagation();
            gaSymphonyVisit('footer');
          }}
          href={SYMPHONY_WEBSITE}
        >
          Powered by
          <SymphonyLogo />
        </SymphonyFooterLink>
      </MiddleFooterContent>
      <FooterContentParagraph>
        For feedback or questions please{' '}
        <EmailLink href={`mailto:${CONTACT_EMAIL}`}>
          <strong>email us</strong>
        </EmailLink>
      </FooterContentParagraph>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
