import { isEmptyFieldValidator } from 'utils/inputValidators';
import { PUBLIC_KEYS } from '../constants';

const generalInfoInputSchema = {
  [PUBLIC_KEYS.DEAL_NAME]: {
    label: 'Resource name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your resource name',
    validator: isEmptyFieldValidator,
  },
};

export default generalInfoInputSchema;
