import { DARK_CHARCOAL, DAVY_GRAY, WHITE } from 'constants/colors';
import styled from 'styled-components';

export const DragNDropWrapper = styled.div`
  background-color: ${WHITE};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 10;
  top: 35px;
  right: 10px;

  cursor: pointer;
`;

export const Label = styled.label`
  color: ${DARK_CHARCOAL};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
`;

export const DragNDropContainer = styled.div`
  align-items: center;
  border: 1px dashed ${DAVY_GRAY};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 120px;
  gap: 0 10px;
`;

export const DragNDropField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DragNDropParagraph = styled.p`
  color: ${DAVY_GRAY};
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

export const ImageError = styled.span`
  top: 0;
  color: var(--WHITE_OR_CRIMSON);
  font-size: 10px;
  line-height: 24px;
  margin: 0;
`;
