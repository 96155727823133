import CATEGORY_COLORS from 'constants/category_colors';

export const EVENT_ORGANIZERS = {
  [CATEGORY_COLORS.BLUE]: ['DRF', 'NFX'],
  [CATEGORY_COLORS.SKY_BLUE]: ['Rock Center for Entrepreneurship', 'MBA Fund'],
  [CATEGORY_COLORS.TURQUOISE]: ['CPD', 'Crypto Club'],
  [CATEGORY_COLORS.GREEN]: ['Women in Investing (WII)', 'Pheonix Fund'],
  [CATEGORY_COLORS.YELLOW]: ['EtA Club', 'Tech Club'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Harvard i-lab'],
  [CATEGORY_COLORS.SALMON]: ['Fintech Club'],
  [CATEGORY_COLORS.LAVANDER]: ['Wharton'],
  [CATEGORY_COLORS.PURPLE]: ['VCPE Club'],
  [CATEGORY_COLORS.GRAY]: ['E-Club'],
  [CATEGORY_COLORS.ORANGE]: ['Other'],
};

export const EVENT_AUDIENCES = {
  [CATEGORY_COLORS.BLUE]: ['Venture Capital (VC)'],
  [CATEGORY_COLORS.SKY_BLUE]: ['RCs'],
  [CATEGORY_COLORS.TURQUOISE]: ['Social Impact'],
  [CATEGORY_COLORS.GREEN]: ['Joiners'],
  [CATEGORY_COLORS.YELLOW]: ['EtA / Searchers'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Founders'],
  [CATEGORY_COLORS.SALMON]: ['i-lab Members'],
  [CATEGORY_COLORS.LAVANDER]: ['Growth / Private Equity (PE)'],
  [CATEGORY_COLORS.PURPLE]: ['Alumni'],
  [CATEGORY_COLORS.GRAY]: ['ECs'],
  [CATEGORY_COLORS.ORANGE]: ['Other'],
};
