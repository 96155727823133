import styled from 'styled-components';
import { CRIMSON_06, CRIMSON_03 } from 'constants/colors';

export const TableWrapper = styled.div`
  background: var(--BLACK_OLIVE_OR_LIGHT_GRAY);
  border-radius: 8px;
  min-height: 740px;
  overflow: scroll;
`;

export const Table = styled.table`
  background: var(--BLACK_OLIVE_OR_LIGHT_GRAY);
  border-collapse: separate;
  border-spacing: 0 5px;
  padding: 0 10px 10px 10px;
  width: 100%;
`;

export const THead = styled.thead`
  tr {
    background-color: transparent;
    height: 48px;

    th {
      border-bottom: 1px solid var(--DIM_GRAY_OR_BRIGHT_GRAY);
      font-weight: 400;
      font-size: 12px;
      position: relative;
      top: -5px;
    }
  }
`;

export const TBody = styled.tbody`
  tr {
    display: table-row;
    height: 70px;

    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const TH = styled.th`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-size: 12px;
  line-height: 18px;
`;

export const TR = styled.tr`
  background-color: ${(props) =>
    props.isSelected ? CRIMSON_03 : 'var(--DAVY_GRAY_OR_BRIGHT_GRAY)'};
  border-radius: 4px;

  &:hover {
    background-color: ${(props) =>
      props.isSelected ? CRIMSON_06 : 'var(--DARK_CHARCOAL_OR_WHITE)'};
    cursor: pointer;
  }
`;

export const TD = styled.td`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 2px;
`;

export const TDdescription = styled.div`
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
