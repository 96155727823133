import axios from 'axios';
import {
  readLocalStorage,
  removeLocalStorageItem,
} from 'utils/localStorageUtils';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const authFetch = axios.create();

authFetch.interceptors.request.use(
  (config) => {
    const user = readLocalStorage('user');

    if (user) {
      // TODO: Try to find solution without disabling ESLint
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${user.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

authFetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      removeLocalStorageItem('user');
    }

    return Promise.reject(error);
  },
);

export const loginUser = async (data) => axios.post(`/login`, data);

export const registerUser = async (data) => axios.post(`/register`, data);

export const getAllCompanies = async (params) =>
  authFetch.get(`/companies`, { params });

export const getAllCompaniesByUserId = async () =>
  authFetch.get(`/user/companies`);

export const getCompanyById = async (companyId) =>
  axios.get(`/companies/${companyId}`);

export const getAllCardColors = async () => axios.get('/card-colors');

export const getAllCategories = async () => axios.get('/categories');

export const getAllGeologicalLocations = async () =>
  axios.get('/company-geographic-focus');

export const getAllFounderAttributes = async () =>
  axios.get('/company-founder-attributes');

export const getAllCompanyStages = async () => axios.get('/company-stages');

export const getAllFundingStages = async () => axios.get('/funding-stages');

export const getPublicResources = async (params) =>
  axios.get('/public-deals', { params });

export const postPublicResource = async (data) =>
  axios.post('/public-deals', data);

export const postExclusiveResource = async (data) =>
  axios.post('/exclusive-deals', data);

export const subscribeToNewsletter = async (subscriberEmail) =>
  axios.post('/subscribers', { subscriberEmail });

export const getAllOptions = async () =>
  axios.all([
    getAllCardColors(),
    getAllCategories(),
    getAllCompanyStages(),
    getAllFundingStages(),
    getAllGeologicalLocations(),
  ]);

export const getAdminCompanyModalOptions = async () =>
  axios.all([
    getAllCardColors(),
    getAllCategories(),
    getAllCompanyStages(),
    getAllFundingStages(),
    getAllGeologicalLocations(),
    getAllFounderAttributes(),
  ]);

export const getTargetAudience = async () => axios.get('/target-audiences');

export const getPublicDealCategories = async () =>
  axios.get('/public-deal-categories');

export const getExclusiveDealCategories = async () =>
  axios.get('/exclusive-deal-categories');

export const getPublicResourceOptions = async () =>
  axios.all([getTargetAudience(), getPublicDealCategories()]);

export const createCompany = async (data) => axios.post('/companies', data);

export const updateCompanyById = async (data, companyId) =>
  authFetch.put(`/companies/${companyId}`, data);

export const getExclusiveDeals = async (params) =>
  authFetch.get('/exclusive-deals', { params });

export const submitCaptcha = async (captchaToken) =>
  axios.post('/captcha-verify', { captchaToken });

export const forgotPassword = async (data) =>
  axios.post(`/user/forgot-password`, data);

export const resetPassword = async (data) =>
  axios.post(`/user/reset-password`, data);

export const getEventAudiences = async () => axios.get('/event-audience');

export const getEventOrganizers = async () => axios.get('/event-organizers');

export const getEventCategories = async () =>
  axios.all([getEventAudiences(), getEventOrganizers()]);

export const getAllEvents = async () => axios.get('/events');

export const getEventById = async (id) => axios.get(`/events/${id}`);

export const createEvent = async (data) => axios.post('/events', data);

export const updateEvent = async (data, id) => axios.put(`/events/${id}`, data);

export const getStatisticsByPeriod = async (params) =>
  authFetch.get('/admin/companies/statistics', { params });

export const authGetAllCompanies = async (params) =>
  authFetch.get(`/companies`, { params });

export const deleteCompanies = async (ids) =>
  authFetch.delete(`/admin/companies`, { data: { ids } });

export const restoreCompanies = async (ids) =>
  authFetch.put('/admin/companies', { ids });

export const userVerify = async (data) => axios.patch(`/user/verify`, data);

export const resendVerificationToken = async (data) =>
  axios.patch('user/resend-verification', data);
