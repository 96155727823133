import {
  Container,
  RedLine,
  StayInTouch,
  WelcomeToHbsTitle,
  StayInTouchParagraph,
  BottomParagraph,
  BottomContentWrapper,
  DescriptionParagraph,
} from 'pages/AboutPage/AboutSection/AboutSection.styled';
import Subscribe from 'components/common/SubscribeInput/SubscribeInput';
import PARAGRAPHS, {
  LINKS,
  LINK_KEYS,
} from 'pages/AboutPage/AboutSection/constants';

const WelcomeToHbs = () => (
  <Container>
    <WelcomeToHbsTitle>About us</WelcomeToHbsTitle>
    <RedLine />
    <div>
      <DescriptionParagraph>
        {PARAGRAPHS.ABOUT[0][0]}
        <a href={LINKS[LINK_KEYS.GSBUILDS]}>{LINK_KEYS.GSBUILDS}</a>
        {PARAGRAPHS.ABOUT[0][1]}
      </DescriptionParagraph>
      <DescriptionParagraph>{PARAGRAPHS.ABOUT[1]}</DescriptionParagraph>
      <DescriptionParagraph>
        {PARAGRAPHS.ABOUT[2]}
        <a href={LINKS[LINK_KEYS.HERE]}>{LINK_KEYS.HERE}</a>.
      </DescriptionParagraph>
      <DescriptionParagraph>{PARAGRAPHS.ABOUT[3]} </DescriptionParagraph>
      <DescriptionParagraph>
        {PARAGRAPHS.ABOUT[4]}
        <a href={`mailto:${LINKS[LINK_KEYS.REACH_OUT]}`}>
          {PARAGRAPHS.ABOUT[5]}
        </a>
        .
      </DescriptionParagraph>
      <DescriptionParagraph>
        {PARAGRAPHS.ABOUT[6]}
        <a href={LINKS[LINK_KEYS.HERE]}>{LINK_KEYS.REACH_OUT}</a>.
      </DescriptionParagraph>
    </div>
    <BottomContentWrapper>
      <StayInTouch>
        <StayInTouchParagraph>Stay in touch!</StayInTouchParagraph>
        <BottomParagraph>
          Receive a Quarterly Roundup of Wharton-related startup news
        </BottomParagraph>
      </StayInTouch>
      <Subscribe />
    </BottomContentWrapper>
  </Container>
);

export default WelcomeToHbs;
