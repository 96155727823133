import { ADD_NEW_EVENT, UPDATE_EVENT } from 'components/Forms/Events/constants';

const EVENTS_STATIC_TEXT = {
  [ADD_NEW_EVENT]: {
    START_TITLE: 'Add an event',
    START_DESCRIPTION:
      'WhartonBuilds is the definitive platform to find events for your liking to attend.',
    CONTACT_INFO: 'For questions or assistance, please reach out to ',
    CONTACT_EMAIL: 'hello@whartonbuilds.com',
    END_TITLE: 'Thank you!',
    END_DESCRIPTION: 'Thank you for submitting your event to our platform.',
  },
  [UPDATE_EVENT]: {
    START_TITLE: 'Update an event',
    START_DESCRIPTION:
      'If the information for your event is incorrect or needs updating, please first input the event name as it currently appears on WhartonBuilds, and then enter the most updated information below as you wish for it to appear',
    CONTACT_INFO: 'For questions or assistance, please reach out to ',
    CONTACT_EMAIL: 'hello@whartonbuilds.com',
    END_TITLE: 'Thank you!',
    END_DESCRIPTION: 'Thank you for updating the event.',
  },
};

export default EVENTS_STATIC_TEXT;
