import PropTypes from 'prop-types';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import useRequest from 'hooks/useRequest';
import {
  generateYearOptions,
  mapCategories,
  mapFounderAttributes,
  mapFundingStages,
} from 'components/Forms/Company/StepTwo/utils';
import { useEffect, useState } from 'react';
import {
  getAllCategories,
  getAllFounderAttributes,
  getAllFundingStages,
} from 'services/requests';
import {
  FiltersContainer,
  FilterWrapper,
  FilterInputField,
  FilterInputFieldWrapper,
  FilterLabel,
  FilterGroupWrapper,
} from './Filters.styled';
import {
  selectFiltersSchema,
  FILTERS_KEYS,
  filterParamsSchema,
} from './schema';

const Filters = ({ filters, handleFiltersChange, isFiltersApplied }) => {
  const [categories, setCategories] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const [founderAttributes, setFounderAttributes] = useState([]);
  const yearOptions = generateYearOptions();
  const hiringOptions = [
    { label: 'Yes', id: 'true' },
    { label: 'No', id: 'false' },
  ];
  const fundraisingOptions = [
    { label: 'Yes', id: 'true' },
    { label: 'No', id: 'false' },
  ];
  const { request } = useRequest();

  const selectionHandler = (field, collection) => (option) =>
    handleFiltersChange(
      field,
      option ? collection.find((object) => object.id === option.id).id : null,
    );

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const response = await request(() => getAllCategories());

      if (!ignore && response) setCategories(mapCategories(response.data));
    };

    const fetchFundingStages = async () => {
      const response = await request(() => getAllFundingStages());

      if (!ignore && response)
        setFundingStages(mapFundingStages(response.data));
    };

    const fetchFoundersAttributes = async () => {
      const response = await request(() => getAllFounderAttributes());
      if (!ignore && response) {
        setFounderAttributes(mapFounderAttributes(response.data));
      }
    };

    fetchFoundersAttributes();

    fetchFundingStages();

    fetchCategories();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <FiltersContainer>
      <FilterGroupWrapper>
        <FilterWrapper>
          <FilterLabel>{FILTERS_KEYS.COMPANY_NAME}</FilterLabel>
          <FilterInputFieldWrapper>
            <FilterInputField
              // value={filters[filterParamsSchema[FILTERS_KEYS.COMPANY_NAME]]}
              onChange={({ target: { value } }) =>
                handleFiltersChange(
                  filterParamsSchema[FILTERS_KEYS.COMPANY_NAME],
                  value,
                )
              }
            />
          </FilterInputFieldWrapper>
        </FilterWrapper>

        <FilterWrapper>
          <FilterLabel>{FILTERS_KEYS.FOUNDER}</FilterLabel>
          <FilterInputFieldWrapper>
            <FilterInputField
              // value={filters[filterParamsSchema[FILTERS_KEYS.FOUNDER]]}
              onChange={({ target: { value } }) =>
                handleFiltersChange(
                  filterParamsSchema[FILTERS_KEYS.FOUNDER],
                  value,
                )
              }
            />
          </FilterInputFieldWrapper>
        </FilterWrapper>
        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.FOUNDING_YEAR}`}
            {...selectFiltersSchema[FILTERS_KEYS.FOUNDING_YEAR]}
            options={yearOptions}
            onChangeHandler={(option) =>
              handleFiltersChange(
                filterParamsSchema[FILTERS_KEYS.FOUNDING_YEAR],
                option && option.label,
              )
            }
            selectedValue={yearOptions.find(
              (object) =>
                object.label ===
                filters[filterParamsSchema[FILTERS_KEYS.FOUNDING_YEAR]],
            )}
          />
        </FilterWrapper>

        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.CATEGORY}`}
            {...selectFiltersSchema[FILTERS_KEYS.CATEGORY]}
            options={categories}
            onChangeHandler={selectionHandler(
              filterParamsSchema[FILTERS_KEYS.CATEGORY],
              categories,
            )}
            selectedValue={categories.find(
              (object) =>
                object.id ===
                filters[filterParamsSchema[FILTERS_KEYS.CATEGORY]],
            )}
          />
        </FilterWrapper>
      </FilterGroupWrapper>
      <FilterGroupWrapper>
        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.FUNDING_STAGE}`}
            {...selectFiltersSchema[FILTERS_KEYS.FUNDING_STAGE]}
            options={fundingStages}
            onChangeHandler={selectionHandler(
              filterParamsSchema[FILTERS_KEYS.FUNDING_STAGE],
              fundingStages,
            )}
            selectedValue={fundingStages.find(
              (object) =>
                object.id ===
                filters[filterParamsSchema[FILTERS_KEYS.FUNDING_STAGE]],
            )}
          />
        </FilterWrapper>

        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.IS_HIRING}`}
            {...selectFiltersSchema[FILTERS_KEYS.IS_HIRING]}
            options={hiringOptions}
            onChangeHandler={selectionHandler(
              filterParamsSchema[FILTERS_KEYS.IS_HIRING],
              hiringOptions,
            )}
            selectedValue={hiringOptions.find(
              (object) =>
                object.id ===
                filters[filterParamsSchema[FILTERS_KEYS.IS_HIRING]],
            )}
            isSearchable={false}
          />
        </FilterWrapper>
        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.IS_FUNDRAISING}`}
            {...selectFiltersSchema[FILTERS_KEYS.IS_FUNDRAISING]}
            options={fundraisingOptions}
            onChangeHandler={selectionHandler(
              filterParamsSchema[FILTERS_KEYS.IS_FUNDRAISING],
              fundraisingOptions,
            )}
            selectedValue={fundraisingOptions.find(
              (object) =>
                object.id ===
                filters[filterParamsSchema[FILTERS_KEYS.IS_FUNDRAISING]],
            )}
            isSearchable={false}
          />
        </FilterWrapper>

        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.FOUNDERS_ATTRIBUTES}`}
            {...selectFiltersSchema[FILTERS_KEYS.FOUNDERS_ATTRIBUTES]}
            options={founderAttributes}
            onChangeHandler={selectionHandler(
              filterParamsSchema[FILTERS_KEYS.FOUNDERS_ATTRIBUTES],
              founderAttributes,
            )}
            selectedValue={founderAttributes.find(
              (object) =>
                object.id ===
                filters[filterParamsSchema[FILTERS_KEYS.FOUNDERS_ATTRIBUTES]],
            )}
          />
        </FilterWrapper>
      </FilterGroupWrapper>
    </FiltersContainer>
  );
};

Filters.defaultProps = {
  filters: {
    companyName: '',
    founderName: '',
    foundingYear: null,
    stage: null,
    category: null,
    isHiring: null,
    fundraising: null,
    founderAttributes: null,
  },
};

Filters.propTypes = {
  isFiltersApplied: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    companyName: PropTypes.string,
    founderName: PropTypes.string,
    foundingYear: PropTypes.string,
    stage: PropTypes.number,
    category: PropTypes.number,
    isHiring: PropTypes.string,
    fundraising: PropTypes.string,
    founderAttributes: PropTypes.string,
  }),
  handleFiltersChange: PropTypes.func.isRequired,
};

export default Filters;
