import PropTypes from 'prop-types';

import { LinkItUrl } from 'react-linkify-it';
import {
  ButtonsContainer,
  DescriptionContainer,
  DescriptionParagraph,
  Title,
  Wrapper,
} from 'components/Forms/Company/EntryModal/EntryModal.styled';
import {
  UPDATE_A_COMPANY,
  STATIC_TEXT,
  STEPS,
} from 'components/Forms/Company/constants';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { EmailLink } from 'components/Footer/Footer.styled';
import { useEffect } from 'react';
import { useAppContext } from 'contexts/AppContext';
import { SIGN_IN } from 'components/Forms/SignForm/constants';
import isAuth from 'utils/isAuth';

const EntryModalUpdate = ({ setStep, closeForm }) => {
  const { setActiveForm, removeUser } = useAppContext();
  const nextStep = () => setStep(STEPS.SELECT_COMPANY);

  useEffect(() => {
    if (!isAuth()) {
      removeUser();
      setActiveForm(SIGN_IN, () => setActiveForm(UPDATE_A_COMPANY));
    }
  }, []);

  return (
    <Wrapper>
      <DescriptionContainer>
        <Title>{STATIC_TEXT[UPDATE_A_COMPANY].START_TITLE}</Title>
        <LinkItUrl>
          <DescriptionParagraph>
            {STATIC_TEXT[UPDATE_A_COMPANY].START_DESCRIPTION}
          </DescriptionParagraph>
        </LinkItUrl>
        <DescriptionParagraph>
          {STATIC_TEXT[UPDATE_A_COMPANY].CONTACT_INFO}{' '}
          <EmailLink
            href={`mailto:${STATIC_TEXT[UPDATE_A_COMPANY].CONTACT_EMAIL}`}
          >
            {STATIC_TEXT[UPDATE_A_COMPANY].CONTACT_EMAIL}
          </EmailLink>
        </DescriptionParagraph>
      </DescriptionContainer>
      <ButtonsContainer>
        <DarkButton
          Border="none"
          Height="40px"
          Width="220px"
          type="button"
          onClick={nextStep}
        >
          Start
        </DarkButton>
        <SecondaryButton
          Border="none"
          Height="40px"
          Width="220px"
          type="button"
          noDark
          onClick={closeForm}
        >
          Cancel
        </SecondaryButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

EntryModalUpdate.propTypes = {
  setStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default EntryModalUpdate;
