import PropTypes from 'prop-types';

import { LinkItUrl } from 'react-linkify-it';
import {
  ButtonsContainer,
  DescriptionContainer,
  DescriptionParagraph,
  Title,
  Wrapper,
} from 'components/Forms/Company/EntryModal/EntryModal.styled';
import {
  ADD_A_COMPANY,
  STATIC_TEXT,
  STEPS,
} from 'components/Forms/Company/constants';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { EmailLink } from 'components/Footer/Footer.styled';
import BUTTONS_CSS from 'components/Forms/Company/EntryModal/constants';

const EntryModalAdd = ({ setStep, closeForm }) => {
  const nextStep = () => setStep(STEPS.STEP_ONE);

  return (
    <Wrapper>
      <DescriptionContainer>
        <Title>{STATIC_TEXT[ADD_A_COMPANY].START_TITLE}</Title>
        <LinkItUrl>
          <DescriptionParagraph>
            {STATIC_TEXT[ADD_A_COMPANY].START_DESCRIPTION}
          </DescriptionParagraph>
        </LinkItUrl>
        <DescriptionParagraph>
          {STATIC_TEXT[ADD_A_COMPANY].CONTACT_INFO}{' '}
          <EmailLink
            href={`mailto:${STATIC_TEXT[ADD_A_COMPANY].CONTACT_EMAIL}`}
          >
            {STATIC_TEXT[ADD_A_COMPANY].CONTACT_EMAIL}
          </EmailLink>
        </DescriptionParagraph>
      </DescriptionContainer>
      <ButtonsContainer>
        <DarkButton {...BUTTONS_CSS} onClick={nextStep}>
          Start
        </DarkButton>
        <SecondaryButton {...BUTTONS_CSS} noDark onClick={closeForm}>
          Cancel
        </SecondaryButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

EntryModalAdd.propTypes = {
  setStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default EntryModalAdd;
