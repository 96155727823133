import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import FormDescription from 'components/common/FormComponents/Input/FormDescription/FormDescription';
import RadioButtonGroup from 'components/common/RadioButtonGroup/RadioButtonGroup';
import Spacer from 'components/common/Spacer/Spacer.styled';
import useCompanyState from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/hooks/useCompanyState';
import { formStateProp } from 'components/Forms/Company/propTypes';
import Founders from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/Founders/Founders';
import FOUNDER_INITIAL_STATE from 'components/Forms/Company/StepThree/schema';
import repackFormState from 'components/Forms/Company/Response/utils';
import { updateCompanyById } from 'services/requests';
import { INITIAL_ALERT_STATE } from 'components/Forms/SignForm/constants';
import AlertDanger from 'components/common/Alert/Alert';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import {
  triggerFieldErrors,
  triggerFounderErrors,
} from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/utils';
import useRequest from 'hooks/useRequest';
import useCompanyParams from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/useCompanyParams/useCompanyParams';
import useCompaniesData from 'pages/AdminPage/TabControl/CompanyTab/useCompaniesData';
import { generateFundraisingOptions } from 'components/Forms/Company/StepTwo/utils';
import { ForgotPasswordText } from 'components/Forms/SignForm/SignForm.styled';
import {
  ButtonsContainer,
  DescriptionContainer,
  FieldContainer,
  FormContainer,
  RowContainer,
  SubTitle,
  Wrapper,
  Title,
} from './EditCombinedSteps.styled';
import {
  inputSchema,
  singleSelectSchema,
  multiSelectSchema,
  isHiringOptions,
} from '../schema';
import {
  INPUT_KEYS,
  MULTI_SELECT_KEYS,
  RADIO_KEYS,
  SINGLE_SELECT_KEYS,
} from '../constants';

const EditCombinedSteps = ({
  closeForm,
  initialCompanyData,
  yearOptions,
  cardColors,
  categories,
  companyStages,
  fundingStages,
  geographicFocus,
  founderAttributes,
}) => {
  const {
    companyData,
    validationErrors,
    setValidationError,
    inputOnChangeHandler,
    inputOnBlurHandler,
    singleSelectionHandler,
    multiSelectionHandler,
    radioButtonGroupHandler,
    foundersHandler,
  } = useCompanyState(initialCompanyData);
  const fundraising = generateFundraisingOptions();

  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState(INITIAL_ALERT_STATE);
  const { request } = useRequest();
  const { companyQueryParams } = useCompanyParams();
  const { setData } = useCompaniesData();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (alert.alertText === 'Success') {
        setData(request, companyQueryParams);
        closeForm();
      }

      setAlert(INITIAL_ALERT_STATE);
    }, 1500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [alert]);

  const alertRef = useRef(null);

  const scrollIntoAlert = () =>
    alertRef.current.scrollIntoView({ behavior: 'smooth' });

  const submitCompany = async () => {
    scrollIntoAlert();

    try {
      const responseData = await updateCompanyById(
        {
          ...repackFormState(companyData),
        },
        companyData.id,
      );

      if (responseData.status !== StatusCodes.OK)
        throw new Error(`Failed: ${responseData.response}`);

      setAlert({
        shouldLoadAlert: true,
        alertText: 'Success',
      });
      setSuccess(true);
    } catch (error) {
      setAlert({
        shouldLoadAlert: true,
        alertText: error.response.data.message,
      });
      setSuccess(false);
    }
  };

  const setFounder = (position) => (founderState) => {
    const index = companyData.founders.findIndex(
      (founder) => founder.position === position,
    );
    const foundersCopy = companyData.founders;

    foundersCopy[index] = founderState;

    foundersHandler(foundersCopy);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const keys = Object.keys(FOUNDER_INITIAL_STATE);

    const hasError =
      triggerFieldErrors(companyData, setValidationError) ||
      triggerFounderErrors(companyData.founders, setFounder, keys);

    if (hasError) {
      setAlert({
        shouldLoadAlert: true,
        alertText: 'Check for warnings first',
      });

      return;
    }

    submitCompany();
  };

  return (
    <Wrapper ref={alertRef}>
      <Title>Edit company info</Title>
      <SubTitle>Please fill in the data below</SubTitle>
      {alert.shouldLoadAlert ? (
        <AlertDanger
          alertText={alert.alertText}
          {...(success && { bgColor: '#dcedc8', textColor: '#2e7d32' })}
          showIcon={false}
        />
      ) : (
        <Spacer Height="40px" Margin="0px 0px 10px 0px" />
      )}
      <FormContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputSchema[INPUT_KEYS.CREATOR_FULL_NAME]}
              value={companyData[INPUT_KEYS.CREATOR_FULL_NAME]}
              name={INPUT_KEYS.CREATOR_FULL_NAME}
              isError={validationErrors[INPUT_KEYS.CREATOR_FULL_NAME]}
              onChangeHandler={inputOnChangeHandler(
                INPUT_KEYS.CREATOR_FULL_NAME,
              )}
              onBlurHandler={inputOnBlurHandler(INPUT_KEYS.CREATOR_FULL_NAME)}
            />
          </FieldContainer>
          <FieldContainer>
            <FormInput
              {...inputSchema[INPUT_KEYS.COMPANY_NAME]}
              value={companyData[INPUT_KEYS.COMPANY_NAME]}
              name={INPUT_KEYS.COMPANY_NAME}
              isError={validationErrors[INPUT_KEYS.COMPANY_NAME]}
              onChangeHandler={inputOnChangeHandler(INPUT_KEYS.COMPANY_NAME)}
              onBlurHandler={inputOnBlurHandler(INPUT_KEYS.COMPANY_NAME)}
            />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputSchema[INPUT_KEYS.CREATOR_HBS_EMAIL]}
              value={companyData[INPUT_KEYS.CREATOR_HBS_EMAIL]}
              name={INPUT_KEYS.CREATOR_HBS_EMAIL}
              isError={validationErrors[INPUT_KEYS.CREATOR_HBS_EMAIL]}
              onChangeHandler={inputOnChangeHandler(
                INPUT_KEYS.CREATOR_HBS_EMAIL,
              )}
              onBlurHandler={inputOnBlurHandler(INPUT_KEYS.CREATOR_HBS_EMAIL)}
            />
          </FieldContainer>
          <FieldContainer>
            <SingleSelect
              {...singleSelectSchema[SINGLE_SELECT_KEYS.FOUNDING_YEAR]}
              selectedValue={companyData[SINGLE_SELECT_KEYS.FOUNDING_YEAR]}
              isError={validationErrors[SINGLE_SELECT_KEYS.FOUNDING_YEAR]}
              options={yearOptions}
              onChangeHandler={singleSelectionHandler(
                SINGLE_SELECT_KEYS.FOUNDING_YEAR,
                yearOptions,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputSchema[INPUT_KEYS.COMPANY_WEBSITE]}
              value={companyData[INPUT_KEYS.COMPANY_WEBSITE]}
              name={INPUT_KEYS.COMPANY_WEBSITE}
              isError={validationErrors[INPUT_KEYS.COMPANY_WEBSITE]}
              onChangeHandler={inputOnChangeHandler(INPUT_KEYS.COMPANY_WEBSITE)}
              onBlurHandler={inputOnBlurHandler(INPUT_KEYS.COMPANY_WEBSITE)}
            />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              {...multiSelectSchema[MULTI_SELECT_KEYS.CATEGORIES]}
              selectedValue={companyData[MULTI_SELECT_KEYS.CATEGORIES]}
              options={categories}
              onChangeHandler={multiSelectionHandler(
                MULTI_SELECT_KEYS.CATEGORIES,
                categories,
              )}
              isError={validationErrors[MULTI_SELECT_KEYS.CATEGORIES]}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <SingleSelect
              {...singleSelectSchema[SINGLE_SELECT_KEYS.COMPANY_STAGE]}
              selectedValue={companyData[SINGLE_SELECT_KEYS.COMPANY_STAGE]}
              isError={validationErrors[SINGLE_SELECT_KEYS.COMPANY_STAGE]}
              options={companyStages}
              onChangeHandler={singleSelectionHandler(
                SINGLE_SELECT_KEYS.COMPANY_STAGE,
                companyStages,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <SingleSelect
              {...singleSelectSchema[SINGLE_SELECT_KEYS.FUNDING_STAGE]}
              selectedValue={companyData[SINGLE_SELECT_KEYS.FUNDING_STAGE]}
              isError={validationErrors[SINGLE_SELECT_KEYS.FUNDING_STAGE]}
              options={fundingStages}
              onChangeHandler={singleSelectionHandler(
                SINGLE_SELECT_KEYS.FUNDING_STAGE,
                fundingStages,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              {...multiSelectSchema[MULTI_SELECT_KEYS.GEOGRAPHIC_FOCUS]}
              selectedValue={companyData[MULTI_SELECT_KEYS.GEOGRAPHIC_FOCUS]}
              options={geographicFocus}
              onChangeHandler={multiSelectionHandler(
                MULTI_SELECT_KEYS.GEOGRAPHIC_FOCUS,
                geographicFocus,
              )}
              isError={validationErrors[MULTI_SELECT_KEYS.GEOGRAPHIC_FOCUS]}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              {...multiSelectSchema[MULTI_SELECT_KEYS.FOUNDER_ATTRIBUTES]}
              selectedValue={companyData[MULTI_SELECT_KEYS.FOUNDER_ATTRIBUTES]}
              options={founderAttributes}
              onChangeHandler={multiSelectionHandler(
                MULTI_SELECT_KEYS.FOUNDER_ATTRIBUTES,
                founderAttributes,
              )}
              isError={validationErrors[MULTI_SELECT_KEYS.FOUNDER_ATTRIBUTES]}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputSchema[INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK]}
              value={companyData[INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK]}
              name={INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK}
              onChangeHandler={inputOnChangeHandler(
                INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK,
              )}
              onBlurHandler={inputOnBlurHandler(
                INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK,
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <SingleSelect
              {...singleSelectSchema[SINGLE_SELECT_KEYS.COMPANY_CARD_COLOR]}
              selectedValue={companyData[SINGLE_SELECT_KEYS.COMPANY_CARD_COLOR]}
              isError={validationErrors[SINGLE_SELECT_KEYS.COMPANY_CARD_COLOR]}
              options={cardColors}
              onChangeHandler={singleSelectionHandler(
                SINGLE_SELECT_KEYS.COMPANY_CARD_COLOR,
                cardColors,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <SingleSelect
              {...singleSelectSchema[SINGLE_SELECT_KEYS.FUNDRAISING]}
              selectedValue={companyData[SINGLE_SELECT_KEYS.FUNDRAISING]}
              options={fundraising}
              onChangeHandler={singleSelectionHandler(
                SINGLE_SELECT_KEYS.FUNDRAISING,
                fundraising,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FormDescription
            {...inputSchema[INPUT_KEYS.COMPANY_DESCRIPTION]}
            value={companyData[INPUT_KEYS.COMPANY_DESCRIPTION]}
            name={INPUT_KEYS.COMPANY_DESCRIPTION}
            onChangeHandler={inputOnChangeHandler(
              INPUT_KEYS.COMPANY_DESCRIPTION,
            )}
            onBlurHandler={inputOnBlurHandler(INPUT_KEYS.COMPANY_DESCRIPTION)}
          />
        </RowContainer>
        <DescriptionContainer>
          <a href="https://mypenn.upenn.edu/" target="_blank" rel="noreferrer">
            <ForgotPasswordText>
              If you have forgotten your alumni email, you may access it at
              PennKey Support
            </ForgotPasswordText>
          </a>
        </DescriptionContainer>
        <Spacer Height="32px" />
        <RadioButtonGroup
          groupLabel="Is this company currently hiring?*"
          options={isHiringOptions}
          selectedLabel={companyData.isHiring}
          onChange={radioButtonGroupHandler(RADIO_KEYS.IS_HIRING)}
        />
        <Spacer Height="32px" />
        <Founders
          founders={companyData.founders}
          setFounders={foundersHandler}
        />
      </FormContainer>
      <Spacer Height="40px" />
      <ButtonsContainer>
        <SecondaryButton
          Border="1px solid #545556"
          Width="83px"
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          type="button"
          noDark
          onClick={closeForm}
        >
          Cancel
        </SecondaryButton>
        <Spacer Width="16px" />
        <DarkButton
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          Width="173px"
          Border="none"
          type="button"
          onClick={submitForm}
        >
          Submit
        </DarkButton>
      </ButtonsContainer>
      <Spacer Heigth="10px" />
    </Wrapper>
  );
};

EditCombinedSteps.propTypes = {
  closeForm: PropTypes.func.isRequired,
  ...formStateProp().isRequired,
};

export default EditCombinedSteps;
