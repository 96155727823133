import { useNavigate } from 'react-router-dom';
import {
  PopupCategory,
  PopupColumn,
  PopupContainer,
  PopupContent,
  PopupContentContainer,
  PopupDescriptionParagraph,
  PopupDescriptionSection,
  PopupExternalLink,
  PopupName,
  PopupSection,
  PopupSectionParagraph,
  PopupSingleCategoryContainer,
  PopupSubSectionName,
  PopupTitle,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';
import {
  PopupControlsContainer,
  Founder,
  FounderInfo,
  FounderName,
  Mba,
  MbaContainer,
  SingleFounder,
} from 'pages/AdminPage/TabControl/CompanyTab/CompanyPopup/AdminCompanyPopup.styled';
import {
  CATEGORIES,
  FOUNDER_ATTRIBUTES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
  OPERATING_STAGE,
} from 'pages/HomePage/constants';
import Controls from 'pages/AdminPage/components/AdminTable/AdditionalElements/Controls/Controls';
import getCategoryColor from 'pages/HomePage/utils';
import Spacer from 'components/common/Spacer/Spacer.styled';
import IconPlaceholder from 'assets/icons/IconPlaceholder';
import validateURL from 'utils/linkValidator';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';
import { WHITE } from 'constants/colors';
import isURL from 'validator/lib/isURL';
import { NOT_AVAILABLE } from 'pages/HomePage/components/CompaniesContainer/constants';
import {
  AdminCompanyPopupPropTypes,
  AdminCompanyPopupDefaultProps,
} from 'pages/AdminPage/TabControl/CompanyTab/CompanyPopup/propTypes';

const AdminCompanyPopup = ({
  id,
  companyName,
  companyWebsite,
  isHiring,
  fundraising,
  fundraisingUpdatedAt,
  foundingYear,
  companyStage,
  fundingStage,
  creatorHbsClasscardLink,
  creatorHbsEmail,
  geographicFocus,
  deletedAt,
  founderAttributes,
  categories,
  companyDescription,
  founders,
  closeForm,
}) => {
  const navigate = useNavigate();

  const navigateToDelete = () => {
    closeForm();
    navigate('/administration/companies/delete', {
      state: { ids: [id] },
    });
  };

  const navigateToEdit = () => {
    closeForm();
    navigate('/administration/companies/edit', { state: { ids: [id] } });
  };

  const handleRestore = () => {
    closeForm();
    navigate('/administration/companies/restore', { state: { ids: [id] } });
  };

  return (
    <PopupContainer>
      <PopupContentContainer>
        <PopupTitleContainer>
          <PopupTitle>Company Information</PopupTitle>
          <PopupName>{companyName}</PopupName>
        </PopupTitleContainer>
        <PopupContent>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Company Website</PopupSubSectionName>
              {companyWebsite && isURL(companyWebsite) ? (
                <PopupExternalLink
                  href={validateURL(companyWebsite)}
                  target="_blank"
                  rel="noreferrer"
                  hasUnderline={isURL(companyWebsite)}
                >
                  {companyWebsite}
                </PopupExternalLink>
              ) : (
                <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
              )}
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Founding Year</PopupSubSectionName>
              <PopupSectionParagraph>
                {foundingYear || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Is company hiring?</PopupSubSectionName>
              <PopupSectionParagraph>
                {isHiring ? 'Yes' : 'No'}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Creator Wharton email</PopupSubSectionName>
              {creatorHbsEmail ? (
                <PopupExternalLink
                  href={`mailto:${creatorHbsEmail}`}
                  target="_blank"
                  rel="noreferrer"
                  hasUnderline={creatorHbsEmail}
                >
                  {creatorHbsEmail}
                </PopupExternalLink>
              ) : (
                <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
              )}
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Wharton Class Card</PopupSubSectionName>
              {creatorHbsClasscardLink ? (
                <PopupExternalLink
                  href={creatorHbsClasscardLink}
                  target="_blank"
                  rel="noreferrer"
                  hasUnderline={creatorHbsClasscardLink}
                >
                  {creatorHbsClasscardLink}
                </PopupExternalLink>
              ) : (
                <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
              )}
            </PopupSection>
          </PopupColumn>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Company Stage</PopupSubSectionName>
              <PopupSingleCategoryContainer>
                <PopupCategory
                  key={companyStage}
                  BgColor={getCategoryColor(companyStage, OPERATING_STAGE)}
                  Color={`${WHITE}`}
                  BorderRadius="4px"
                  Padding="1px 3px"
                >
                  {companyStage || NOT_AVAILABLE}
                </PopupCategory>
              </PopupSingleCategoryContainer>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Funding Stage</PopupSubSectionName>
              <PopupSingleCategoryContainer>
                <PopupCategory
                  key={fundingStage}
                  BgColor={getCategoryColor(fundingStage, FUNDING_STAGE)}
                  Color={`${WHITE}`}
                  BorderRadius="4px"
                  Padding="1px 3px"
                >
                  {fundingStage || NOT_AVAILABLE}
                </PopupCategory>
              </PopupSingleCategoryContainer>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Fundraising?</PopupSubSectionName>
              <PopupSectionParagraph>
                {/* eslint-disable-next-line no-nested-ternary */}
                {fundraising && fundraisingUpdatedAt
                  ? `Yes (${new Date(fundraisingUpdatedAt).toLocaleString(
                      'en-US',
                      {
                        month: '2-digit',
                        year: 'numeric',
                      },
                    )})`
                  : fundraisingUpdatedAt !== null
                  ? `No (${new Date(fundraisingUpdatedAt).toLocaleString(
                      'en-US',
                      {
                        month: '2-digit',
                        year: 'numeric',
                      },
                    )})`
                  : 'No'}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Founder(s) attributes</PopupSubSectionName>
              <PopupMultiCategories
                categories={founderAttributes}
                categoriesColors={FOUNDER_ATTRIBUTES}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Category</PopupSubSectionName>
              <PopupMultiCategories
                categories={categories}
                categoriesColors={CATEGORIES}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Geographic focus</PopupSubSectionName>
              <PopupMultiCategories
                categories={geographicFocus}
                categoriesColors={GEOGRAPHIC_LOCATIONS}
              />
            </PopupSection>
          </PopupColumn>
        </PopupContent>
        <Spacer Height="15px" />
        <PopupDescriptionSection>
          <PopupSubSectionName>Company Description</PopupSubSectionName>
          <PopupDescriptionParagraph>
            {companyDescription || NOT_AVAILABLE}
          </PopupDescriptionParagraph>
        </PopupDescriptionSection>
        <FounderInfo>
          {founders
            .sort((a, b) => a.position - b.position)
            .map((founder) => (
              <SingleFounder key={founder.fullName}>
                <IconPlaceholder />
                <FounderName>{founder.fullName}</FounderName>
                <MbaContainer>
                  <Mba>
                    {founder.classYear
                      ? `MBA ‘ ${founder.classYear.toString().slice(-2)}`
                      : NOT_AVAILABLE}
                  </Mba>
                  {founder.department && (
                    <Founder>Department: {founder.department}</Founder>
                  )}
                  <Founder>Founder: {companyName}</Founder>
                  {founder.founderEmail && (
                    <Founder>Email: {founder.founderEmail}</Founder>
                  )}
                  {founder.founderLinkedin && (
                    <Founder>
                      LinkedIn:{' '}
                      <a
                        href={
                          founder.founderLinkedin.startsWith('http')
                            ? founder.founderLinkedin
                            : `//${founder.founderLinkedin}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {founder.fullName}
                      </a>
                    </Founder>
                  )}
                </MbaContainer>
              </SingleFounder>
            ))}
        </FounderInfo>
      </PopupContentContainer>
      <Spacer Height="20px" />
      <PopupControlsContainer>
        <Controls
          openDeleteModalForId={navigateToDelete}
          openEditModalForId={navigateToEdit}
          handleRestore={handleRestore}
          isDeleted={!!deletedAt}
        />
      </PopupControlsContainer>
      <Spacer Height="20px" />
    </PopupContainer>
  );
};

AdminCompanyPopup.propTypes = {
  ...AdminCompanyPopupPropTypes(),
};

AdminCompanyPopup.defaultProps = {
  ...AdminCompanyPopupDefaultProps(),
};

export default AdminCompanyPopup;
