const PlusIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00065 13.334C5.06065 13.334 2.66732 10.9407 2.66732 8.00065C2.66732 5.06065 5.06065 2.66732 8.00065 2.66732C10.9407 2.66732 13.334 5.06065 13.334 8.00065C13.334 10.9407 10.9407 13.334 8.00065 13.334ZM8.00065 1.33398C7.12517 1.33398 6.25827 1.50642 5.44943 1.84145C4.64059 2.17649 3.90566 2.66755 3.28661 3.28661C2.03636 4.53685 1.33398 6.23254 1.33398 8.00065C1.33398 9.76876 2.03636 11.4645 3.28661 12.7147C3.90566 13.3338 4.64059 13.8248 5.44943 14.1598C6.25827 14.4949 7.12517 14.6673 8.00065 14.6673C9.76876 14.6673 11.4645 13.9649 12.7147 12.7147C13.9649 11.4645 14.6673 9.76876 14.6673 8.00065C14.6673 7.12517 14.4949 6.25827 14.1598 5.44943C13.8248 4.64059 13.3338 3.90566 12.7147 3.28661C12.0956 2.66755 11.3607 2.17649 10.5519 1.84145C9.74304 1.50642 8.87613 1.33398 8.00065 1.33398ZM8.66732 4.66732H7.33398V7.33398H4.66732V8.66732H7.33398V11.334H8.66732V8.66732H11.334V7.33398H8.66732V4.66732Z"
      fill="#ffffff"
    />
  </svg>
);

export default PlusIcon;
