const STEP_THREE_KEYS = {
  FULL_NAME: 'fullName',
  CLASS_YEAR: 'classYear',
  EMAIL: 'founderEmail',
  LINKEDIN: 'founderLinkedin',
  FOUNDER_ATTRIBUTES: 'founderAttributes',
  DEPARTMENT: 'department',
};

export default STEP_THREE_KEYS;
