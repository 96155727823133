import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { eventFormStateProps } from 'components/Forms/Events/propTypes';
import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';
import {
  ERROR_CONFIG,
  THANK_YOU_CONFIG,
  THANK_YOU_UPDATE_CONFIG,
} from 'components/Forms/Events/Response/constants';
import repackFormState from 'components/Forms/Events/Response/utils';
import { useAppContext } from 'contexts/AppContext';

const Response = ({ formState, request, update, closeForm }) => {
  const [success, setSuccess] = useState(true);
  const { successCallback } = useAppContext();
  const getThankYouConfig = () =>
    update ? THANK_YOU_UPDATE_CONFIG : THANK_YOU_CONFIG;

  const handleEvent = async () => {
    try {
      const requestBody = repackFormState(formState, update);
      const responseData = await request(requestBody, formState.id);

      if (responseData.status !== StatusCodes.OK)
        throw new Error(`Failed: ${responseData.response}`);

      setSuccess(true);
      if (successCallback) {
        successCallback();
      }

      setTimeout(() => {
        closeForm();
      }, 1200);
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    handleEvent();
  }, []);

  return <ResponsePopUp {...(success ? getThankYouConfig() : ERROR_CONFIG)} />;
};

Response.defaultProps = {
  update: false,
};

Response.propTypes = {
  formState: eventFormStateProps().isRequired,
  request: PropTypes.func.isRequired,
  update: PropTypes.bool,
  closeForm: PropTypes.func.isRequired,
};

export default Response;
