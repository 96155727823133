export const FILTERS_KEYS = {
  COMPANY_NAME: 'Company name',
  FOUNDER: 'Founder',
  CATEGORY: 'Category',
  FUNDING_STAGE: 'FundingStage',
  FOUNDING_YEAR: 'FoundingYear',
  IS_HIRING: 'IsHiring',
  IS_FUNDRAISING: 'IsFundraising',
  FOUNDERS_ATTRIBUTES: 'FoundersAttributes',
};

export const filterParamsSchema = {
  [FILTERS_KEYS.COMPANY_NAME]: 'companyName',
  [FILTERS_KEYS.FOUNDER]: 'founderName',
  [FILTERS_KEYS.CATEGORY]: 'category',
  [FILTERS_KEYS.FUNDING_STAGE]: 'stage',
  [FILTERS_KEYS.FOUNDING_YEAR]: 'foundingYear',
  [FILTERS_KEYS.IS_HIRING]: 'isHiring',
  [FILTERS_KEYS.IS_FUNDRAISING]: 'fundraising',
  [FILTERS_KEYS.FOUNDERS_ATTRIBUTES]: 'founderAttributes',
};

export const selectFiltersSchema = {
  [FILTERS_KEYS.CATEGORY]: {
    label: 'Category',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.FUNDING_STAGE]: {
    label: 'Funding stage',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.FOUNDING_YEAR]: {
    label: 'Founding year',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.IS_HIRING]: {
    label: 'Hiring',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.IS_FUNDRAISING]: {
    label: 'Fundraising',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.FOUNDERS_ATTRIBUTES]: {
    label: 'Founder Attributes',
    placeHolder: 'Select',
    MaxHeight: '300px',
  },
};

export const textFilterSchema = {};
