import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';
import { getPublicResourceOptions } from 'services/requests';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  ButtonsContainer,
} from 'components/Forms/Resources/AddResources.styled';
import {
  FieldContainer,
  RowContainer,
  Wrapper,
} from 'components/Forms/Resources/StepTwo/StepTwo.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import BackArrow from 'assets/icons/BackArrow';
import DragNDrop from 'components/common/DragNDrop/DragNDrop';
import HbsDatePicker from 'components/common/HbsDatePicker/HbsDatePicker';
import { PUBLIC_KEYS, BUTTONS_CSS } from 'components/Forms/Resources/constants';
import {
  ResourceInfoDescriptionSchema,
  ResourceInfoInputSchema,
  ResourceInfoSelectSchema,
} from 'components/Forms/Resources/StepTwo/PublicResource/schemas';
import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import { publicFormStateProps } from 'components/Forms/Resources/propTypes';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import FormDescription from 'components/common/FormComponents/Input/FormDescription/FormDescription';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import {
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/ResourcePage/constants';

const StepTwoPublic = ({
  currentStep,
  closeForm,
  backStep,
  nextStep,
  formState,
  setFormState,
}) => {
  const [inputsState, setInputsState] = useState(ResourceInfoInputSchema);
  const [selectsState, setSelectsState] = useState(ResourceInfoSelectSchema);
  const [descriptionState, setDescriptionState] = useState(
    ResourceInfoDescriptionSchema,
  );

  const { request } = useRequest();
  const currentDate = new Date();
  const [targetAudiences, setTargetAudience] = useState([]);
  const [publicDealCategories, setPublicDealCategories] = useState([]);

  const onChangeHandler =
    (stateHandler, key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      stateHandler((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };
  const { onBlurHandler, triggerError } = useFormInput();

  const selectDateHandler = (date) =>
    setFormState((prevState) => ({
      ...prevState,
      [PUBLIC_KEYS.EXPIRING_DATE]: date,
    }));

  const setUploadedFile = (data) =>
    setFormState((prevState) => ({
      ...prevState,
      [PUBLIC_KEYS.DEAL_IMAGE]: data,
    }));

  useEffect(() => {
    let ignore = false;

    const fetchOptions = async () => {
      if (!ignore) {
        const allData = await request(() => getPublicResourceOptions());

        if (allData) {
          setTargetAudience(
            mapCategoriesToOptions(
              allData[0].data,
              PUBLIC_RESOURCE_TARGET_AUDIENCES,
            ),
          );
          setPublicDealCategories(
            mapCategoriesToOptions(allData[1].data, PUBLIC_RESOURCE_CATEGORIES),
          );
        }
      }
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, []);

  const multiSelectionHandler = (key, collection) => (options) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]:
        options &&
        options.map((option) =>
          collection.find((object) => object.id === option.id),
        ),
    }));
    setSelectsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const onClickSubmitButton = (e) => {
    e.preventDefault();

    const keysInput = Object.keys(inputsState);
    const keysSelect = Object.keys(selectsState);
    const keysDescription = Object.keys(descriptionState);

    const inputsWithErrors = keysInput.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );
    const selectsWithErrors = keysSelect.filter(
      (key) => !selectsState[key].validator(formState[key]),
    );
    const descriptionWithError = keysDescription.filter(
      (key) => !descriptionState[key].validator(formState[key]),
    );

    inputsWithErrors.forEach((key) => triggerError(setInputsState, key));
    selectsWithErrors.forEach((key) => triggerError(setSelectsState, key));
    descriptionWithError.forEach((key) =>
      triggerError(setDescriptionState, key),
    );

    if (
      inputsWithErrors.length ||
      selectsWithErrors.length ||
      descriptionWithError.length
    )
      return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>
          <SecondaryButton
            Border="none"
            noDark
            type="button"
            onClick={backStep}
          >
            <BackArrow />
          </SecondaryButton>
          {`Step ${currentStep}`}
        </Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputsState[PUBLIC_KEYS.DEAL_URL]}
              value={formState[PUBLIC_KEYS.DEAL_URL]}
              onChangeHandler={onChangeHandler(
                setInputsState,
                PUBLIC_KEYS.DEAL_URL,
              )}
              onBlurHandler={onBlurHandler(
                setInputsState,
                PUBLIC_KEYS.DEAL_URL,
              )}
              name={PUBLIC_KEYS.DEAL_URL}
            />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              key={`${PUBLIC_KEYS.TARGET_AUDIENCES}+${targetAudiences.length}`}
              {...selectsState[PUBLIC_KEYS.TARGET_AUDIENCES]}
              selectedValue={formState[PUBLIC_KEYS.TARGET_AUDIENCES]}
              options={targetAudiences}
              onChangeHandler={multiSelectionHandler(
                PUBLIC_KEYS.TARGET_AUDIENCES,
                targetAudiences,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              key={`${PUBLIC_KEYS.CATEGORIES}+${targetAudiences.length}`}
              {...selectsState[PUBLIC_KEYS.CATEGORIES]}
              selectedValue={formState[PUBLIC_KEYS.CATEGORIES]}
              options={publicDealCategories}
              onChangeHandler={multiSelectionHandler(
                PUBLIC_KEYS.CATEGORIES,
                publicDealCategories,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <HbsDatePicker
              selectedDate={formState[PUBLIC_KEYS.EXPIRING_DATE]}
              minDate={currentDate}
              setSelectedDate={selectDateHandler}
            />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormDescription
              {...descriptionState[PUBLIC_KEYS.DEAL_DESCRIPTION]}
              name={PUBLIC_KEYS.DEAL_DESCRIPTION}
              value={formState[PUBLIC_KEYS.DEAL_DESCRIPTION]}
              onChangeHandler={onChangeHandler(
                setDescriptionState,
                PUBLIC_KEYS.DEAL_DESCRIPTION,
              )}
              onBlurHandler={onBlurHandler(
                setDescriptionState,
                PUBLIC_KEYS.DEAL_DESCRIPTION,
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <DragNDrop
              uploadedFile={formState[PUBLIC_KEYS.DEAL_IMAGE]}
              setUploadedFile={setUploadedFile}
              label="Deal logo"
              dndParagraph="Drag and drop or upload a company logo"
            />
          </FieldContainer>
        </RowContainer>
        <ButtonsContainer>
          <SecondaryButton {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <DarkButton {...BUTTONS_CSS.primary} onClick={onClickSubmitButton}>
            Next: Contact
          </DarkButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepTwoPublic.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: publicFormStateProps().isRequired,
};

export default StepTwoPublic;
