const PasswordChangedIcon = () => (
  <svg
    width="180"
    height="144"
    viewBox="0 0 180 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M174.344 143.336H5.65619C4.15607 143.336 2.7174 142.741 1.65666 141.682C0.595917 140.623 0 139.187 0 137.689C61.1492 130.594 121.149 130.594 180 137.689C180 139.187 179.404 140.623 178.343 141.682C177.283 142.741 175.844 143.336 174.344 143.336Z"
      fill="#333333"
    />
    <path
      d="M180 138.021L0 137.689L20.8614 102.644L20.9612 102.478V25.2425C20.9611 24.305 21.146 23.3767 21.5053 22.5105C21.8646 21.6444 22.3913 20.8574 23.0552 20.1945C23.7192 19.5317 24.5074 19.0058 25.375 18.6471C26.2425 18.2884 27.1723 18.1038 28.1113 18.1039H150.891C151.83 18.1038 152.759 18.2884 153.627 18.6471C154.494 19.0058 155.283 19.5317 155.947 20.1945C156.611 20.8574 157.137 21.6444 157.497 22.5105C157.856 23.3767 158.041 24.305 158.041 25.2425V103.009L180 138.021Z"
      fill="#767674"
    />
    <path
      d="M27.9484 23.0866C27.3309 23.0873 26.7389 23.3325 26.3023 23.7684C25.8657 24.2043 25.6201 24.7954 25.6194 25.4118V95.8342C25.6201 96.4507 25.8657 97.0417 26.3023 97.4776C26.7389 97.9135 27.3309 98.1588 27.9484 98.1595H152.052C152.669 98.1587 153.261 97.9135 153.698 97.4776C154.135 97.0417 154.38 96.4507 154.381 95.8342V25.4118C154.38 24.7954 154.135 24.2043 153.698 23.7684C153.261 23.3325 152.669 23.0873 152.052 23.0866H27.9484Z"
      fill="white"
    />
    <path
      d="M28.468 107.461C28.2772 107.461 28.0905 107.516 27.9298 107.618C27.7691 107.721 27.6412 107.868 27.5612 108.041L21.1331 121.992C21.0632 122.144 21.0326 122.311 21.0441 122.478C21.0557 122.644 21.1091 122.806 21.1994 122.946C21.2897 123.087 21.414 123.203 21.5609 123.283C21.7077 123.363 21.8724 123.405 22.0398 123.405H157.218C157.388 123.405 157.555 123.362 157.704 123.279C157.853 123.196 157.978 123.077 158.067 122.933C158.157 122.788 158.207 122.623 158.215 122.453C158.223 122.284 158.187 122.115 158.111 121.963L151.124 108.011C151.041 107.846 150.914 107.706 150.756 107.609C150.598 107.511 150.416 107.46 150.231 107.461H28.468Z"
      fill="#545556"
    />
    <path
      d="M89.5006 21.9239C90.3275 21.9239 90.9979 21.2547 90.9979 20.4291C90.9979 19.6036 90.3275 18.9343 89.5006 18.9343C88.6737 18.9343 88.0034 19.6036 88.0034 20.4291C88.0034 21.2547 88.6737 21.9239 89.5006 21.9239Z"
      fill="white"
    />
    <path
      d="M76.2138 126.063C75.9944 126.063 75.7811 126.135 75.607 126.269C75.4328 126.402 75.3074 126.589 75.2501 126.8L73.6377 132.779C73.5978 132.927 73.5925 133.082 73.6222 133.232C73.652 133.382 73.7159 133.523 73.8091 133.645C73.9023 133.766 74.0222 133.864 74.1596 133.932C74.297 134 74.4482 134.035 74.6014 134.035H105.266C105.425 134.035 105.582 133.997 105.723 133.924C105.865 133.851 105.987 133.746 106.079 133.616C106.171 133.487 106.231 133.337 106.254 133.18C106.276 133.023 106.261 132.863 106.209 132.712L104.136 126.733C104.068 126.537 103.94 126.368 103.771 126.248C103.602 126.127 103.4 126.063 103.192 126.063H76.2138Z"
      fill="#333333"
    />
    <path
      d="M158.04 101.315V102.644H20.8611L20.9643 102.478V101.315H158.04Z"
      fill="#333333"
    />
    <path
      d="M154.214 46.5053C167.077 46.5053 177.504 36.0948 177.504 23.2527C177.504 10.4106 167.077 0 154.214 0C141.351 0 130.924 10.4106 130.924 23.2527C130.924 36.0948 141.351 46.5053 154.214 46.5053Z"
      fill="#011F5B"
    />
    <path
      d="M151.423 32.4724C150.882 32.4734 150.355 32.3024 149.923 31.9852L149.896 31.9655L144.244 27.7371C143.983 27.5411 143.763 27.2967 143.598 27.0178C143.433 26.7388 143.326 26.4308 143.282 26.1114C143.239 25.792 143.261 25.4674 143.346 25.1561C143.431 24.8448 143.578 24.5529 143.778 24.2971C143.979 24.0413 144.229 23.8267 144.515 23.6654C144.8 23.5041 145.115 23.3993 145.442 23.357C145.769 23.3147 146.101 23.3358 146.42 23.4189C146.738 23.5021 147.037 23.6458 147.298 23.8418L150.959 26.5847L159.609 15.56C159.81 15.3043 160.06 15.0897 160.346 14.9285C160.631 14.7673 160.946 14.6626 161.273 14.6205C161.6 14.5783 161.932 14.5994 162.251 14.6827C162.569 14.766 162.868 14.9098 163.129 15.1059L163.076 15.1773L163.131 15.1069C163.659 15.5034 164.004 16.0882 164.091 16.7331C164.178 17.378 164 18.0304 163.595 18.5471L153.421 31.5148C153.185 31.8136 152.883 32.0555 152.536 32.2215C152.19 32.3876 151.809 32.4734 151.423 32.4724Z"
      fill="white"
    />
    <path
      d="M99.3475 72.2802H80.0499C79.697 72.2798 79.3587 72.1397 79.1092 71.8906C78.8597 71.6415 78.7193 71.3038 78.719 70.9515V59.3251C78.7194 58.9728 78.8597 58.6351 79.1092 58.386C79.3587 58.1369 79.697 57.9968 80.0499 57.9964H99.3475C99.7003 57.9968 100.039 58.1369 100.288 58.386C100.538 58.6351 100.678 58.9728 100.678 59.3251V70.9515C100.678 71.3037 100.538 71.6415 100.288 71.8906C100.039 72.1397 99.7003 72.2798 99.3475 72.2802ZM80.0499 59.3251V70.9515H99.3484L99.3475 59.3251L80.0499 59.3251Z"
      fill="#333333"
    />
    <path
      d="M95.6878 59.3251H83.71V54.0102C83.71 50.1606 86.2287 47.3666 89.6989 47.3666C93.169 47.3666 95.6878 50.1606 95.6878 54.0102L95.6878 59.3251ZM85.0408 57.9964H94.3569V54.0102C94.3569 50.8809 92.4415 48.6953 89.6989 48.6953C86.9563 48.6953 85.0409 50.8809 85.0409 54.0102L85.0408 57.9964Z"
      fill="#333333"
    />
    <path
      d="M89.6993 66.1348C90.4343 66.1348 91.0301 65.5399 91.0301 64.8061C91.0301 64.0722 90.4343 63.4774 89.6993 63.4774C88.9643 63.4774 88.3684 64.0722 88.3684 64.8061C88.3684 65.5399 88.9643 66.1348 89.6993 66.1348Z"
      fill="#333333"
    />
  </svg>
);

export default PasswordChangedIcon;
