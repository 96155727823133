import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const AllEventsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  gap: 24px;
  width: 100%;

  @media ${DEVICE.tablet} {
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const AllEvent = styled.div`
  display: flex;
  border: 1px solid var(--DAVY_GRAY_OR_BRIGHT_GRAY);
  border-radius: 4px;
  cursor: pointer;
  height: 100px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 20px;
  position: relative;
  top: 0;
  transition: top ease 0.25s;
  width: 500px;

  &:hover {
    top: -6px;
  }

  @media ${DEVICE.laptopL} {
    width: 100%;
    max-width: 400px;
  }

  @media ${DEVICE.laptop} {
    max-width: 350px;
  }

  @media ${DEVICE.tablet} {
    max-width: 300px;
    padding: 16px;
    min-height: 100px;
  }
`;

export const AllEventContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 90%;
  justify-content: space-between;
`;

export const AllEventTitle = styled.p`
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICE.tablet} {
    font-size: 16px;
  }
`;

export const AllEventDate = styled.p`
  color: var(--GRAY_80_OR_DAVY_GRAY);
  margin: 0;
`;
