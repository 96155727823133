import Dots from 'components/common/Dots/Dots';
import { useAppContext } from 'contexts/AppContext';
import {
  WelcomeToPageContainer,
  WelcomeToPageParagraph,
  WelcomeToPageRedLine,
  WelcomeToPageTitle,
} from 'components/common/WelcomeToPage/WelcomeToPage.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import PropTypes from 'prop-types';
import { ADD_AN_EXCLUSIVE_DEAL } from 'components/Forms/ExclusiveDeals/constants';
import { ButtonWrapper, AddResourceButton } from './WBSExclusive.styled';

const WBSExclusive = ({ successCallback }) => {
  const { setActiveForm } = useAppContext();

  return (
    <WelcomeToPageContainer>
      <Dots rows={12} />
      <WelcomeToPageTitle>Exclusive Deals</WelcomeToPageTitle>
      <WelcomeToPageParagraph>
        An independent set of resources for founders from the Wharton community
        built by members of the Wharton community
      </WelcomeToPageParagraph>
      <Spacer Height="20px" />
      <ButtonWrapper>
        <AddResourceButton
          onClick={() => setActiveForm(ADD_AN_EXCLUSIVE_DEAL, successCallback)}
        >
          Add a new exclusive deal
        </AddResourceButton>
      </ButtonWrapper>
      <Spacer Height="20px" />
      <WelcomeToPageRedLine />
    </WelcomeToPageContainer>
  );
};
WBSExclusive.propTypes = {
  successCallback: PropTypes.func.isRequired,
};
export default WBSExclusive;
