import {
  hbsEmailValidator,
  isEmptyFieldValidator,
  isEmptyOrValidUrl,
  isTruthy,
  isUrlValid,
} from 'utils/inputValidators';
import {
  INPUT_KEYS,
  MULTI_SELECT_KEYS,
  RADIO_KEYS,
  SINGLE_SELECT_KEYS,
} from './constants';

export const inputSchema = {
  [INPUT_KEYS.CREATOR_FULL_NAME]: {
    label: 'Your full name*',
    inputType: 'text',
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your full name',
    validator: isEmptyFieldValidator,
  },
  [INPUT_KEYS.CREATOR_HBS_EMAIL]: {
    label: 'Wharton email*',
    inputType: 'email',
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your Wharton email',
    validator: hbsEmailValidator,
  },
  [INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK]: {
    label: 'Penn link or LinkedIn*',
    inputType: 'text',
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your Penn link or LinkedIn',
    validator: isUrlValid,
  },
  [INPUT_KEYS.COMPANY_NAME]: {
    label: 'Company name*',
    inputType: 'text',
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your company name',
    validator: isEmptyFieldValidator,
  },
  [INPUT_KEYS.COMPANY_WEBSITE]: {
    label: 'Company website',
    inputType: 'text',
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Link you have provided is not valid',
    validator: isEmptyOrValidUrl,
  },
  [INPUT_KEYS.COMPANY_DESCRIPTION]: {
    label: 'Company description*',
    placeholder: 'Enter your company description...',
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Please enter your description',
    validator: isEmptyFieldValidator,
    RowNum: 7,
  },
};

export const singleSelectSchema = {
  [SINGLE_SELECT_KEYS.COMPANY_CARD_COLOR]: {
    label: 'Color of the card*',
    placeHolder: 'Select a card color',
    MaxHeight: '110px',
    validator: isTruthy,
  },
  [SINGLE_SELECT_KEYS.COMPANY_STAGE]: {
    label: 'Company stage*',
    placeHolder: 'Select a company stage',
    MaxHeight: '110px',
    validator: isTruthy,
  },
  [SINGLE_SELECT_KEYS.FUNDING_STAGE]: {
    label: 'Funding stage*',
    placeHolder: 'Select a funding stage',
    MaxHeight: '150px',
    validator: isTruthy,
  },
  [SINGLE_SELECT_KEYS.FOUNDING_YEAR]: {
    label: 'Founding year*',
    placeHolder: 'Select a founding year',
    MaxHeight: '110px',
    validator: isTruthy,
  },
  [SINGLE_SELECT_KEYS.FUNDRAISING]: {
    label: 'Is this company currently fundraising*',
    placeHolder: 'Select',
    MaxHeight: '110px',
    validator: () => true,
  },
};

export const multiSelectSchema = {
  [MULTI_SELECT_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Pick up to 3 categories',
    MaxHeight: '110px',
    disabledDarkMode: true,
    validator: isEmptyFieldValidator,
  },
  [MULTI_SELECT_KEYS.GEOGRAPHIC_FOCUS]: {
    label: 'Geographical focus',
    placeHolder: 'Pick up to 3 locations',
    MaxHeight: '110px',
    disabledDarkMode: true,
    validator: () => true,
  },
  [MULTI_SELECT_KEYS.FOUNDER_ATTRIBUTES]: {
    label: 'Founder(s) attributes',
    placeHolder: 'Pick up to 3 attributes',
    MaxHeight: '110px',
    disabledDarkMode: true,
    validator: () => true,
  },
};

export const isHiringOptions = [
  {
    label: 'Yes',
    name: RADIO_KEYS.IS_HIRING,
  },
  {
    label: 'No',
    name: RADIO_KEYS.IS_HIRING,
  },
];
