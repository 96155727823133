import {
  WelcomeToPageContainer,
  WelcomeToPageTitle,
  WelcomeToPageParagraph,
  WelcomeToPageRedLine,
} from 'components/common/WelcomeToPage/WelcomeToPage.styled';

const AdminPageLandingSection = () => (
  <WelcomeToPageContainer>
    <WelcomeToPageTitle>Welcome to Admin panel</WelcomeToPageTitle>
    <WelcomeToPageParagraph>
      This is a space where you can keep track all of the data in WhartonBuilds.
      Feel free to contact Symphony personally if you need assistance.
    </WelcomeToPageParagraph>
    <WelcomeToPageRedLine />
  </WelcomeToPageContainer>
);

export default AdminPageLandingSection;
