import isURL from 'validator/lib/isURL';

export const isTruthy = (value) => !!value;

export const isEmptyFieldValidator = (value) =>
  typeof value === 'string' ? !!value.trim().length : !!value.length;

// export const hbsEmailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9._%+-]+\.upenn.edu/i;

// export const hbsEmailValidator = (value) => hbsEmailPattern.test(value);

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
export const hbsEmailValidator = (value) => emailPattern.test(value);

export const emailValidator = (value) => emailPattern.test(value);

// const urlPattern =
//   /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\
// .[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const isUrlValid = (str) => isURL(str, { require_protocol: true });
// !str.includes('@') && urlPattern.test(str);

export const isEmptyOrValidUrl = (str) => !str || isUrlValid(str);

export const isValidDate = (date) => date instanceof Date;
