import styled from 'styled-components';
import { CRIMSON, DARK_CHARCOAL, RAISIN_BLACK } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    justify-content: center;
    margin: 0 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const Title = styled.h3`
  color: ${DARK_CHARCOAL};
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 0 0 10px;
  text-align: center;
`;

export const SubTitle = styled.h4`
  color: ${DARK_CHARCOAL};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 40px;
  text-align: center;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 142px;
  justify-content: flex-end;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: ${RAISIN_BLACK};
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 0;
  }

  a {
    color: ${CRIMSON};
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:hover::after {
      text-decoration: none;
    }
  }

  @media ${DEVICE.laptop} {
    margin: 0 20px;
    min-height: 100px;
    text-align: center;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;

  @media ${DEVICE.formResponsive} {
    gap: 10px;
  }
`;

export const FieldContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;
