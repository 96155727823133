import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import {
  Wrapper,
  Title,
  DescriptionParagraph,
  ButtonsContainer,
  BUTTONS_CSS,
  Error,
} from 'pages/AdminPage/components/Forms/Company/RestoreCompany/RestoreCompany.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { restoreCompanies } from 'services/requests';
import useRequest from 'hooks/useRequest';
import useCompanyParams from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/useCompanyParams/useCompanyParams';
import useCompaniesData from 'pages/AdminPage/TabControl/CompanyTab/useCompaniesData';

const RestoreCompany = () => {
  const [error, setError] = useState(false);
  const {
    state: { ids },
  } = useLocation();
  const navigate = useNavigate();
  const { request } = useRequest();
  const { companyQueryParams } = useCompanyParams();
  const { setData } = useCompaniesData();

  const closeForm = () => navigate({ pathname: '/administration' });
  const handleRestore = async () => {
    setError(false);
    let status;

    try {
      const res = await restoreCompanies(ids);
      status = res.status;
    } catch (e) {
      status = e.response?.status;
      setError(true);
    }

    if (status === 200) {
      setData(request, companyQueryParams);
      closeForm();
    }
  };

  const count = ids.length;

  return (
    <Modal closeForm={closeForm}>
      <Wrapper>
        <Title>Restore Company</Title>
        <DescriptionParagraph>{`Are you sure you want to restore ${count} ${
          count > 1 ? 'companies' : 'company'
        }?`}</DescriptionParagraph>
        {error && <Error>*Something went wrong</Error>}
        <ButtonsContainer>
          <SecondaryButton $ {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <PrimaryButton $ {...BUTTONS_CSS.primary} onClick={handleRestore}>
            Restore
          </PrimaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default RestoreCompany;
