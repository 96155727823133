import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import DEVICE from 'constants/deviceSizes';
import styled, { keyframes } from 'styled-components';

export const CreateUpdateHireContainer = styled.div`
  border: 1px solid var(--DARK_CHARCOAL_OR_BRIGHT_GRAY);
  background-color: var(--RAISING_BLACK_OR_WHITE);
  width: 100%;
  max-width: 480px;
`;

export const CreateUpdateHireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 32px;
  gap: 30px;

  @media ${DEVICE.laptopL} {
    padding: 20px;
  }
`;

export const BrandsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandNameWrapper = styled.span`
  font-size: 18px;
  line-height: 40px;
`;

export const disappear = keyframes`
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

export const BrandName = styled.b`
  font-size: 20px;
  color: var(--WHITE_OR_CRIMSON);
  animation: ${disappear} 3s infinite;
`;

export const AddUpdateCompanyContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
  }
`;

export const AddCompanyButton = styled(PrimaryButton)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 11px 33px;
  border: none;

  @media ${DEVICE.laptopL} {
    font-size: 14px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    padding: 8px 12px;
  }
`;

export const UpdateCompanyButton = styled(SecondaryButton)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 11px 20px;
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  border-color: var(--GRAY_80_OR_DAVY_GRAY);

  @media ${DEVICE.laptopL} {
    font-size: 14px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    padding: 8px 12px;
  }
`;
