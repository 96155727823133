import CATEGORY_COLORS from 'constants/category_colors';

export const CATEGORIES = {
  [CATEGORY_COLORS.BLUE]: [
    'AR/VR',
    'Business Services',
    'Defense / Military',
    'Families / Kids / Parents',
    'Gig Economy',
    'Health: Medical Devices',
    'Machine Learning',
    'Pharmaceuticals',
    'Social Network',
  ],
  [CATEGORY_COLORS.SKY_BLUE]: [
    'Advertising',
    'Chemicals',
    'Developer Tools',
    'Fashion',
    'GovTech',
    'Health: Therapeutics',
    'Manufacturing',
    'Real Estate / PropTech / Construction Tech',
    'Space',
  ],
  [CATEGORY_COLORS.TURQUOISE]: [
    'AgTech',
    'Climate Tech / Clean Tech / Renewables',
    'Direct-to-Consumer (DTC)',
    'Fintech: Banking / Debt Underwriting',
    'Hardware',
    'Human Capital / HRTech',
    'Marketing',
    'Retail',
    'Travel',
  ],
  [CATEGORY_COLORS.GREEN]: [
    'Apparel',
    'Cloud Infrastructure',
    'Diversity Equity & Inclusion (DEI)',
    'Fintech: Infrastructure',
    'Health: Consumer',
    'IT Services',
    'Marketplace',
    'Robotics',
  ],
  [CATEGORY_COLORS.YELLOW]: [
    'Artificial Intelligence (AI): General',
    'Consumer Products & Packaged Goods (CPG)',
    'E-commerce',
    'Fintech: Insurance',
    'Health: Diagnostics',
    'Impact',
    'Material Sciences',
    'SMB Software',
  ],
  [CATEGORY_COLORS.WARM_YELLOW]: [
    'Artificial Intelligence (AI): Generative',
    'Creator / Passion Economy',
    'Education / EdTech',
    'Fintech: Proptech',
    'Health: Digital Health',
    'IoT',
    'Media / Music / Content / Publishing',
    'SaaS',
  ],
  [CATEGORY_COLORS.SALMON]: [
    'Arts & Culture',
    'Crypto / Web3 / Blockchain',
    'Energy',
    'Fitness & Sports',
    'Health: Drugs / Drug Discovery / Pharma',
    'LegalTech',
    'Mental Health',
    'Sales & CRM',
  ],
  [CATEGORY_COLORS.LAVANDER]: [
    'Aviation / Aerospace',
    'Cybersecurity & Security',
    'Enterprise',
    'Food & Beverages',
    'Health: Health',
    'Local Services',
    'Mobility / Self Driving / Auto',
    'Semiconductors',
  ],
  [CATEGORY_COLORS.PURPLE]: [
    'Beauty / Cosmetics',
    'Data: Analytics & Services',
    'Enterprise Infrastructure',
    'Future of Work',
    'Health: Hospital Services',
    'Lodging / Hospitality',
    'Oil & Gas',
    'Services',
  ],
  [CATEGORY_COLORS.GRAY]: [
    'BioTech / Life Sciences',
    'Deep Tech',
    'Entertainment & Sports',
    'Games/Gaming/eSports',
    'Health: IT',
    'Logistics & Supply Chain',
    'Personal Health & Wellness',
    'Social Media',
  ],
};

export const FUNDING_STAGE = {
  [CATEGORY_COLORS.BLUE]: ['Bootstrapped', 'Exited: Other'],
  [CATEGORY_COLORS.SKY_BLUE]: ['Friends & Family/Angels', 'Dissolved/Shutdown'],
  [CATEGORY_COLORS.TURQUOISE]: ['Seed Stage', 'Nonprofit', 'Debt'],
  [CATEGORY_COLORS.GREEN]: ['Series A', 'Other: ICO'],
  [CATEGORY_COLORS.YELLOW]: ['Series B'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Series C'],
  [CATEGORY_COLORS.SALMON]: ['Series D+'],
  [CATEGORY_COLORS.LAVANDER]: ['Pre-Ipo'],
  [CATEGORY_COLORS.PURPLE]: ['Exited: IPO'],
  [CATEGORY_COLORS.GRAY]: ['Exited: Acquisition'],
};

export const OPERATING_STAGE = {
  [CATEGORY_COLORS.BLUE]: ['Ideation'],
  [CATEGORY_COLORS.GREEN]: ['Scaling'],
  [CATEGORY_COLORS.YELLOW]: ['Mature'],
  [CATEGORY_COLORS.SALMON]: ['Growth'],
  [CATEGORY_COLORS.PURPLE]: ['MVP/Pre-Product Market Fit'],
};

export const FOUNDER_ATTRIBUTES = {
  [CATEGORY_COLORS.BLUE]: ['BIPOC Founder(s)'],
  [CATEGORY_COLORS.SKY_BLUE]: ['Disabled Founder(s)'],
  [CATEGORY_COLORS.TURQUOISE]: [`ESL Founder(s)`],
  [CATEGORY_COLORS.YELLOW]: [`Global South Founder(s)`],
  [CATEGORY_COLORS.WARM_YELLOW]: [`Veteran Founder(s)`],
  [CATEGORY_COLORS.SALMON]: [`LGBTQIA Founder(s)`],
  [CATEGORY_COLORS.LAVANDER]: [`Female Founder(s)`],
  [CATEGORY_COLORS.PURPLE]: [`PhD Founder(s)`],
};

export const GEOGRAPHIC_LOCATIONS = {
  [CATEGORY_COLORS.BLUE]: [`China`],
  [CATEGORY_COLORS.SKY_BLUE]: [`Europe`],
  [CATEGORY_COLORS.TURQUOISE]: [`Global South`],
  [CATEGORY_COLORS.GREEN]: [`Africa`, `India`],
  [CATEGORY_COLORS.YELLOW]: [`Africa (Subsaharan)`, `LatAm/South America`],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Series C', `South East Asia`],
  [CATEGORY_COLORS.SALMON]: [`Africa (North) / MENA`, `USA/Canada`],
  [CATEGORY_COLORS.LAVANDER]: [`Africa (East)`, `Western World`],
  [CATEGORY_COLORS.PURPLE]: [`Africa (West)`],
  [CATEGORY_COLORS.GRAY]: [`Brazil`],
};
