import PropTypes from 'prop-types';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  ButtonsContainer,
} from 'components/Forms/Resources/AddResources.styled';
import {
  Wrapper,
  DescriptionContainer,
} from 'components/Forms/Resources/StepOne/StepOne.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import generalInfoInputSchema from 'components/Forms/Resources/StepOne/schemas';
import { GENERAL_INFO_TEXT } from 'components/Forms/Resources/StepOne/constants';
import { useState } from 'react';
import {
  DarkButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { publicFormStateProps } from 'components/Forms/Resources/propTypes';
import { BUTTONS_CSS, PUBLIC_KEYS } from 'components/Forms/Resources/constants';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';

const StepOne = ({
  currentStep,
  closeForm,
  nextStep,
  setFormState,
  formState,
}) => {
  const [formInputState, setFormInputState] = useState(generalInfoInputSchema);

  const { onBlurHandler, triggerError } = useFormInput();

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      setFormInputState((prevState) => ({
        ...prevState,
        [PUBLIC_KEYS.DEAL_NAME]: {
          ...prevState[PUBLIC_KEYS.DEAL_NAME],
          isError:
            prevState[PUBLIC_KEYS.DEAL_NAME].isError &&
            !prevState[PUBLIC_KEYS.DEAL_NAME].validator(value),
        },
      }));
    };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !formInputState[PUBLIC_KEYS.DEAL_NAME].validator(
        formState[PUBLIC_KEYS.DEAL_NAME],
      )
    ) {
      triggerError(setFormInputState, PUBLIC_KEYS.DEAL_NAME);
      return;
    }

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>{`Step ${currentStep}`}</Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer onSubmit={onSubmit}>
        <DescriptionContainer>
          <b>{GENERAL_INFO_TEXT.DESCRIPTION_LABEL}</b>
          <Spacer Height="5px" />
          <p>{GENERAL_INFO_TEXT.DESCRIPTION} </p>
        </DescriptionContainer>
        <Spacer Height="20px" />

        <FormInput
          {...formInputState[PUBLIC_KEYS.DEAL_NAME]}
          name="name"
          key={PUBLIC_KEYS.DEAL_NAME}
          value={formState[PUBLIC_KEYS.DEAL_NAME] || ''}
          onBlurHandler={onBlurHandler(
            setFormInputState,
            PUBLIC_KEYS.DEAL_NAME,
          )}
          onChangeHandler={onChangeHandler(PUBLIC_KEYS.DEAL_NAME)}
        />

        <DescriptionContainer>
          <p>{GENERAL_INFO_TEXT.GRATITUDE} </p>
        </DescriptionContainer>
        <ButtonsContainer>
          <SecondaryButton
            {...BUTTONS_CSS.secondary}
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <DarkButton {...BUTTONS_CSS.primary}>Next: Resource Info</DarkButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepOne.propTypes = {
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: publicFormStateProps().isRequired,
};

export default StepOne;
