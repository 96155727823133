import useRequest from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { getAllEvents } from 'services/requests';
import {
  augmentDayProperty,
  splitDataByDate,
} from 'pages/EventPage/components/Content/utils';
import {
  PAST_EVENTS,
  UPCOMING_EVENTS,
} from 'pages/EventPage/components/Content/constants';

export default function useEvents() {
  const [selectedEventType, setSelectedEventType] = useState(PAST_EVENTS);
  const [fetching, setFetching] = useState(true);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const { request } = useRequest();

  const fetchEvents = async (ignore) => {
    const response = await request(() => getAllEvents());

    if (!ignore && response) {
      const { upcoming, past } = splitDataByDate(
        response.data.map(augmentDayProperty),
      );

      if (upcoming.length) setSelectedEventType(UPCOMING_EVENTS);

      setUpcomingEvents(upcoming);
      setPastEvents(past);
      setAllEvents(response.data?.map(augmentDayProperty));
    }

    if (!ignore) setFetching(false);
  };

  useEffect(() => {
    let ignore = false;

    fetchEvents(ignore);

    return () => {
      ignore = true;
    };
  }, [request]);
  return {
    selectedEventType,
    fetching,
    upcomingEvents,
    pastEvents,
    allEvents,
    setUpcomingEvents,
    setSelectedEventType,
    fetchEvents,
  };
}
