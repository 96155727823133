import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const UpcomingEventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px 0;
  padding: 20px 0;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${DEVICE.tablet} {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  height: 250px;
  flex-direction: column;
  max-width: 180px;
  width: 100%;

  @media ${DEVICE.laptopL} {
    max-width: 100px;
  }

  @media ${DEVICE.laptop} {
    max-width: 80px;
  }

  @media ${DEVICE.tablet} {
    flex-direction: row;
    height: 90px;
    gap: 10px;
    max-width: 450px;
    width: 100%;
    margin-bottom: 12px;
  }

  @media ${DEVICE.formResponsive} {
    max-width: 335px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  height: 250px;
  flex-direction: column;
  width: 100%;

  @media ${DEVICE.tablet} {
    height: 90px;
    width: 64px;
  }
`;

export const DateMonthParagraph = styled.p`
  color: var(--WHITE_OR_CRIMSON);
  font-size: 18px;
  line-height: 30px;
  margin: 0;
`;

export const DateDayHeader = styled.p`
  color: var(--WHITE_OR_CRIMSON);
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
  margin: 0;
`;
export const EventCountContainer = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    align-items: flex-end;
    border-bottom: 1px solid var(--DAVY_GRAY_OR_BRIGHT_GRAY);
    display: flex;
    margin-bottom: 17px;
    width: 100%;
  }
`;

export const EventCountPerDay = styled.p`
  color: var(--GRAY_80_OR_DAVY_GRAY);
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

export const UpcomingEventsCardContainer = styled.div`
  display: flex;
  border-left: 1px solid var(--DAVY_GRAY_OR_BRIGHT_GRAY);
  flex-wrap: wrap;
  padding-left: 20px;
  gap: 16px;

  @media ${DEVICE.tablet} {
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
  }
`;
