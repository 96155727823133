import { COMPANIES_ACTIONS } from './companiesActions';

const companiesReducer = (state, action) => {
  if (action.type === COMPANIES_ACTIONS.LOADING_STARTED)
    return {
      ...state,
      isLoading: true,
    };

  if (action.type === COMPANIES_ACTIONS.LOADING_FINISHED)
    return {
      ...state,
      isLoading: false,
    };

  if (action.type === COMPANIES_ACTIONS.DATA_RECEIVED)
    return {
      ...state,
      data: action.payload,
      error: null,
    };

  if (action.type === COMPANIES_ACTIONS.DATA_ERROR)
    return {
      ...state,
      error: action.payload,
    };

  return new Error(`no such action: ${action.type}`);
};

export default companiesReducer;
