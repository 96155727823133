export const isUpcoming = (date) => new Date(date) > new Date();

export const augmentDayProperty = (event) => ({
  ...event,
  day: new Date(event.eventDate).toDateString(),
});

export const splitDataByDate = (events) => {
  const past = events.filter((event) => !isUpcoming(event.eventDate));
  const upcoming = events.filter((event) => isUpcoming(event.eventDate));

  return { past, upcoming };
};

const DAY = 'day';
const TIME = 'time';

const DATE_FORMAT_OPTIONS = {
  [DAY]: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  [TIME]: {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  },
};

export const dateSortFunction = (dateA, dateB, desc = true) => {
  const parsedDateA = Date.parse(new Date(dateA));
  const parsedDateB = Date.parse(new Date(dateB));
  if (desc) {
    return parsedDateB - parsedDateA;
  }
  return parsedDateA - parsedDateB;
};

export const formatDateGetDay = (dateString) =>
  new Date(dateString).toLocaleDateString('en-US', DATE_FORMAT_OPTIONS[DAY]);

export const formatDateGetTime = (dateString) =>
  new Date(dateString).toLocaleTimeString('en-US', DATE_FORMAT_OPTIONS[TIME]);
