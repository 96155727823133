import styled from 'styled-components';
import { WHITE, RAISIN_BLACK, CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

const Button = styled.button`
  align-items: ${(props) => props.AlignItems};
  background-color: ${(props) => props.BgColor};
  border: ${(props) => props.Border};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => props.Color};
  display: ${(props) => props.Display};
  height: ${(props) => props.Height};
  font-size: ${(props) => props.FontSize};
  font-weight: ${(props) => props.FontWeight};
  justify-content: ${(props) => props.JustifyContent};
  text-align: ${(props) => props.TextAlign};
  text-decoration: ${(props) =>
    props.TextDecoration ? props.TextDecoration : 'none'};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: ${(props) => props.Padding};
  width: ${(props) => props.Width};

  border-radius: 4px;
  font-family: 'Poppins';
  position: relative;

  @media ${DEVICE.formResponsive} {
    height: ${(props) => props.responsiveHeight};
    font-size: ${(props) => props.responsiveFontSize};
    width: ${(props) => props.responsiveWidth};
  }
`;

const PrimaryButton = styled(Button)`
  background: var(--WHITE_OR_CRIMSON);
  color: var(--RAISING_BLACK_OR_WHITE);
`;

const DarkButton = styled(Button)`
  background: ${CRIMSON};
  color: ${WHITE};
`;

const InvertedButton = styled(Button)`
  background-color: transparent;
  color: var(--WHITE_OR_CRIMSON);
`;

const SecondaryButton = styled(Button)`
  background-color: var(--RAISING_BLACK_OR_WHITE);
  background-color: ${(props) => props.noDark && WHITE};
  border: ${(props) =>
    props.Border === 'none'
      ? 'none '
      : '1px solid var(--GRAY_80_OR_DAVY_GRAY)'};
  color: var(--WHITE_OR_RAISING_BLACK);
  color: ${(props) => props.noDark && RAISIN_BLACK};
`;

export { Button, PrimaryButton, SecondaryButton, InvertedButton, DarkButton };
