import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const UpcomingEventCardWrapper = styled.div`
  align-items: center;
  border: 1px solid var(--DAVY_GRAY_OR_BRIGHT_GRAY);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
  transition: top ease 0.25s;
  width: 400px;
  padding: 24px;

  &:hover {
    top: -6px;
  }

  @media ${DEVICE.formResponsive} {
    width: max-content;
    padding: 16px;
  }
`;

export const UpcomingEventCardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 360px;
  justify-content: space-between;

  @media ${DEVICE.formResponsive} {
    height: 158px;
    width: 300px;
  }
`;

export const UpcomingEventCardEventName = styled.p`
  align-self: flex-start;
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media ${DEVICE.tablet} {
    font-size: 16px;
  }
`;

export const UpcomingEventsCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;

  @media ${DEVICE.tablet} {
    gap: 32px 0;
  }
`;

export const UpcomingEventCardDateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UpcomingEventCardDate = styled.p`
  color: var(--GRAY_80_OR_DAVY_GRAY);
  font-weight: 540;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`;
