import { useCallback, useContext, useEffect } from 'react';
import { LoadingSpinnerContainer } from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import NoResults from 'components/common/NoResults/NoResults';
import { LoadingSpinner } from 'components/common/SubscribeInput/SubscribeInput.styled';
import AdminTable from 'pages/AdminPage/components/AdminTable/AdminTable';
import CompanyTabContainer from 'pages/AdminPage/TabControl/CompanyTab/CompanyTab.styled';
import COMPANY_COLUMNS from 'pages/AdminPage/TabControl/CompanyTab/CompanyTable/companyTableSchema';
import useCompanyParams from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/useCompanyParams/useCompanyParams';
import CompanyFilters from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/CompanyFilters';
import CompanyControlSelected from 'pages/AdminPage/TabControl/CompanyTab/CompanyTable/CompanyControlSelected';
import Modal from 'components/common/Modal/Modal';
import AdminCompanyPopup from 'pages/AdminPage/TabControl/CompanyTab/CompanyPopup/AdminCompanyPopup';
import useCompanyPreview from 'pages/AdminPage/TabControl/CompanyTab/useCompanyPreview';
import Pagination from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/Pagination';
import { LIMIT_OPTIONS } from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/constants';
import useRequest from 'hooks/useRequest';
import CompanyStatistics from './CompanyStatistics/CompanyStatistics';
import { CompaniesContext } from '../../../../contexts/CompaniesContext';
import useCompaniesData from './useCompaniesData';

const CompanyTab = () => {
  const {
    companyQueryParams,
    setCategory,
    setCompanyName,
    setFounder,
    setFoundingYear,
    setFundingStage,
    setStatisticParam,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    resetFilters,
  } = useCompanyParams();

  const { request } = useRequest();
  const { setData } = useCompaniesData();
  const { state } = useContext(CompaniesContext);

  const { previewCompany, handleRowClick, closePopup } = useCompanyPreview();

  useEffect(() => {
    setData(request, companyQueryParams);
  }, [request, companyQueryParams]);

  const handlePageClick = (value) => {
    setPaginationOffset(value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const handleItemsPerPageChange = ({ target: { value } }) => {
    setPaginationLimit(+value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const displayCompanies = useCallback(() => {
    if (state.isLoading)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!state.data) return <NoResults />;

    return (
      <>
        <AdminTable
          columns={COMPANY_COLUMNS}
          data={state.data.data}
          ControlSelected={CompanyControlSelected}
          handleRowClick={handleRowClick}
        />
        <Pagination
          totalRows={state.data.total}
          offset={companyQueryParams.offset}
          limit={companyQueryParams.limit}
          limitOptions={LIMIT_OPTIONS}
          handlePageClick={handlePageClick}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </>
    );
  }, [state.data, state.isLoading]);

  return (
    <CompanyTabContainer>
      {!!previewCompany && (
        <Modal closeOnEscape closeForm={closePopup}>
          <AdminCompanyPopup {...previewCompany} closeForm={closePopup} />
        </Modal>
      )}
      <CompanyStatistics
        companyQueryParams={companyQueryParams}
        setStatisticParam={setStatisticParam}
        resetFilters={resetFilters}
      />
      <CompanyFilters
        companyQueryParams={companyQueryParams}
        setCategory={setCategory}
        setCompanyName={setCompanyName}
        setFounder={setFounder}
        setFoundingYear={setFoundingYear}
        setFundingStage={setFundingStage}
        setSortType={setSortType}
        resetFilters={resetFilters}
      />
      {displayCompanies()}
    </CompanyTabContainer>
  );
};

export default CompanyTab;
