import { EXCLUSIVE_KEYS } from 'components/Forms/ExclusiveDeals/constants';
import { isEmptyFieldValidator, isUrlValid } from 'utils/inputValidators';

export const ExclusiveResourceInfoInputSchema = {
  [EXCLUSIVE_KEYS.COMPANY_WEBSITE]: {
    label: 'Company URL*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter your company's URL",
    validator: isUrlValid,
  },
  [EXCLUSIVE_KEYS.PROMOTION]: {
    label: 'Tailored link to promotion/Discount code/Email*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter your company's website",
    validator: isEmptyFieldValidator,
  },
};

export const ExclusiveResourceInfoSelectSchema = {
  [EXCLUSIVE_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Select the category',
    MaxHeight: '170px',
    isError: false,
    disabledDarkMode: true,
    validator: isEmptyFieldValidator,
  },
};

export const ExclusiveResourceInfoDescriptionSchema = {
  [EXCLUSIVE_KEYS.DEAL_DESCRIPTION]: {
    label: 'Describe the deal and how to redeem it*',
    placeholder: 'Enter your a description...',
    warnMsg: 'Please enter description',
    validator: isEmptyFieldValidator,
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    RowNum: 6,
  },
  [EXCLUSIVE_KEYS.COMPANY_DESCRIPTION]: {
    label: 'Company description*',
    placeholder: 'Enter your company description...',
    warnMsg: 'Please enter description',
    validator: isEmptyFieldValidator,
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    RowNum: 6,
  },
};
