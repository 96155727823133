import Dots from 'components/common/Dots/Dots';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  WelcomeToPageContainer,
  WelcomeToPageParagraph,
  WelcomeToPageRedLine,
  WelcomeToPageTitle,
} from 'components/common/WelcomeToPage/WelcomeToPage.styled';
import { ADD_NEW_EVENT, UPDATE_EVENT } from 'components/Forms/Events/constants';
import { useAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';
import {
  ButtonWrapper,
  UpdateEventButton,
  AddEventButton,
} from './WelcomeToEvents.styled';

const WelcomeToEvents = ({ fetchEvents }) => {
  const { setActiveForm } = useAppContext();
  const callback = fetchEvents;
  return (
    <WelcomeToPageContainer>
      <Dots rows={12} />
      <WelcomeToPageTitle>Events</WelcomeToPageTitle>
      <WelcomeToPageParagraph>
        Don’t miss any of the events we have planned for our founders. Subscribe
        and receive event updates.
      </WelcomeToPageParagraph>
      <Spacer Height="20px" />
      <ButtonWrapper>
        <AddEventButton onClick={() => setActiveForm(ADD_NEW_EVENT, callback)}>
          {ADD_NEW_EVENT}
        </AddEventButton>
        <UpdateEventButton
          onClick={() => setActiveForm(UPDATE_EVENT, callback)}
        >
          {UPDATE_EVENT}
        </UpdateEventButton>
      </ButtonWrapper>
      <Spacer Height="20px" />
      <WelcomeToPageRedLine />
      <Spacer Height="20px" />
    </WelcomeToPageContainer>
  );
};

WelcomeToEvents.defaultProps = {
  fetchEvents: () => null,
};

WelcomeToEvents.propTypes = {
  fetchEvents: PropTypes.func,
};

export default WelcomeToEvents;
