import { Button } from 'components/common/Button/Button.styled';

const getButtonStyles = (isUserFounder, handleGoToLink) => (
  <Button
    BgColor={
      isUserFounder
        ? 'var(--WHITE_OR_CRIMSON)'
        : 'var(--RAISING_BLACK_OR_WHITE)'
    }
    Border={
      isUserFounder
        ? '1px solid var(--WHITE_OR_CRIMSON)'
        : '1px solid var(--WHITE_OR_RAISING_BLACK)'
    }
    Color={
      isUserFounder
        ? 'var(--RAISING_BLACK_OR_WHITE)'
        : 'var(--WHITE_OR_RAISING_BLACK)'
    }
    disabled={!isUserFounder}
    Height="40px"
    Width="100%"
    name="claimResource"
    onClick={handleGoToLink}
  >
    Claim Resource
  </Button>
);

export default getButtonStyles;
