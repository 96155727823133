import Modal from 'components/common/Modal/Modal';
import ResourcePopup from 'components/common/ResourcePages/ResourcePopup/ResourcePopup';
import ResourceCard from 'components/common/ResourcePages/ResourceCard/ResourceCard';
import Carousel from 'components/common/Carousel/Carousel.styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ADD_A_COMPANY } from 'components/Forms/Company/constants';
import { SIGN_UP } from 'components/Forms/SignForm/constants';
import { RESOURCES_CAROUSEL_SETTINGS } from 'pages/ExclusiveResources/constants';
import WarnIcon from 'assets/icons/InputErrorIcon';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import ResourcesNotFound from 'components/common/ResourcePages/ResourcesNotFound/ResourcesNotFound';
import FilterControls from 'components/common/ResourcePages/FilterControls/FilterControls';
import PropTypes from 'prop-types';
import {
  ExclusiveResourceRedemptionInfo,
  ResourcesContainer,
  ResourcesHeading,
} from '../../ExclusiveResourcesPage.styled';
import { EXCLUSIVE_RESOURCES_PARAMS } from './constants';
import ExclusiveResourcesFilters from './components/ExclusiveResourcesFilters/ExclusiveResourcesFilters';
import { mapExclusiveData } from '../../utils';

const ExclusiveResourcesContainer = ({ exclusiveContext }) => {
  const {
    fetching,
    exclusiveResourcesData,
    setPreviewResource,
    isUserFounder,
    setActiveForm,
    setDisplayFilters,
    displayFilters,
    clearFilters,
    exclusiveResourcesQueryParams,
    categories,
    isFiltersCleared,
    setDealDescription,
    setCategory,
    setSortType,
    setCompanyName,
    previewResource,
    closePopup,
  } = exclusiveContext;
  const getDisplay = () => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!exclusiveResourcesData.data.length) return <ResourcesNotFound />;

    return (
      <Carousel {...RESOURCES_CAROUSEL_SETTINGS}>
        {exclusiveResourcesData.data.map((exclusiveResource) => (
          <ResourceCard
            key={exclusiveResource.companyName}
            {...mapExclusiveData(exclusiveResource)}
            previewResourceDetails={() => setPreviewResource(exclusiveResource)}
            isUserFounder={isUserFounder}
          />
        ))}
      </Carousel>
    );
  };

  return (
    <ResourcesContainer>
      <ResourcesHeading>
        Exclusive Deals ({exclusiveResourcesData.total})
      </ResourcesHeading>
      {!fetching && !isUserFounder ? (
        <ExclusiveResourceRedemptionInfo>
          <WarnIcon />
          <p>
            To claim exclusive resources, please{' '}
            <button type="button" onClick={() => setActiveForm(ADD_A_COMPANY)}>
              submit a company
            </button>{' '}
            and{' '}
            <button type="button" onClick={() => setActiveForm(SIGN_UP)}>
              create an account
            </button>
            . Currently, these resources are only available to Wharton founders.
          </p>
        </ExclusiveResourceRedemptionInfo>
      ) : null}
      <FilterControls
        toggleFilters={() => setDisplayFilters(!displayFilters)}
        clearFilters={clearFilters}
      />
      {displayFilters && (
        <ExclusiveResourcesFilters
          exclusiveResourcesQueryParams={exclusiveResourcesQueryParams}
          companyName={
            exclusiveResourcesQueryParams[
              EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME
            ]
          }
          dealDescription={
            exclusiveResourcesQueryParams[
              EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION
            ]
          }
          sortByParam={
            exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.SORT_BY]
          }
          orderByParam={
            exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.ORDER_BY]
          }
          categories={categories}
          isFiltersCleared={isFiltersCleared}
          setDealDescription={setDealDescription}
          setCategory={setCategory}
          setSortType={setSortType}
          setCompanyName={setCompanyName}
        />
      )}
      {!!previewResource && (
        <Modal closeOnEscape closeForm={closePopup}>
          <ResourcePopup
            {...mapExclusiveData(previewResource)}
            isUserFounder={isUserFounder}
          />
        </Modal>
      )}
      {getDisplay()}
    </ResourcesContainer>
  );
};

ExclusiveResourcesContainer.propTypes = {
  exclusiveContext: PropTypes.shape({
    fetching: PropTypes.bool,
    exclusiveResourcesData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      total: PropTypes.number,
    }),
    setPreviewResource: PropTypes.func,
    isUserFounder: PropTypes.bool,
    setActiveForm: PropTypes.func,
    setDisplayFilters: PropTypes.func,
    displayFilters: PropTypes.bool,
    clearFilters: PropTypes.func,
    exclusiveResourcesQueryParams: PropTypes.shape(Object),
    categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    isFiltersCleared: PropTypes.bool,
    setDealDescription: PropTypes.func,
    setCategory: PropTypes.func,
    setSortType: PropTypes.func,
    setCompanyName: PropTypes.func,
    previewResource: PropTypes.shape(Object),
    closePopup: PropTypes.func,
  }).isRequired,
};

export default ExclusiveResourcesContainer;
