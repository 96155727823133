import PropTypes from 'prop-types';
import {
  PopupButtonContainer,
  PopupCategory,
  PopupColumn,
  PopupContainer,
  PopupContent,
  PopupContentContainer,
  PopupDescriptionParagraph,
  PopupDescriptionSection,
  PopupExternalLink,
  PopupName,
  PopupSection,
  PopupSectionParagraph,
  PopupSingleCategoryContainer,
  PopupSubSectionName,
  PopupTitle,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';
import {
  Founder,
  FounderInfo,
  FounderName,
  Mba,
  MbaContainer,
  SingleFounder,
} from 'pages/HomePage/components/CompaniesContainer/CompanyPopup/CompanyPopup.styled';
import {
  CATEGORIES,
  FOUNDER_ATTRIBUTES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
  OPERATING_STAGE,
} from 'pages/HomePage/constants';
import getCategoryColor from 'pages/HomePage/utils';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { DarkButton } from 'components/common/Button/Button.styled';
import IconPlaceholder from 'assets/icons/IconPlaceholder';
import validateURL from 'utils/linkValidator';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';
import { WHITE } from 'constants/colors';
import isURL from 'validator/lib/isURL';
import { NOT_AVAILABLE } from '../constants';

const CompanyPopup = ({
  companyName,
  companyWebsite,
  isHiring,
  fundraising,
  fundraisingUpdatedAt,
  foundingYear,
  companyStage,
  fundingStage,
  creatorHbsClasscardLink,
  creatorHbsEmail,
  geographicFocus,
  founderAttributes,
  categories,
  companyDescription,
  founders,
}) => (
  <PopupContainer>
    <PopupContentContainer>
      <PopupTitleContainer>
        <PopupTitle>Company Information</PopupTitle>
        <PopupName>{companyName}</PopupName>
      </PopupTitleContainer>
      <PopupContent>
        <PopupColumn>
          <PopupSection>
            <PopupSubSectionName>Company Website</PopupSubSectionName>
            {companyWebsite && isURL(companyWebsite) ? (
              <PopupExternalLink
                href={validateURL(companyWebsite)}
                target="_blank"
                rel="noreferrer"
                hasUnderline={isURL(companyWebsite)}
              >
                {companyWebsite}
              </PopupExternalLink>
            ) : (
              <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
            )}
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Founding Year</PopupSubSectionName>
            <PopupSectionParagraph>
              {foundingYear || NOT_AVAILABLE}
            </PopupSectionParagraph>
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Is company hiring?</PopupSubSectionName>
            <PopupSectionParagraph>
              {isHiring ? 'Yes' : 'No'}
            </PopupSectionParagraph>
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Geographic focus</PopupSubSectionName>
            <PopupMultiCategories
              categories={geographicFocus}
              categoriesColors={GEOGRAPHIC_LOCATIONS}
            />
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Wharton Class Card</PopupSubSectionName>
            {creatorHbsClasscardLink ? (
              <PopupExternalLink
                href={creatorHbsClasscardLink}
                target="_blank"
                rel="noreferrer"
                hasUnderline={creatorHbsClasscardLink}
              >
                {creatorHbsClasscardLink}
              </PopupExternalLink>
            ) : (
              <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
            )}
          </PopupSection>
        </PopupColumn>
        <PopupColumn>
          <PopupSection>
            <PopupSubSectionName>Company Stage</PopupSubSectionName>
            <PopupSingleCategoryContainer>
              <PopupCategory
                key={companyStage}
                BgColor={getCategoryColor(companyStage, OPERATING_STAGE)}
                Color={`${WHITE}`}
                BorderRadius="4px"
                Padding="1px 3px"
              >
                {companyStage || NOT_AVAILABLE}
              </PopupCategory>
            </PopupSingleCategoryContainer>
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Funding Stage</PopupSubSectionName>
            <PopupSingleCategoryContainer>
              <PopupCategory
                key={fundingStage}
                BgColor={getCategoryColor(fundingStage, FUNDING_STAGE)}
                Color={`${WHITE}`}
                BorderRadius="4px"
                Padding="1px 3px"
              >
                {fundingStage || NOT_AVAILABLE}
              </PopupCategory>
            </PopupSingleCategoryContainer>
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Fundraising?</PopupSubSectionName>
            <PopupSectionParagraph>
              {/* eslint-disable-next-line no-nested-ternary */}
              {fundraising && fundraisingUpdatedAt
                ? `Yes (${new Date(fundraisingUpdatedAt).toLocaleString(
                    'en-US',
                    {
                      month: '2-digit',
                      year: 'numeric',
                    },
                  )})`
                : fundraisingUpdatedAt !== null
                ? `No (${new Date(fundraisingUpdatedAt).toLocaleString(
                    'en-US',
                    {
                      month: '2-digit',
                      year: 'numeric',
                    },
                  )})`
                : 'No'}
            </PopupSectionParagraph>
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Founder(s) attributes</PopupSubSectionName>
            <PopupMultiCategories
              categories={founderAttributes}
              categoriesColors={FOUNDER_ATTRIBUTES}
            />
          </PopupSection>
          <PopupSection>
            <PopupSubSectionName>Category</PopupSubSectionName>
            <PopupMultiCategories
              categories={categories}
              categoriesColors={CATEGORIES}
            />
          </PopupSection>
        </PopupColumn>
      </PopupContent>
      <Spacer Height="15px" />
      <PopupDescriptionSection>
        <PopupSubSectionName>Company Description</PopupSubSectionName>
        <PopupDescriptionParagraph>
          {companyDescription || NOT_AVAILABLE}
        </PopupDescriptionParagraph>
      </PopupDescriptionSection>
      <FounderInfo>
        {founders
          .sort((a, b) => a.position - b.position)
          .map((founder) => (
            <SingleFounder key={founder.fullName}>
              <IconPlaceholder />
              <FounderName>{founder.fullName}</FounderName>
              <MbaContainer>
                <Mba>
                  {founder.classYear
                    ? `MBA ‘ ${founder.classYear.toString().slice(-2)}`
                    : NOT_AVAILABLE}
                </Mba>
                {founder.department && (
                  <Founder>Department: {founder.department}</Founder>
                )}
                <Founder>Founder: {companyName}</Founder>
                {founder.founderEmail && (
                  <Founder>Email: {founder.founderEmail}</Founder>
                )}
                {founder.founderLinkedin && (
                  <Founder>
                    LinkedIn:{' '}
                    <a
                      href={founder.founderLinkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {founder.fullName}
                    </a>
                  </Founder>
                )}
              </MbaContainer>
            </SingleFounder>
          ))}
      </FounderInfo>
    </PopupContentContainer>
    <PopupButtonContainer>
      <a
        href={
          creatorHbsClasscardLink && isURL(creatorHbsClasscardLink)
            ? validateURL(creatorHbsClasscardLink)
            : `mailto:${creatorHbsEmail}`
        }
        target="_blank"
        rel="noreferrer"
      >
        <DarkButton Width="152px" Height="40px" Border="none">
          Contact Founder
        </DarkButton>
      </a>
      <Spacer Height="40px" />
    </PopupButtonContainer>
  </PopupContainer>
);

CompanyPopup.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyWebsite: PropTypes.string,
  companyStage: PropTypes.string.isRequired,
  foundingYear: PropTypes.number,
  fundingStage: PropTypes.string,
  creatorHbsClasscardLink: PropTypes.string,
  creatorHbsEmail: PropTypes.string,
  geographicFocus: PropTypes.arrayOf(PropTypes.string),
  founderAttributes: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyDescription: PropTypes.string.isRequired,
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      classYear: PropTypes.number,
      position: PropTypes.number.isRequired,
      department: PropTypes.string,
      founderLinkedin: PropTypes.string,
    }),
  ),
  isHiring: PropTypes.bool.isRequired,
  fundraising: PropTypes.bool,
  fundraisingUpdatedAt: PropTypes.string,
};

CompanyPopup.defaultProps = {
  foundingYear: null,
  fundingStage: null,
  companyWebsite: null,
  creatorHbsClasscardLink: null,
  creatorHbsEmail: null,
  geographicFocus: [],
  founderAttributes: [],
  founders: {
    classYear: null,
  },
  fundraising: null,
  fundraisingUpdatedAt: null,
};

export default CompanyPopup;
