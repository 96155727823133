import styled from 'styled-components';
import { DAVY_GRAY, BRIGHT_GRAY } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  min-width: 376px;
  margin: 0px 72px;

  @media ${DEVICE.formResponsive} {
    min-width: 335px;
    margin: 0px 20px;
  }
`;

export const Logo = styled.p`
  color: var(--WHITE_OR_CRIMSON);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 0px;
`;

export const Title = styled.p`
  color: ${DAVY_GRAY};
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  line-height: 71px;
  margin: 0px;
  text-align: center;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormRowContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const FormBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
`;

export const AlreadyHaveDontHave = styled.div`
  align-items: center;
  border-top: 1px solid ${BRIGHT_GRAY};
  color: ${DAVY_GRAY};
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  font-size: 12px;
  line-height: 18px;
  height: 74px;

  @media ${DEVICE.laptop} {
    font-size: 14px;
    line-height: 21px;
    justify-content: center;
  }

  @media ${DEVICE.formResponsive} {
    margin-top: 15px;
  }
`;

export const SignUpInButton = styled.button`
  background: transparent;
  border: none;
  color: var(--WHITE_OR_CRIMSON);
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
  }

  @media ${DEVICE.laptop} {
    text-decoration: underline;
  }
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ForgotPasswordText = styled.span`
  cursor: pointer;
  font-size: 12px;
  :hover {
    text-decoration: underline;
  }
  color: ${DAVY_GRAY};
`;
