import { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ToggleSwitch from 'components/Header/ToggleSwitch/ToggleSwitch';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { SIGN_IN, SIGN_UP } from 'components/Forms/SignForm/constants';
import {
  PrimaryButton,
  InvertedButton,
} from 'components/common/Button/Button.styled';
import { useAppContext } from 'contexts/AppContext';
import Hamburger from 'components/Header/Hamburger/Hamburger';
import DropdownShow from 'assets/icons/DropdownShow';
import DropdownHide from 'assets/icons/DropdownHide';
import useClickOutside from 'hooks/useClickOutside';
// import Tooltip from 'components/common/Tooltip/Tooltip';
import USER_ROLES from 'constants/user_roles';
import {
  Container,
  HBSBuilds,
  Title,
  ToggleContainer,
  Nav,
  StyledLink,
  UserInfo,
  DropdownMenu,
  ButtonsContainer,
  // HeaderTooltipContainer,
  DropdownButton,
} from './Header.styled';

const Header = ({ isDark, toggleSwitch }) => {
  const { setActiveForm, user, removeUser, getUserRole } = useAppContext();
  const [dropdown, setDropdown] = useState(false);
  const contentRef = useRef(null);
  const location = useLocation();

  const openDropDown = () => setDropdown(!dropdown);
  const navigate = useNavigate();

  const closeDropDown = () => setDropdown(false);

  const logOut = () => {
    removeUser();
    closeDropDown();
    navigate('/');
  };

  useClickOutside(contentRef, closeDropDown);

  return (
    <Container>
      <Hamburger toggleSwitch={toggleSwitch} isDark={isDark} />
      <HBSBuilds>
        <Link to="/">
          <Title>WhartonBuilds</Title>
        </Link>
      </HBSBuilds>
      {!(
        getUserRole() === USER_ROLES.ADMIN &&
        location.pathname.startsWith('/administration')
      ) && (
        <ToggleContainer>
          <ToggleSwitch toggleSwitch={toggleSwitch} isDark={isDark} />
        </ToggleContainer>
      )}
      <Nav>
        {getUserRole() === USER_ROLES.ADMIN && (
          <StyledLink to="/administration">
            <span>Admin</span>
          </StyledLink>
        )}
        <StyledLink to="/">
          <span>Companies</span>
        </StyledLink>
        <StyledLink to="/resources">
          <span>Resources</span>
        </StyledLink>
        <StyledLink to="/exclusive-resources">
          <span>Exclusive Deals</span>
        </StyledLink>
        <StyledLink to="/events">
          <span>Events</span>
        </StyledLink>
        <StyledLink to="/about">
          <span>About</span>
        </StyledLink>
        <ButtonsContainer ref={contentRef}>
          {user ? (
            <UserInfo onClick={openDropDown}>
              <span>Howdy, {user.fullName.split(' ')[0]}</span>
              <Spacer Width="10px" />
              {dropdown ? (
                <DropdownHide fill="var(--BRIGHT_GRAY_OR_DAVY_GRAY)" />
              ) : (
                <DropdownShow fill="var(--BRIGHT_GRAY_OR_DAVY_GRAY)" />
              )}
            </UserInfo>
          ) : (
            <>
              <InvertedButton
                Border="none"
                onClick={() => setActiveForm(SIGN_IN)}
              >
                Sign in
              </InvertedButton>
              <PrimaryButton
                Width="89px"
                Height="40px"
                Border="none"
                onClick={() => setActiveForm(SIGN_UP)}
              >
                Sign up
              </PrimaryButton>
            </>
          )}
          {dropdown && (
            <>
              {/* <HeaderTooltipContainer>
                <Tooltip
                  text="Coming soon!"
                  Heigth="30px"
                  Width="120px"
                  Side="Left"
                  LeftOffSet="-100px"
                  TopOffSet="-5px"
                  ArrowLeftOffSet="130px"
                />
              </HeaderTooltipContainer> */}
              <DropdownMenu>
                {/* <DropdownButton
                  Color="var(--WHITE_OR_RAISING_BLACK)"
                  disabled
                  type="button"
                >
                  Profile
                </DropdownButton> */}
                <DropdownButton
                  Color="var(--WHITE_OR_CRIMSON)"
                  onClick={logOut}
                >
                  Log Out
                </DropdownButton>
              </DropdownMenu>
            </>
          )}
        </ButtonsContainer>
      </Nav>
    </Container>
  );
};

Header.propTypes = {
  isDark: PropTypes.bool.isRequired,
  toggleSwitch: PropTypes.func.isRequired,
};

export default Header;
