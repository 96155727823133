import { AERO_BLUE, RAISIN_BLACK_ALPHA_03 } from 'constants/colors';

const MAXIMUM_FILE_SIZE_IN_BYTES = 5120000; // 5mb

export default MAXIMUM_FILE_SIZE_IN_BYTES;

export const baseStyle = {
  borderColor: RAISIN_BLACK_ALPHA_03,
  transition: 'border .24s ease-in-out',
};

export const focusedStyle = {
  borderColor: AERO_BLUE,
  borderWidth: 2,
};

export const acceptStyle = {
  borderColor: RAISIN_BLACK_ALPHA_03,
  borderWidth: 2,
};

export const rejectStyle = {
  borderColor: 'var(--WHITE_OR_CRIMSON)',
  borderWidth: 2,
};
