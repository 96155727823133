import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  border-color: var(--WHITE_OR_RAISING_BLACK);
  background-color: var(--RAISING_BLACK_OR_WHITE);
  color: var(--WHITE_OR_RAISING_BLACK);
`;

export const SelectEventTypeButtonContainer = styled.div`
  display: flex;
  gap: 0 24px;
  margin-bottom: 20px;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
  }
`;

export const SelectEventTypeButton = styled.button`
  background: ${(props) =>
    props.selected
      ? 'rgba(170, 67, 78, 0.2)'
      : 'var(--DAVY_GRAY_OR_BRIGHT_GRAY)'};
  border: 1px solid
    ${(props) =>
      props.selected
        ? 'var(--WHITE_OR_CRIMSON)'
        : 'var(--DAVY_GRAY_OR_BRIGHT_GRAY)'};
  border-radius: 100px;
  color: ${(props) =>
    props.selected
      ? 'var(--WHITE_OR_CRIMSON)'
      : 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)'};
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  font-size: 14px;
  line-height: 21px;
  height: 30px;
  width: ${(props) => props.width};
`;

export const EventsContainer = styled.div`
  display: flex;
  max-height: 90vh;
  overflow: scroll;

  touch-action: pan-y;
  -ms-touch-action: pan-y;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileBottomSpacer = styled.div`
  display: none;

  @media ${DEVICE.formResponsive} {
    display: block;
    min-height: 100px;
  }
`;
