import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import { useAppContext } from 'contexts/AppContext';
import useRequest from 'hooks/useRequest';
import { EXCLUSIVE_RESOURCES_PARAMS } from 'pages/ExclusiveResources/components/ExclusiveResourcesContainer/constants';
import useExclusiveResourcesQueryParams from 'pages/ExclusiveResources/components/ExclusiveResourcesContainer/useExclusiveResourcesParams';
import { EXCLUSIVE_RESOURCE_CATEGORIES } from 'pages/ResourcePage/constants';
import { useEffect, useState } from 'react';
import {
  getExclusiveDealCategories,
  getExclusiveDeals,
} from 'services/requests';

export default function useExclusiveDeals() {
  const {
    exclusiveResourcesQueryParams,
    resetFilters,
    setCategory,
    setCompanyName,
    setDealDescription,
    setSortType,
  } = useExclusiveResourcesQueryParams();
  const [previewResource, setPreviewResource] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [exclusiveResourcesData, setExclusiveResourcesData] = useState({
    data: [],
    total: 0,
  });
  const [isUserFounder, setIsUserFounder] = useState(false);

  const { setActiveForm, user } = useAppContext();

  const { request } = useRequest();

  const closePopup = () => setPreviewResource(null);

  const isFiltersApplied = Boolean(
    exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME] ||
      exclusiveResourcesQueryParams[
        EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION
      ] ||
      exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.CATEGORY],
  );

  const clearFilters = () => {
    if (isFiltersApplied) {
      setFetching(true);
      resetFilters();
      setIsFiltersCleared(!isFiltersCleared);
    }
  };

  let catIgnore = false;
  useEffect(() => {
    const fetchCategories = async () => {
      const fetchedCategories = await request(() =>
        getExclusiveDealCategories(),
      );

      if (!catIgnore && fetchedCategories)
        setCategories(
          mapCategoriesToOptions(
            fetchedCategories.data,
            EXCLUSIVE_RESOURCE_CATEGORIES,
          ),
        );
    };

    fetchCategories();

    return () => {
      catIgnore = true;
    };
  }, []);

  const fetchExclusiveResources = async () => {
    const fetchedResources = await request(() =>
      getExclusiveDeals(exclusiveResourcesQueryParams),
    );
    if (fetchedResources) {
      setExclusiveResourcesData({
        data: fetchedResources.data.data,
        total: fetchedResources.data.total,
      });

      setIsUserFounder(fetchedResources.data.isAuthorized);
    }
    setFetching(false);
  };
  let ignore = false;
  useEffect(() => {
    if (!ignore) fetchExclusiveResources();
    return () => {
      ignore = true;
    };
  }, [request, exclusiveResourcesQueryParams, user]);
  return {
    fetching,
    exclusiveResourcesData,
    setPreviewResource,
    isUserFounder,
    setActiveForm,
    setDisplayFilters,
    displayFilters,
    clearFilters,
    exclusiveResourcesQueryParams,
    categories,
    isFiltersCleared,
    setDealDescription,
    setCategory,
    setSortType,
    setCompanyName,
    previewResource,
    closePopup,
    fetchExclusiveResources,
  };
}
