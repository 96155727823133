export const UPCOMING_EVENTS = 'Upcoming Events';

export const PAST_EVENTS = 'Past Events';

export const ALL_EVENTS = 'All Events';

export const isEventActive = {
  [UPCOMING_EVENTS]: true,
  [PAST_EVENTS]: false,
};
