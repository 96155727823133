import { useState } from 'react';
import { TAB_CONTROL_KEYS, SUB_HEADER_TABS } from './constants';

import {
  TabControlsContainer,
  ListItemContainer,
  ListItem,
} from './TabControl.styled';

const TabControl = () => {
  const [activeTab, setActiveTab] = useState(TAB_CONTROL_KEYS.COMPANIES);

  return (
    <TabControlsContainer>
      <ListItemContainer>
        {Object.values(TAB_CONTROL_KEYS).map((key) => (
          <ListItem
            key={key}
            onClick={() => setActiveTab(key)}
            isActive={key === activeTab}
            width={SUB_HEADER_TABS[key].width}
          >
            {key}
          </ListItem>
        ))}
      </ListItemContainer>
      {SUB_HEADER_TABS[activeTab].content}
    </TabControlsContainer>
  );
};

export default TabControl;
