import AnchorPartner from 'components/common/AnchorPartner/AnchorPartner';
import useEvents from 'hooks/api/useEvents';
import EventPageContent from 'pages/EventPage/components/Content/EventPageContent';
import WelcomeToEvents from 'pages/EventPage/components/WelcomeToEvents/WelcomeToEvents';
import {
  EventPageContainer,
  LandingSection,
  EventAnchorPartnerWrapper,
} from 'pages/EventPage/EventPage.styled';

const EventPage = () => {
  const eventsContext = useEvents();
  const { fetchEvents } = eventsContext;
  return (
    <EventPageContainer>
      <LandingSection>
        <WelcomeToEvents fetchEvents={fetchEvents} />
        <EventAnchorPartnerWrapper>
          <AnchorPartner />
        </EventAnchorPartnerWrapper>
      </LandingSection>
      <EventPageContent eventsContext={eventsContext} />
    </EventPageContainer>
  );
};

export default EventPage;
