import {
  Container,
  HamburgerContainer,
  Title,
} from 'components/Header/Hamburger/Hamburger.styled';
import Sidebar from 'components/Header/Sidebar/Sidebar';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'assets/icons/CloseIcon';
import HamburgerIcon from 'assets/icons/HamburgerIcon';
// import HBSBuildsLogo from 'assets/icons/HBSBuildsLogo';

const Hamburger = ({ toggleSwitch, isDark }) => {
  const [openHamburger, setOpenHamburger] = useState(false);

  const closeHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  return (
    <>
      <Container>
        <HamburgerContainer onClick={closeHamburger}>
          {openHamburger ? <CloseIcon /> : <HamburgerIcon />}
        </HamburgerContainer>
        <Title>WhartonBuilds</Title>
      </Container>
      {openHamburger && (
        <Sidebar
          open={openHamburger}
          toggleSwitch={toggleSwitch}
          isDark={isDark}
          closeHamburger={closeHamburger}
        />
      )}
    </>
  );
};

Hamburger.propTypes = {
  toggleSwitch: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};

export default Hamburger;
