import { useCallback, useState } from 'react';
import {
  ALL_EVENTS,
  PAST_EVENTS,
  UPCOMING_EVENTS,
} from 'pages/EventPage/components/Content/constants';
import {
  ContentContainer,
  EventsContainer,
  SelectEventTypeButton,
  SelectEventTypeButtonContainer,
} from 'pages/EventPage/components/Content/EventPageContent.styled';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import PastEvents from 'pages/EventPage/components/Content/PastEvents/PastEvents';
import UpcomingEvents from 'pages/EventPage/components/Content/UpcomingEvents/UpcomingEvents';
import Modal from 'components/common/Modal/Modal';
import EventPopup from 'pages/EventPage/components/EventPopup/EventPopup';
import { isUpcoming } from 'pages/EventPage/components/Content/utils';
import Spacer from 'components/common/Spacer/Spacer.styled';
import useEventPopupData from 'pages/EventPage/components/Content/useEventPopupData';
import PropTypes from 'prop-types';
import AllEvents from './AllEvents/AllEvents';
import { eventsPropTypes } from '../eventPropTypes';

const EventPageContent = ({ eventsContext }) => {
  const {
    selectedEventType,
    fetching,
    upcomingEvents,
    pastEvents,
    allEvents,
    setSelectedEventType,
  } = eventsContext;
  const [eventPopUpId, setEventPopUpId] = useState(null);

  const closeEventPopup = () => setEventPopUpId(null);
  const handleEventCardClick = (id) => setEventPopUpId(id);

  const { eventPopupData } = useEventPopupData(eventPopUpId);

  const displayEventPopUp = useCallback(() => {
    if (eventPopupData)
      return (
        <Modal closeForm={closeEventPopup} closeOnEscape>
          <EventPopup
            {...eventPopupData}
            rsvpLink={
              isUpcoming(eventPopupData.eventDate)
                ? eventPopupData.rsvpLink
                : null
            }
          />
        </Modal>
      );

    return null;
  }, [eventPopupData]);

  const displayEvents = useCallback(() => {
    switch (selectedEventType) {
      case UPCOMING_EVENTS:
        return (
          <UpcomingEvents
            upcomingEvents={upcomingEvents}
            handleEventCardClick={handleEventCardClick}
          />
        );
      case PAST_EVENTS:
        return (
          <PastEvents
            pastEvents={pastEvents}
            handleEventCardClick={handleEventCardClick}
          />
        );
      case ALL_EVENTS:
        return (
          <AllEvents
            allEvents={allEvents}
            handleEventCardClick={handleEventCardClick}
          />
        );
      default:
        break;
    }
    return false;
  }, [selectedEventType, upcomingEvents]);

  return (
    <>
      {displayEventPopUp()}
      {fetching ? (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ) : (
        <ContentContainer>
          <SelectEventTypeButtonContainer>
            <SelectEventTypeButton
              width="158px"
              selected={selectedEventType === UPCOMING_EVENTS}
              onClick={() => setSelectedEventType(UPCOMING_EVENTS)}
            >
              {UPCOMING_EVENTS}
            </SelectEventTypeButton>
            <SelectEventTypeButton
              width="111px"
              selected={selectedEventType === PAST_EVENTS}
              onClick={() => setSelectedEventType(PAST_EVENTS)}
            >
              {PAST_EVENTS}
            </SelectEventTypeButton>
            <SelectEventTypeButton
              width="109px"
              selected={selectedEventType === ALL_EVENTS}
              onClick={() => setSelectedEventType(ALL_EVENTS)}
            >
              {ALL_EVENTS}
            </SelectEventTypeButton>
          </SelectEventTypeButtonContainer>
          <EventsContainer>{displayEvents()}</EventsContainer>
          <Spacer Height="40px" />
        </ContentContainer>
      )}
    </>
  );
};
EventPageContent.defaultProps = {
  eventsContext: {},
};
EventPageContent.propTypes = {
  eventsContext: PropTypes.shape({
    selectedEventType: PropTypes.string,
    fetching: PropTypes.bool,
    upcomingEvents: eventsPropTypes(),
    pastEvents: eventsPropTypes(),
    allEvents: eventsPropTypes(),
    setSelectedEventType: PropTypes.func,
  }),
};

export default EventPageContent;
