import { shape, number, string, arrayOf } from 'prop-types';

export const eventDefaultProps = () => ({
  eventLogo: null,
  eventLocation: null,
  rsvpLink: null,
  audience: null,
  organizers: null,
});

export const eventPropTypes = () => ({
  id: number,
  eventName: string.isRequired,
  eventDescription: string.isRequired,
  rsvpLink: string,
  eventLogo: string,
  eventLocation: string,
  eventDate: string.isRequired,
  organizers: arrayOf(string),
  audience: arrayOf(string),
});

export const eventPreviewPropTypes = () => ({
  id: number,
  eventName: string.isRequired,
  rsvpLink: string,
  eventDate: string.isRequired,
});

export const eventsPropTypes = () => arrayOf(shape(eventPreviewPropTypes()));
