import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const ResourcesSection = styled.div`
  padding: 20px 75px 0;
  overflow: auto;

  @media ${DEVICE.laptop} {
    padding: 20px 50px 0;
  }
  @media ${DEVICE.formResponsive} {
    padding: 20px 25px 0;
    min-height: 100vh;
    overflow-x: hidden;
  }
`;

export const ResourcesContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

export const LandingSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @media ${DEVICE.formResponsive} {
    margin-bottom: auto;
  }
`;

export const ExclusiveResourceRedemptionInfo = styled.div`
  margin-bottom: 12px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 24px;
  color: var(--GRAY_80_OR_DAVY_GRAY);

  svg {
    margin-right: 10px;
    min-width: 20px;
    min-height: 20px;
    align-self: flex-start;
  }

  p {
    margin: 0;
    text-align: start;
  }

  p > button {
    color: var(--WHITE_OR_CRIMSON);
    text-decoration: underline;
    background: none;
    border: none;
    font-size: inherit;
    cursor: pointer;
    display: inline;
    font-weight: 700;
  }

  p > button {
    padding: 0;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 14px;
    margin-bottom: 32px;

    svg {
      min-width: 18px;
    }
  }
`;

export const ResourcesAnchorPartnerWrapper = styled.div`
  display: initial;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;

export const LandingSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${DEVICE.laptop} {
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
  }

  @media ${DEVICE.formResponsive} {
    align-items: flex-start;
  }
`;

export const ResourcesHeading = styled.h1`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);

  @media ${DEVICE.tablet} {
    margin-bottom: 32px;
  }
`;

export const StartupLink = styled.a`
  font-size: 14px;
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  :hover {
    text-decoration: underline;
  }
`;
