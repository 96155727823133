import styled, { keyframes } from 'styled-components';

const SpinnerAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }`;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 5px solid var(--DARK_CHARCOAL_OR_BRIGHT_GRAY);
  border-top: 5px solid var(--WHITE_OR_CRIMSON);
  border-radius: 50%;
  animation: ${SpinnerAnimation} 1.5s linear infinite;
`;

export const LoadingSpinnerContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  height: 50vh;
  margin-top: 120px;
`;
