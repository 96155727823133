import copyDefinedProps from 'utils/copyDefinedProps';
import { getAllCompanies } from 'services/requests';
import useRequest from 'hooks/useRequest';
import { useCallback, useState } from 'react';
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_SORT_TYPE,
  INITIAL_FILTERS,
  sortQueryParams,
} from 'pages/HomePage/components/CompaniesContainer/constants';
import { debounce } from 'lodash';

export default function useCompanies() {
  const [data, setData] = useState([]);
  const [previewCompany, setPreviewCompany] = useState(null);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filtersKey, setFiltersKey] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [sortType, setSortType] = useState(DEFAULT_SORT_TYPE);
  const [loadMoreConfig, setLoadMoreConfig] = useState({
    offset: DEFAULT_PAGINATION_OFFSET,
    limit: DEFAULT_PAGINATION_LIMIT,
  });
  const [prevConfig, setPrevConfig] = useState({
    offset: -1,
    limit: -1,
  });

  const resetFetchedData = () => {
    setData([]);
    setTotalRowCount(0);
    setPrevConfig({ offset: -1, limit: -1 });
    setLoadMoreConfig({
      offset: DEFAULT_PAGINATION_OFFSET,
      limit: DEFAULT_PAGINATION_LIMIT,
    });
  };

  const handleSortTypeChange = (sortTypeValue) => {
    resetFetchedData();
    setSortType(sortTypeValue);
  };

  const handleFiltersChange = debounce((filterKey, value) => {
    resetFetchedData();
    setFilters((prevState) => ({
      ...prevState,
      [filterKey]: value,
    }));
  }, 600);

  const isFiltersApplied = () =>
    Boolean(
      filters.companyName ||
        filters.founderName ||
        filters.foundingYear ||
        filters.stage ||
        filters.category ||
        filters.isHiring ||
        filters.fundraising ||
        filters.founderAttributes,
    );

  const clearFilters = () => {
    if (isFiltersApplied()) {
      resetFetchedData();
      setFiltersKey(!filtersKey);
      setFilters(INITIAL_FILTERS);
    }
  };
  const { request } = useRequest();

  const getCompanies = useCallback(async () => {
    if (prevConfig?.offset !== loadMoreConfig.offset) {
      setFetching(true);
      const filterParams = copyDefinedProps(filters, Object.keys(filters));

      const responseData = await request(() =>
        getAllCompanies({
          offset: loadMoreConfig.offset,
          limit: loadMoreConfig.limit,
          ...filterParams,
          ...sortQueryParams[sortType],
        }),
      );
      if (responseData) {
        setTotalRowCount(responseData.data.total);
        setData((prevState) => prevState.concat(responseData.data.data));
        setPrevConfig({
          offset: loadMoreConfig.offset,
          limit: loadMoreConfig.limit,
        });
      }

      setFetching(false);
    }
  }, [loadMoreConfig.limit, loadMoreConfig.offset, filters, sortType]);

  const updateLoadMoreConfig = () => {
    setLoadMoreConfig((prevState) => ({
      ...prevState,
      offset: prevState.offset + prevState.limit,
    }));
  };

  const reloadCompanyData = () => {
    resetFetchedData();
    getCompanies();
  };

  return {
    setPreviewCompany,
    fetching,
    data,
    previewCompany,
    sortType,
    handleSortTypeChange,
    displayFilters,
    setDisplayFilters,
    clearFilters,
    filtersKey,
    filters,
    handleFiltersChange,
    totalRowCount,
    updateLoadMoreConfig,
    loadMoreConfig,
    getCompanies: reloadCompanyData,
  };
}
